import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_perforated_mounting_strip_galvanized_1.jpg";
import Second from "../../assets/img/other-fittings/ascfixing_perforated_mounting_strip_galvanized_2.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const PerforedMounting = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of2")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of2")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
      </div>
      <div className="overflow-x-auto mb-10 md:ml-[200px] mx-4">
        <table>
          <tr className="table-tr">
            <td>Product Code</td>
            <td>Width W (mm)</td>
            <td>Length L (mm)</td>
            <td>Thinkness (mm)</td>
            <td>F (min)</td>
            <td>Weight Kgs / Casette</td>
          </tr>
          <tr>
            <td>ASC 3X12X10 SK</td>
            <td>12</td>
            <td>10</td>
            <td>0,3</td>
            <td>56,17</td>
            <td>0,203</td>
          </tr>
          <tr>
            <td>ASC 4X12X10 SK</td>
            <td>12</td>
            <td>10</td>
            <td>0,4</td>
            <td>74,89</td>
            <td>0,271</td>
          </tr>
          <tr>
            <td>ASC 5X12X10 SK</td>
            <td>12</td>
            <td>10</td>
            <td>0,5</td>
            <td>93,61</td>
            <td>0,338</td>
          </tr>
          <tr>
            <td>ASC 6X12X10 SK</td>
            <td>12</td>
            <td>10</td>
            <td>0,6</td>
            <td>112,33</td>
            <td>0,406</td>
          </tr>
          <tr>
            <td>ASC 7X12X10 SK</td>
            <td>12</td>
            <td>10</td>
            <td>0,7</td>
            <td>131,05</td>
            <td>0,474</td>
          </tr>
          <tr>
            <td>ASC 8X12X10 SK</td>
            <td>12</td>
            <td>10</td>
            <td>0,8</td>
            <td>149,78</td>
            <td>0,540</td>
          </tr>
          <tr>
            <td>ASC 3X17X10 SK</td>
            <td>17</td>
            <td>10</td>
            <td>0,3</td>
            <td>82,60</td>
            <td>0,274</td>
          </tr>
          <tr>
            <td>ASC 4X17X10 SK</td>
            <td>17</td>
            <td>10</td>
            <td>0,4</td>
            <td>110,18</td>
            <td>0,366</td>
          </tr>
          <tr>
            <td>ASC 5X17X10 SK</td>
            <td>17</td>
            <td>10</td>
            <td>0,5</td>
            <td>137,76</td>
            <td>0,458</td>
          </tr>
          <tr>
            <td>ASC 6X17X10 SK</td>
            <td>17</td>
            <td>10</td>
            <td>0,6</td>
            <td>165,18</td>
            <td>0,549</td>
          </tr>
          <tr>
            <td>ASC 7X17X10 SK</td>
            <td>17</td>
            <td>10</td>
            <td>0,7</td>
            <td>192,73</td>
            <td>0,641</td>
          </tr>
          <tr>
            <td>ASC 8X17X10 SK</td>
            <td>17</td>
            <td>10</td>
            <td>0,8</td>
            <td>220,26</td>
            <td>0,732</td>
          </tr>
          <tr>
            <td>ASC 3X12X15 SK</td>
            <td>12</td>
            <td>15</td>
            <td>0,3</td>
            <td>56,17</td>
            <td>0,304</td>
          </tr>
          <tr>
            <td>ASC 4X12X15 SK</td>
            <td>12</td>
            <td>15</td>
            <td>0,4</td>
            <td>74,89</td>
            <td>0,406</td>
          </tr>
          <tr>
            <td>ASC 5X12X15 SK</td>
            <td>12</td>
            <td>15</td>
            <td>0,5</td>
            <td>93,61</td>
            <td>0,507</td>
          </tr>
          <tr>
            <td>ASC 6X12X15 SK</td>
            <td>12</td>
            <td>15</td>
            <td>0,6</td>
            <td>112,33</td>
            <td>0,609</td>
          </tr>
          <tr>
            <td>ASC 7X12X15 SK</td>
            <td>12</td>
            <td>15</td>
            <td>0,7</td>
            <td>131,05</td>
            <td>0,710</td>
          </tr>
          <tr>
            <td>ASC 3X17X15 SK</td>
            <td>17</td>
            <td>15</td>
            <td>0,3</td>
            <td>82,60</td>
            <td>0,412</td>
          </tr>
          <tr>
            <td>ASC 4X17X15 SK</td>
            <td>17</td>
            <td>15</td>
            <td>0,4</td>
            <td>110,18</td>
            <td>0,549</td>
          </tr>
          <tr>
            <td>ASC 5X17X15 SK</td>
            <td>17</td>
            <td>15</td>
            <td>0,5</td>
            <td>137,76</td>
            <td>0,682</td>
          </tr>
          <tr>
            <td>ASC 6X17X15 SK</td>
            <td>17</td>
            <td>15</td>
            <td>0,6</td>
            <td>165,18</td>
            <td>0,823</td>
          </tr>
          <tr>
            <td>ASC 7X17X15 SK</td>
            <td>17</td>
            <td>15</td>
            <td>0,7</td>
            <td>192,73</td>
            <td>0,960</td>
          </tr>
        </table>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=48"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default PerforedMounting;
