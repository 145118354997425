import React from "react";
import Navbar from "../components/Navbar";
import FixedImage from "../components/FixedImage";
import Global from "../components/Global";
import Footer from "../components/Footer";
import ComImg from "../assets/ascelik.jpg";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import AnimatedPage from "../components/AnimatedPage";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const About = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Navbar />
      <AnimatedPage>
        <div className="w-full mb-0 h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
            {t("aboutus")}
          </h1>
          {/* <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">Clamp and Fixing System</h2> */}
        </div>
        <div>
          <div class="container-item mb-2 mx-auto mt-14 py-23 justify-center items-center grid grid-col-1 xl:grid-cols-2">
            <div class="lg:flex-grow lg:pr-24 md:pr-16 block flex-col md:mb-0 items-center deneme">
              <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-black/80 border-b-2 border-black ">
                {t("aboutus")}
              </h1>
              <p class="text-sm mb-8 leading-relaxed">{t("aboutdesc")}</p>
            </div>
            <div class="lg:w-auto lg:mx-auto deneme">
              <img src={ComImg} alt="/" class="w-full h-auto object-fill" />
            </div>
          </div>
          {/* <div class="container px-16 py-14 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">ASC FİXİNG</h2>
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">Clamp and Fixing System</h1>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/3">
              <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col hover:scale-110 duration-1000">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Shooting Stars</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus aliquid eligendi, rerum necessitatibus ullam ab magni at sequi eius animi reprehenderit veniam deserunt, eum nesciunt sunt quas vel adipisci autem!</p>

                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col hover:scale-110 duration-1000">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">The Catalyzer</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus fugit dolore vitae numquam cum nihil repellat, possimus tempore tempora, excepturi velit perspiciatis nemo tenetur totam error dicta esse sed a.</p>

                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col hover:scale-110 duration-1000">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                      <circle cx="6" cy="6" r="3"></circle>
                      <circle cx="6" cy="18" r="3"></circle>
                      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Neptune</h2>
                  2
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores aliquam consequatur voluptate expedita rerum vel impedit consequuntur. Amet qui quas eligendi corrupti a! Dignissimos repudiandae quam praesentium expedita. Eligendi, doloremque.</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          <Global />
          <FixedImage />
        </div>
        <Footer />
      </AnimatedPage>
    </>
  );
};

export default About;
