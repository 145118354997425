import React, { Component } from "react";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { GrMail } from "react-icons/gr";
import { IoLocationSharp } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import MaviLogo from "../assets/asc-fixing-logo.png";
import BeyazLogo from "../assets/asc-fixing-beyaz-logo.png";

class Form extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    emailStatus: "",
  };

  // handle the value
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  // when submit btn is clicked
  submitForm = (e) => {
    const { name, email, phone, subject, message } = this.state;

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the response state and the step

      this.setState({
        emailStatus: xhr.responseText,
      });
    });
    // open the request with the verb and the url
    xhr.open(
      "GET",
      "http://192.168.31.177/mail-api-react-active/contact-mail-api.php?email=" +
        email +
        "&name-surname=" +
        name +
        "&phone=" +
        phone +
        "&subject=" +
        subject +
        "&message=" +
        message
    );
    // send the request
    xhr.send();

    // reset the fields
    this.setState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    e.preventDefault();
  };

  render() {
    const { name, email, phone, subject, message, emailStatus } = this.state;

    const Alert = () => {
      Swal.fire({
        icon: "success",
        title: "Mesajınız Gönderildi.",
        text: "Yakında Sizinle İletişime Geçeceğiz.",
        confirmButtonText: "Tamam",
      });
    };

    return (
      <div>
        <div className="w-full">
          <div className="formBlock" onSubmit={this.submitForm}>
            {emailStatus ? emailStatus : null}

            <div className="my-24">
              <div
                className="container mx-auto my-4 px-4 lg:px-20"
                data-aos="fade-right"
              >
                <form
                  onSubmit={Alert}
                  action=""
                  className="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl"
                >
                  <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    <input
                      value={name}
                      onChange={this.handleChange("name")}
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      required
                      placeholder="Full Name"
                    />
                    <input
                      pattern="[0-9]{10}"
                      min={0}
                      value={phone}
                      onChange={this.handleChange("phone")}
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="number"
                      required
                      placeholder="Phone Number"
                    />
                    <input
                      value={email}
                      onChange={this.handleChange("email")}
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      required
                      placeholder="Email"
                    />
                    <input
                      value={subject}
                      onChange={this.handleChange("subject")}
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      required
                      placeholder="Subject"
                    />
                  </div>
                  <div className="my-4">
                    <textarea
                      value={message}
                      onChange={this.handleChange("message")}
                      placeholder="Message Here"
                      required
                      class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    ></textarea>
                  </div>
                  <div className="my-2 w-1/2 lg:w-1/4">
                    <button
                      type="submit"
                      class="uppercase text-sm font-bold tracking-wide bg-[#1D4ED8] text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline hover:scale-110 hover:bg-white border-[#1D4ED8] border hover:text-[#1D4ED8] duration-500"
                    >
                      Gönder
                    </button>
                  </div>
                </form>

                <div className="w-full lg:-mt-[500px] lg:w-2/6 px-8 py-12 ml-auto bg-[#1D4ED8]/90 rounded-2xl">
                  <div className="flex flex-col text-white h-[400px]">
                    <div className="bg-[#fff]/90 rounded-2xl">
                      <img
                        src={MaviLogo}
                        className="w-[50%] mx-auto my-8"
                        alt="/"
                      />
                    </div>
                    <ul className="list-none pl-8 mt-1">
                      <li className="hover:underline p-1 flex cursor-pointer py-2 my-1">
                        <BsWhatsapp size={30} className="pr-3 pb-2" />
                        <a href="tel:+90 530 915 81 11">+90 (530) 915 81 11</a>
                      </li>
                      <li className="hover:underline p-1 flex cursor-pointer py-2 my-1">
                        <GrMail size={32} className="pr-3 pb-2" />
                        <Link to="mailto:info@ascfixing.com">
                          info@ascfixing.com
                        </Link>
                      </li>
                      <li className="hover:underline p-1 pr-5 flex cursor-pointer py-2 my-1">
                        <IoLocationSharp size={52} className="pr-3" />
                        <Link
                          to="https://goo.gl/maps/xbkaNu69ZWXJc8Rr9"
                          target="_blank"
                        >
                          Karaagac Mahallesi Hadimkoy Istanbul Caddesi No:28
                          34500 Buyukcekmece - Istanbul / TURKEY
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
