import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import AnimatedPage from "../components/AnimatedPage";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const onButtonClick = () => {
  // using Java Script method to get PDF file
  fetch("general-clamp-fixing-system.pdf").then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "general-clamp-fixing-system.pdf";
      alink.click();
    });
  });
};
const Quality = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center my-[100px]">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
            {t("quality")}
          </h1>
        </div>
        <div className="container m-auto flex flex-col md:flex-row justify-evenly mt-10 gap-8 px-5 mb-24">
          <div
            className="box-border w-full md:w-2/5 h-80 overflow-hidden"
            data-aos="zoom-in"
          >
            <Link to="/quality/certificates">
              <div className="bg-no-repeat bg-center bg-cover bg-certificates-bg w-full h-full hover:scale-110 duration-500">
                <div className="bg-[#0000008a] w-full h-full">
                  <h1 className="relative text-4xl text-white/80 top-[135px] font-bold text-center">
                    {t("certificate")}
                  </h1>
                </div>
              </div>
            </Link>
          </div>

          <div
            className="box-border w-full md:w-2/5 h-80 overflow-hidden"
            data-aos="zoom-in"
          >
            <Link onClick={onButtonClick}>
              <div className="bg-no-repeat bg-center bg-cover bg-catalogues-bg  w-full h-full hover:scale-110 duration-500">
                <div className="bg-[#0000008a] w-full h-full">
                  <h1 className="relative text-4xl text-white/80 top-[135px] font-bold text-center">
                    {t("catologue")}
                  </h1>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </AnimatedPage>
    </div>
  );
};

export default Quality;
