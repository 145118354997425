import React, { Component, useState } from "react";
import { GrMail } from "react-icons/gr";
import { IoLocationSharp } from "react-icons/io5";
import Map from "../components/Map";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import Swal from "sweetalert2";
import Form from "../components/Form";
import AnimatedPage from "../components/AnimatedPage";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const Contact = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center mt-[100px]">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
            {t("contact")}
          </h1>
        </div>
        <div className="contactouteline bg-[#F6F7F8] mb-12">
          <div className="container m-auto">
            <div
              className="pl-[25px] relative md:pl-[100px] mt-5"
              data-aos="zoom-out"
            >
              <div className="w-[20px] h-14 bg-[#1D4ED8] rounded-sm absolute"></div>
              <h1 className="flex justify-start font-bold text-2xl md:text-4xl text-[#1D4ED8] pt-2 pb-5 ml-7">
                {t("contact")}
              </h1>
            </div>
            <div className="mb-[50px]">
              <Form />
            </div>
          </div>
          <Map />
        </div>
        <Footer />
      </AnimatedPage>
    </div>
  );
};

export default Contact;
