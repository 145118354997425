import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import WasherDin from "../../assets/img/profile-console/ascfixing_l_rail_console.jpg";
import CollectorBox from "../../assets/img/profile-console/ascfixing_l_rail_console_2.jpg";
import Detai from "../../assets/img/profile-console/ascfixing_l_rail_console_teknik.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const LRailConsole = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/profile-console">{t("profileconsole")}</Link>
          </h2>
          <BsArrowRightSquareFill
            className="mx-1 md:mx-3 text-gray-600"
            size={25}
          />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("lrailconsole")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={WasherDin} alt="" />
              </div>
              <div className="image">
                <img src={CollectorBox} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("lrailconsole")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          {/* <div className='text-md mt-5 p-2'>
            <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('application')}</h3>
            <ul className='text-sm'>
              <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                <span className='ml-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, recusandae unde nostrum quidem vero dolor sed natus distinctio minus, </span>
              </li>
              <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                <span className='ml-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur sapiente ex labore delectus quidem totam itaque. Atque ullam veniam quos incidunt.  </span>
              </li>
              <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                <span className='ml-2'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis magnam perspiciatis deleniti maiores ex mollitia veritatis! Officiis quis perspiciatis consequuntur!</span>
              </li>
              <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                <span className='ml-2'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis magnam perspiciatis deleniti maiores ex mollitia veritatis! Officiis quis perspiciatis consequuntur!</span>
              </li>
              <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                <span className='ml-2'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis magnam perspiciatis deleniti maiores ex mollitia veritatis! Officiis quis perspiciatis consequuntur!</span>
              </li>
            </ul>
          </div> */}
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">{t("features")}</h3>
            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("material")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("coating")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto mb-10 min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>PROFILE DIMENSION</td>
              <td></td>
              <td>PLATE DIMENSION</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td>Width W (mm)</td>
              <td>Height H (mm)</td>
              <td>Thickness S (mm)</td>
              <td>Length L (mm)</td>
              <td>Width Z (mm)</td>
              <td>Length Y (mm)</td>
              <td>Thickness S2 (mm)</td>
            </tr>
            <tr>
              <td>ASC LK 30x30x200</td>
              <td>30</td>
              <td>30</td>
              <td>3,00</td>
              <td>200</td>
              <td>120</td>
              <td>40</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 30x30x300</td>
              <td>30</td>
              <td>30</td>
              <td>3,00</td>
              <td>300</td>
              <td>120</td>
              <td>40</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 30x30x400</td>
              <td>30</td>
              <td>30</td>
              <td>3,00</td>
              <td>400</td>
              <td>120</td>
              <td>40</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 30x30x500</td>
              <td>30</td>
              <td>30</td>
              <td>3,00</td>
              <td>500</td>
              <td>120</td>
              <td>40</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 40x40x200</td>
              <td>40</td>
              <td>40</td>
              <td>4,00</td>
              <td>200</td>
              <td>120</td>
              <td>50</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 40x40x300</td>
              <td>40</td>
              <td>40</td>
              <td>4,00</td>
              <td>300</td>
              <td>120</td>
              <td>50</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 40x40x400</td>
              <td>40</td>
              <td>40</td>
              <td>4,00</td>
              <td>400</td>
              <td>120</td>
              <td>50</td>
              <td>4,00</td>
            </tr>
            <tr>
              <td>ASC LK 40x40x500</td>
              <td>40</td>
              <td>40</td>
              <td>4,00</td>
              <td>500</td>
              <td>120</td>
              <td>50</td>
              <td>4,00</td>
            </tr>
          </table>
        </div>
        <FancyBox>
        <div>
          <div className="image object-cover mt-3">
            <img data-fancybox="detay" src={Detai} alt="" className="h-[250px] drop-shadow-2xl" />
          </div>
        </div>
        </FancyBox>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=46"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default LRailConsole;
