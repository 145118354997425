import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_bolt.jpg";
import Second from "../../assets/img/other-fittings/ascfixing_bolt_teknik.jpg";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const BoltDin933 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of32")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of32")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
      </div>
      <div className="flex flex-row max-md:flex-col gap-x-5 overflow-x-auto mb-10 md:ml-[200px] mx-4">
        <table className="w-[50%]">
          <tr className="table-tr">
            <td>Product Code</td>
            <td>Size M</td>
            <td>Length L (mm)</td>
            <td>Weight (gr)</td>
          </tr>
          <tr>
            <td>ASC CV 0515</td>
            <td>M5</td>
            <td>15</td>
            <td>3,12</td>
          </tr>
          <tr>
            <td>ASC CV 0530</td>
            <td>M5</td>
            <td>30</td>
            <td>5,11</td>
          </tr>
          <tr>
            <td>ASC CV 0615</td>
            <td>M6</td>
            <td>15</td>
            <td>4,76</td>
          </tr>
          <tr>
            <td>ASC CV 0630</td>
            <td>M6</td>
            <td>30</td>
            <td>7,51</td>
          </tr>
          <tr>
            <td>ASC CV 0815</td>
            <td>M8</td>
            <td>15</td>
            <td>10,35</td>
          </tr>
          <tr>
            <td>ASC CV 0820</td>
            <td>M8</td>
            <td>20</td>
            <td>12,3</td>
          </tr>
          <tr>
            <td>ASC CV 0825</td>
            <td>M8</td>
            <td>25</td>
            <td>12,9</td>
          </tr>
          <tr>
            <td>ASC CV 0830</td>
            <td>M8</td>
            <td>30</td>
            <td>15,5</td>
          </tr>
          <tr>
            <td>ASC CV 1015</td>
            <td>M10</td>
            <td>15</td>
            <td>18,2</td>
          </tr>
          <tr>
            <td>ASC CV 1030</td>
            <td>M10</td>
            <td>30</td>
            <td>26,2</td>
          </tr>
          <tr>
            <td>ASC CV 1040</td>
            <td>M10</td>
            <td>40</td>
            <td>31,2</td>
          </tr>
          <tr>
            <td>ASC CV 1050</td>
            <td>M10</td>
            <td>50</td>
            <td>36,2</td>
          </tr>
          <tr>
            <td>ASC CV 1230</td>
            <td>M12</td>
            <td>30</td>
            <td>37,7</td>
          </tr>
          <tr>
            <td>ASC CV 1245</td>
            <td>M12</td>
            <td>45</td>
            <td>48,5</td>
          </tr>
          <tr>
            <td>ASC CV 1630</td>
            <td>M16</td>
            <td>30</td>
            <td>76,9</td>
          </tr>
          <tr>
            <td>ASC CV 1645</td>
            <td>M16</td>
            <td>45</td>
            <td>97,1</td>
          </tr>
        </table>
        <div>
          <div className="flex flex-row mx-auto gap-x-8">
            <FancyBox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              <Link className="image  items-center object-cover mb-3">
                <img
                  data-fancybox="detay"
                  src={Second}
                  alt=""
                  className="h-[250px] drop-shadow-2xl"
                />
              </Link>
            </FancyBox>
          </div>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=65"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default BoltDin933;
