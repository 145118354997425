import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_corner_for_ventilation.png";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const CornerVentilation = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of5")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of5")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
      </div>
      <div className="overflow-x-auto mb-10 md:ml-[200px] mx-4">
        <table>
          <tr className="table-tr">
            <td>Product Code</td>
            <td>A (mm)</td>
            <td>B (mm)</td>
            <td>L (mm)</td>
            <td>g (mm)</td>
            <td>K1 (mm)</td>
            <td>K2 (mm)</td>
            <td>K3 (mm)</td>
            <td>K4 (mm)</td>
            <td>K5 (mm)</td>
            <td>E (mm)</td>
            <td>F (mm)</td>
            <td>d (mm)</td>
          </tr>
          <tr>
            <td>ASC HKB 73</td>
            <td>18</td>
            <td>15</td>
            <td>73</td>
            <td>2</td>
            <td>23</td>
            <td>-</td>
            <td>51</td>
            <td>10</td>
            <td>8</td>
            <td>11</td>
            <td>9</td>
            <td>6,2</td>
          </tr>
          <tr>
            <td>ASC HKB 95</td>
            <td>18</td>
            <td>15</td>
            <td>95</td>
            <td>2</td>
            <td>23</td>
            <td>51</td>
            <td>85</td>
            <td>10</td>
            <td>8</td>
            <td>12</td>
            <td>9</td>
            <td>6</td>
          </tr>
          <tr>
            <td>ASC HKB 103</td>
            <td>27</td>
            <td>25</td>
            <td>103</td>
            <td>2,5</td>
            <td>35</td>
            <td>52</td>
            <td>87</td>
            <td>10</td>
            <td>13</td>
            <td>16</td>
            <td>13</td>
            <td>7,2</td>
          </tr>
        </table>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=49"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default CornerVentilation;
