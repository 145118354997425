import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Products from "../components/Products";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import AnimatedPage from "../components/AnimatedPage";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}

const ProductsPage = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('products')}</h1>
        </div>
        <Products />
        <Footer />
      </AnimatedPage>
    </div>
  );
};

export default ProductsPage;
