import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ProfileEndCap from "../assets/img/other-fittings/ascfixing_profile_end_cap.png";
import PerforatedMountingStrip from "../assets/img/other-fittings/ascfixing_perforated_mounting_strip_galvanized_2.jpg";
import UshapedWasher from "../assets/img/other-fittings/ascfixing_u_shaped_washer.jpg";
import SlidingSupport from "../assets/img/other-fittings/ascfixing_sliding_support.png";
import CornerForVentilation from "../assets/img/other-fittings/ascfixing_corner_for_ventilation.png";
import BeamClamp from "../assets/img/other-fittings/ascfixing_beam_clamp_2.jpg";
import GClimps from "../assets/img/other-fittings/ascfixing_g_clips.png";
import VTrapezeHangar from "../assets/img/other-fittings/ascfixing_v_trapeze_hanger.jpg";
import QuickFix from "../assets/img/other-fittings/ascfixing_quickfix_1.jpg";
import SlideNutTb2 from "../assets/img/other-fittings/ascfixing_slide_nut_tb2_1.png";
import SlideNutTb3 from "../assets/img/other-fittings/ascfixing_slide_nut_tb3_1.jpg";
import SlideNutTb4 from "../assets/img/other-fittings/ascfixing_slide_nut_tb4.png";
import SlideNutTb5 from "../assets/img/other-fittings/ascfixing_slide_nut_tb5.jpg";
import SlideNut from "../assets/img/other-fittings/ascfixing_slide_nut.png";
import SquareSlideNut from "../assets/img/other-fittings/ascfixing_square_slide_nut.png";
import SpringSlideNude from "../assets/img/other-fittings/ascfixing_spring_slide_nut_1.jpg";
import WallPlateWithNut from "../assets/img/other-fittings/ascfixing_wall_plate_with_nut.jpg";
import WallPlateWithNutWithWelded from "../assets/img/other-fittings/ascfixing_wall_plate_with_nut_welded.jpg";
import WallPlateWithNutWithBolt from "../assets/img/other-fittings/ascfixing_wall_plate_with_bolt.jpg";
import WallPlateWithNutWithBoltWelded from "../assets/img/other-fittings/ascfixing_wall_plate_with_bolt_welded.jpg";
import WallPlateWıthNutWıthMuff from "../assets/img/other-fittings/ascfixing_wall_plate_with_muff.jpg";
import ThreadedRod from "../assets/img/other-fittings/ascfixing_threaded_rod.jpg";
import StudBolt from "../assets/img/other-fittings/ascfixing_stud_bolt.jpg";
import NutDin934 from "../assets/img/other-fittings/ascfixing_nut.jpg";
import ExtensionNutDin6334 from "../assets/img/other-fittings/ascfixing_extension_nut.jpg";
import WasherDin125 from "../assets/img/other-fittings/ascfixing_washer_narrow.jpg";
import WasherDin9021 from "../assets/img/other-fittings/ascfixing_washer_din_9021.jpg";
import WasherInchWhitworth from "../assets/img/other-fittings/ascfixig_washer_inch_whitworth.jpg";
import SpringWasherDin127 from "../assets/img/other-fittings/ascfixing_spring_washer_.jpg";
import SquareWasher from "../assets/img/other-fittings/ascfixing_square_washer.png";
import SquareWasherForUProfile from "../assets/img/other-fittings/ascfixing_square_washer_for_u_profile.jpg";
import { Link } from "react-router-dom";
import BoltDin933 from "../assets/img/other-fittings/ascfixing_bolt.jpg";
import TriphoneAdaptor from "../assets/img/other-fittings/ascfixing_adaptor_triphone.png";
import DropInAnchor from "../assets/img/other-fittings/ascfixing_drop_in_anchor.jpg";
import DrawInAnchorSType from "../assets/img/other-fittings/ascfixing_draw_in_anchor_stype_2.png";
import WedgeAnchor from "../assets/img/other-fittings/ascfixing_wedge_anchor.jpg";
import PipeTypeAnchor from "../assets/img/other-fittings/ascfixing_pipe_type_anchor.png";
import SleeveAnchor from "../assets/img/other-fittings/ascfixing_sleeve_anchor.jpg";
import CeilingAnchor from "../assets/img/other-fittings/ascfixing_ceiling_anchor.jpg";
import HookAnchor from "../assets/img/other-fittings/ascfixing_hook_anchor.jpg";
import StandardPlasticAnchorWhite from "../assets/img/other-fittings/ascfixing_standard_plastic_anchor_white.jpg";
import StandardPlasticAnchorGray from "../assets/img/other-fittings/ascfixing_standard_plastic_anchor_grey.jpg";
import BrickCavityAnchor from "../assets/img/other-fittings/ascfixing_brick_cavity_anchor.jpg";
import RocketPlasticAnchor from "../assets/img/other-fittings/ascfixing_rocket_anchor.jpg";
import BrickAnchor from "../assets/img/other-fittings/ascfixing_brick_anchor.png";
import LavatoryAssemblySet from "../assets/img/other-fittings/ascfixing_lavatory_assembly_set_1.jpg";
import ClosetAssemblySet from "../assets/img/other-fittings/ascfixing_closet_assembly_set_2.jpg";
import SpringAssemblyLavatorySet from "../assets/img/other-fittings/ascfixing_spring_lavatory_assembly_set_3.jpg";
import WaterHeaterAssemblySet from "../assets/img/other-fittings/ascfixing_water_heater_assembly_set_4.jpg";
import CollectorBoxWithConsole from "../assets/img/other-fittings/ascfixing_collector_box_with_console.png";
import CollectorBox from "../assets/img/other-fittings/ascfixing_collector_box.png";
import CollectorClampSquare from "../assets/img/other-fittings/ascfixing_collector_clamp_square.jpg";
import CollectorClampRound from "../assets/img/other-fittings/ascfixing_collector_clamp_round.jpg";
import AdjustablePlasticClamp from "../assets/img/other-fittings/ascfixing_adjustable_plastic_clamp_2.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import AnimatedPage from "../components/AnimatedPage";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}

const OtherFittings = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <AnimatedPage>
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]" >
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('products')}</h1>
        <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">{t('otherfittings')}</h2>
      </div>
      <div className="max-w-[1140px] m-auto p-4 " data-aos="fade-up" data-aos-duration="3000">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
          {/* Ürünler Başlangıcı */}
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/profile-cap">
              <div className="overflow-hidden">
                <img
                  src={ProfileEndCap}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of1')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/perfored-mounting">
              <div className="overflow-hidden">
                <img
                  src={PerforatedMountingStrip}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of2')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/shaped-washer">
              <div className="overflow-hidden">
                <img
                  src={UshapedWasher}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of3')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/sliding-support">
              <div className="overflow-hidden">
                <img
                  src={SlidingSupport}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of4')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/corner-for-ventilation">
              <div className="overflow-hidden">
                <img
                  src={CornerForVentilation}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of5')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/beam-clamp">
              <div className="overflow-hidden">
                <img
                  src={BeamClamp}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of6')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/g-climps">
              <div className="overflow-hidden">
                <img
                  src={GClimps}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of7')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/v-trapeze-hangar">
              <div className="overflow-hidden">
                <img
                  src={VTrapezeHangar}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of8')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/quick-fit">
              <div className="overflow-hidden">
                <img
                  src={QuickFix}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of9')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/slide-nude-tb2">
              <div className="overflow-hidden">
                <img
                  src={SlideNutTb2}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of10')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/slide-nude-tb3">
              <div className="overflow-hidden">
                <img
                  src={SlideNutTb3}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of11')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/slide-nude-tb4">
              <div className="overflow-hidden">
                <img
                  src={SlideNutTb4}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of12')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/slide-nude-tb5">
              <div className="overflow-hidden">
                <img
                  src={SlideNutTb5}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of13')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/slide-nude">
              <div className="overflow-hidden">
                <img
                  src={SlideNut}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of14')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/square-slide-nude">
              <div className="overflow-hidden">
                <img
                  src={SquareSlideNut}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of15')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/spring-slide-nude">
              <div className="overflow-hidden">
                <img
                  src={SpringSlideNude}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of16')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/wall-plate-with-nut">
              <div className="overflow-hidden">
                <img
                  src={WallPlateWithNut}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of17')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/wall-plate-with-nut-with-welded">
              <div className="overflow-hidden">
                <img
                  src={WallPlateWithNutWithWelded}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of18')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/wall-plate-with-nut-with-bolt">
              <div className="overflow-hidden">
                <img
                  src={WallPlateWithNutWithBolt}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of19')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/wall-plate-with-nut-with-bolt-welded">
              <div className="overflow-hidden">
                <img
                  src={WallPlateWithNutWithBoltWelded}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of20')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/wall-plate-with-nut-with-muff">
              <div className="overflow-hidden">
                <img
                  src={WallPlateWıthNutWıthMuff}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of21')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/threaded-rod">
              <div className="overflow-hidden">
                <img
                  src={ThreadedRod}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of22')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/stud-bolt">
              <div className="overflow-hidden">
                <img
                  src={StudBolt}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of23')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/nud-din">
              <div className="overflow-hidden">
                <img
                  src={NutDin934}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of24')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/extension-nut-din6334">
              <div className="overflow-hidden">
                <img
                  src={ExtensionNutDin6334}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of25')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/washer-din125">
              <div className="overflow-hidden">
                <img
                  src={WasherDin125}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of26')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/washer-din9021">
              <div className="overflow-hidden">
                <img
                  src={WasherDin9021}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of27')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/washer-inch-whitworth">
              <div className="overflow-hidden">
                <img
                  src={WasherInchWhitworth}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of28')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/spring-washer-din127">
              <div className="overflow-hidden">
                <img
                  src={SpringWasherDin127}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of29')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/square-washer">
              <div className="overflow-hidden">
                <img
                  src={SquareWasher}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of30')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/square-washer-for-profile">
              <div className="overflow-hidden">
                <img
                  src={SquareWasherForUProfile}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of31')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/bolt-din933">
              <div className="overflow-hidden">
                <img
                  src={BoltDin933}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of32')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/triphone-adaptor">
              <div className="overflow-hidden">
                <img
                  src={TriphoneAdaptor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of33')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/drop-in-anchor">
              <div className="overflow-hidden">
                <img
                  src={DropInAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of34')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/draw-in-anchor-stype">
              <div className="overflow-hidden">
                <img
                  src={DrawInAnchorSType}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of35')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/wedge-anchor">
              <div className="overflow-hidden">
                <img
                  src={WedgeAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of36')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/pipe-type-anchor">
              <div className="overflow-hidden">
                <img
                  src={PipeTypeAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of37')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/sleeve-anchor">
              <div className="overflow-hidden">
                <img
                  src={SleeveAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of38')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/ceiling-anchor">
              <div className="overflow-hidden">
                <img
                  src={CeilingAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of39')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/hook-anchor">
              <div className="overflow-hidden">
                <img
                  src={HookAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of40')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/standart-plastic-anchor-white">
              <div className="overflow-hidden">
                <img
                  src={StandardPlasticAnchorWhite}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of41')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/standart-plastic-anchor-gray">
              <div className="overflow-hidden">
                <img
                  src={StandardPlasticAnchorGray}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of42')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/brick-cavity-anchor">
              <div className="overflow-hidden">
                <img
                  src={BrickCavityAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of43')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/rocket-plastic-anchor">
              <div className="overflow-hidden">
                <img
                  src={RocketPlasticAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of44')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/brick-anchor">
              <div className="overflow-hidden">
                <img
                  src={BrickAnchor}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of45')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/lavatory-assembly-set">
              <div className="overflow-hidden">
                <img
                  src={LavatoryAssemblySet}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of46')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/closet-assembly-set">
              <div className="overflow-hidden">
                <img
                  src={ClosetAssemblySet}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of47')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/spring-assembly-lavatory-set">
              <div className="overflow-hidden">
                <img
                  src={SpringAssemblyLavatorySet}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of48')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/water-heater-assembly-set">
              <div className="overflow-hidden">
                <img
                  src={WaterHeaterAssemblySet}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of49')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/collector-box-with-console">
              <div className="overflow-hidden">
                <img
                  src={CollectorBoxWithConsole}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of50')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/collector-box">
              <div className="overflow-hidden">
                <img
                  src={CollectorBox}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of51')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/collector-clamp-square">
              <div className="overflow-hidden">
                <img
                  src={CollectorClampSquare}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of52')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/collector-clamp-round">
              <div className="overflow-hidden">
                <img
                  src={CollectorClampRound}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of53')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/other-fittings/adjustable-plastic-clamp">
              <div className="overflow-hidden">
                <img
                  src={AdjustablePlasticClamp}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                {t('of54')}
              </h1>
            </Link>
          </div>
          {/* Ürünler Sonu */}
        </div>
      </div>
      <Footer />
      </AnimatedPage>
    </div>
  );
};

export default OtherFittings;
