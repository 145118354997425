import React from "react";
import FancyBox from "./FancyBox";
import { Link } from "react-router-dom";

const Map = () => {
  return (
    <div className="my-2">
      <FancyBox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <Link data-fancybox to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d863.8772055144957!2d28.63129590275672!3d41.088457895945915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa0bcac3e59f9%3A0x330d2357321b663c!2zQXPDp2VsaWsgS2VsZXDDp2U!5e0!3m2!1str!2str!4v1688504946497!5m2!1str!2str">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d863.8772055144957!2d28.63129590275672!3d41.088457895945915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa0bcac3e59f9%3A0x330d2357321b663c!2zQXPDp2VsaWsgS2VsZXDDp2U!5e0!3m2!1str!2str!4v1688504946497!5m2!1str!2str"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Link>
      </FancyBox>
    </div>
  );
};

export default Map;
