import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing_clevis_hanger_1.jpg";
import Second from "../../assets/img/pipe-clamps/ascfixing_clevis_hanger_2.png";
import Forth from "../../assets/img/pipe-clamps/ascfixing_clevis_hanger_teknik.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const ClevisHanger = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr14")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="flex shadow-lg mb-10 rounded-lg">
            <FancyBox
              options={{
                Carousel: {
                  infinite: true,
                },
                Autoplay: {
                  timeout: 1000,
                },
              }}
            >
              <ul className="flex flex-row">
                <li>
                  <Link autoplay data-fancybox="eklenti" to={First}>
                    <img className="image object-contain" src={First} alt="" />
                  </Link>
                </li>
                <li>
                  <Link data-fancybox="eklenti" to={Second}>
                    <img src={Second} alt="" />
                  </Link>
                </li>
              </ul>
            </FancyBox>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("pr14")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          {/* <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('application')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsa1')}
                        </span>
                    </li>

                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsa2')}</span>
                    </li>
                </ul>
            </div>
            <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('features')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf2')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf4')} {t('nsf8')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('n2')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf10')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf11')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf12')}
                        </span>
                    </li>
                </ul>
            </div> */}
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Pipe Size</td>
              <td></td>
              <td>H</td>
              <td>D</td>
              <td>A</td>
              <td>R</td>
              <td>Steel Dimensions</td>
              <td></td>
              <td>Design Load</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td>inch</td>
              <td>(mm)</td>
              <td>(mm)</td>
              <td>(mm)</td>
              <td>(mm)</td>
              <td></td>
              <td>Upper (mm)</td>
              <td>Lower (mm)</td>
              <td>kN</td>
            </tr>
            <tr>
              <td>ASC CH 0025</td>
              <td>1&quot;</td>
              <td>25</td>
              <td>78</td>
              <td>35</td>
              <td>58</td>
              <td>M10</td>
              <td>3x25</td>
              <td>3x25</td>
              <td>2.77</td>
            </tr>
            <tr>
              <td>ASC CH 0032</td>
              <td>11/4&quot;</td>
              <td>32</td>
              <td>88</td>
              <td>46</td>
              <td>63</td>
              <td>M10</td>
              <td>3x25</td>
              <td>3x25</td>
              <td>2.77</td>
            </tr>
            <tr>
              <td>ASC CH 0040</td>
              <td>11/2&quot;</td>
              <td>40</td>
              <td>101</td>
              <td>57</td>
              <td>70</td>
              <td>M10</td>
              <td>3x25</td>
              <td>3x25</td>
              <td>2.77</td>
            </tr>
            <tr>
              <td>ASC CH 0050</td>
              <td>2&quot;</td>
              <td>50</td>
              <td>123</td>
              <td>63</td>
              <td>78</td>
              <td>M10</td>
              <td>3x25</td>
              <td>3x25</td>
              <td>2.77</td>
            </tr>
            <tr>
              <td>ASC CH 0065</td>
              <td>21/2&quot;</td>
              <td>65</td>
              <td>155</td>
              <td>79</td>
              <td>96</td>
              <td>M12</td>
              <td>3x25</td>
              <td>3x25</td>
              <td>5</td>
            </tr>
            <tr>
              <td>ASC CH 0080</td>
              <td>3&quot;</td>
              <td>80</td>
              <td>170</td>
              <td>100</td>
              <td>111</td>
              <td>M12</td>
              <td>3x30</td>
              <td>3x30</td>
              <td>5</td>
            </tr>
            <tr>
              <td>ASC CH 0100</td>
              <td>4&quot;</td>
              <td>100</td>
              <td>210</td>
              <td>111</td>
              <td>138</td>
              <td>M16</td>
              <td>4x30</td>
              <td>3x30</td>
              <td>6.3</td>
            </tr>
            <tr>
              <td>ASC CH 0125</td>
              <td>5&quot;</td>
              <td>125</td>
              <td>246</td>
              <td>127</td>
              <td>163</td>
              <td>M16</td>
              <td>5x30</td>
              <td>5x30</td>
              <td>6.3</td>
            </tr>
            <tr>
              <td>ASC CH 0150</td>
              <td>6&quot;</td>
              <td>150</td>
              <td>283</td>
              <td>144</td>
              <td>191</td>
              <td>M20</td>
              <td>5x40</td>
              <td>5x40</td>
              <td>8.6</td>
            </tr>
            <tr>
              <td>ASC CH 0200</td>
              <td>8&quot;</td>
              <td>200</td>
              <td>372</td>
              <td>181</td>
              <td>231</td>
              <td>M20</td>
              <td>6x40</td>
              <td>5x40</td>
              <td>8.81</td>
            </tr>
            <tr>
              <td>ASC CH 0250</td>
              <td>10&quot;</td>
              <td>250</td>
              <td>435</td>
              <td>212</td>
              <td>308</td>
              <td>M22</td>
              <td>10x40</td>
              <td>6x40</td>
              <td>16</td>
            </tr>
          </table>
        </div>
        <FancyBox>
          <div className="image object-cover">
            <img src={Forth} data-FancyBox alt="" className="h-[320px] cursor-pointer drop-shadow-2xl" />
          </div>
        </FancyBox>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=16"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default ClevisHanger;
