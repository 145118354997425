import React from "react";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Footer from "../../components/Footer";
import pdfFile from '../../assets/pdf/ascfixing_new_catalogu_2022.pdf';
import AnimatedPage from "../../components/AnimatedPage";
const clickHandle = lang => {
  i18n.changeLanguage(lang)
}
const Catalogue = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-28">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('quality')}</h1>
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">{t('catologue')}</h2>
        </div>
        <div className="max-w-[1100px] my-28 mx-auto">
          <iframe src={pdfFile} frameborder="0" className="w-full h-screen"></iframe>
        </div>
        <Footer />
      </AnimatedPage>
    </div>
  );
};

export default Catalogue;
