import React from "react";
import { IoCallSharp } from "react-icons/io5";
import { GrMail } from "react-icons/gr";
import { MdLocationPin } from "react-icons/md";
import Logo from '../assets/asc-fixing-logo.png'
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Link, NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsYoutube,BsTwitter,BsLinkedin,BsInstagram } from "react-icons/bs";
import { FaFax } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}


const Footer = () => {
  const { t, i18n } = useTranslation()
  return (
    <footer className="bg-[#fff] text-black/90 justify-center mt-2">
      <div className="grid grid-cols-1 gap-10 mx-auto py-5 md:grid-cols-4 justify-center px-6">
        <div className="">
          <div className="com-logo pt-3">
            <a href="/">
              <img src={Logo} alt="" className="w-30 h-20" />
            </a>
          </div>
          <div className="pt-7 auto-cols-auto relative">
            <p className="text-sm">
            {t('footerdesc')}
            </p>
          </div>
          <div className="social-media-icons text-3xl pt-9 flex justify-start">
            <NavLink
              target="_blank"
              to="https://www.facebook.com/asc.ascelikfixing"
              className="hover:scale-110 duration-300"
            >
              <FaFacebookF size={28}/>
            </NavLink>

            <NavLink
             target="_blank"
             to="https://www.instagram.com/ascfixing/"
             className="pl-3 hover:scale-110 duration-300">
              <BsInstagram size={28} className=""/>
            </NavLink>

            <NavLink
              target="_blank"
              to="https://www.youtube.com/@ascfixing"
              className="pl-3 hover:scale-110 duration-300"
            >
              <BsYoutube size={28} />
            </NavLink>

            <NavLink
              target="_blank"
              to="https://twitter.com/ascfixing"
              className="pl-3 hover:scale-110 duration-300"
            >
              <BsTwitter size={28} />
            </NavLink>
            <NavLink
              target="_blank"
              to="https://www.linkedin.com/company/ascfixing/"
              className="pl-3 hover:scale-110 duration-300"
            >
              <BsLinkedin size={28} />
            </NavLink>
          </div>
        </div>
        <div className="">
          <h3 className="text-xl pb-3 font-semibold border-b-2 text-start">
          {t('links')}
          </h3>
          <ul className=" text-sm list-none pt-2">
            <NavLink to="/products">
              <li className="hover:underline p-1 my-2">{t('footerproducts')}</li>
            </NavLink>
            <NavLink to="/products/pipe-clamps">
              <li className="hover:underline p-1 my-2">{t('footerpipeclamps')}</li>
            </NavLink>
            <NavLink to="/products/profile-console">
              <li className="hover:underline p-1 my-2">{t('footerprofileconsole')}</li>
            </NavLink>
            <NavLink to="/products/other-fittings">
              <li className="hover:underline p-1 my-2">{t('footerotherfittings')}</li>
            </NavLink>
          </ul>
        </div>
        <div className="">
          <h3 className="text-xl pb-3 font-semibold border-b-2 text-start">
          {t('informations')}
          </h3>
          <ul className="text-sm list-none pt-2">
            <NavLink to="/quality/catalogue">
              <li className="hover:underline p-1 my-2">{t('catologue')}</li>
            </NavLink>
            <NavLink to="/quality/certificates">
              <li className="hover:underline p-1 my-2">{t('certificate')}</li>
            </NavLink>
            <NavLink to="/news/fair">
              <li className="hover:underline p-1 my-2">{t('footerfair')}</li>
            </NavLink>
            <NavLink to="/news/announcements">
              <li className="hover:underline p-1 my-2">{t('footerannouncements')}</li>
            </NavLink>
          </ul>
        </div>
        <div className="">
          <h3 className="text-xl pb-3 font-semibold border-b-2 text-start">
          {t('footercontact')}
          </h3>
          <ul className="text-sm max-[769px]:text-xs list-none text-start pt-2">
            <li className=" hover:underline flex cursor-pointer py-1 my-2 ">
              <IoLogoWhatsapp size={32} className="pr-3 pb-2" />
              <a href="tel:+90 530 915 81 11">+90 (530) 915 81 11</a>
            </li>
            <li className="hover:underline flex cursor-pointer pt-4 py-1">
              <IoCallSharp size={32} className="pr-3 pb-2" />
              <a href="tel:+90 212 620 3344">+90 (212) 620 33 44</a>
            </li>
            <li className="flex py-1 my-2">
              <FaFax size={32} className="pr-3 pb-2" />
              <p href="fax:+90 212 450 20 22"> +90 (212) 450 20 22</p>
            </li>
            <li className="hover:underline flex cursor-pointer py-1 my-2">
              <GrMail size={32} className="pr-3 pb-2" />
              <a href="mailto:info@ascfixing.com">info@ascfixing.com</a>
            </li>
            <li className="hover:underline flex cursor-pointer py-1 my-2">
              <MdLocationPin size={52} className="pr-3 pb-2" />
              <NavLink to="https://goo.gl/maps/xbkaNu69ZWXJc8Rr9" target="_blank">
                Karaagac Mahallesi Hadimkoy Istanbul Caddesi No:28 34500
                Buyukcekmece - Istanbul / TURKEY
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="relative bg-blue-700 w-full h-16">
        <span className="flex justify-center items-start text-start max-md:text-[6px] max-sm:text-[4px] text-white/80 px-auto pt-5">Asc Ascelik Clamp & Fixing || Copyright ©
          
          <span><Link target='_blank' to="https://procollec.com/" className="px-1"> Procollec Technology and Engineering</Link></span> </span>
      </div>
    </footer>
  );
};

export default Footer;
