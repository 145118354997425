import React from "react";
import { Link, NavLink } from "react-router-dom";
import PipeClamp from "../assets/img/products-main/pipe-clamp-main.jpg";
import ProfileConsole from '../assets/img/products-main/profile-console-main.jpg'
import OtherFittings from '../assets/img/products-main/other-fittings-main.jpg'
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const clickHandle = lang => {
  i18n.changeLanguage(lang)
}
const Products = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className="grid group mt-10 overflow-hidden" data-aos="fade-left"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine">
      <div className="pl-[25px] relative md:pl-[101px]" >
        <div className="w-[20px] h-14 bg-[#1D4ED8] rounded-sm absolute"></div>
        <h1 className="flex justify-start font-bold text-xl md:text-4xl text-[#1D4ED8] pt-2 pb-5 ml-7">
          {t('products')}
        </h1>
      </div>

      <div className="container-item flex flex-col lg:flex-row justify-center h-auto px-6 mx-auto">
        <Link to='/products/pipe-clamps'>
          <div className="mx-6 my-6 bg-white shadow-lg hover:scale-105 duration-500">
            <img className="flex justify-center mx-auto w-[75%]"
              src={PipeClamp} alt="/" />
            <p className="flex justify-center text-center pt-4 font-semibold text-xl max-sm:text-[10px] max-xl:text-sm mx-auto text-gray-700 pb-4">
              {t('pipeclamps')}
            </p>
          </div>
        </Link>
        <Link to='/products/profile-console'>
          <div className="mx-6 my-6 bg-white shadow-lg hover:scale-105 hover:text-red-500 duration-500">
            <img className="flex justify-center mx-auto w-[75%]"
              src={ProfileConsole}
              alt="/"
            />
            <p className="flex justify-center text-center pt-4 font-semibold text-xl max-sm:text-[10px] max-xl:text-sm mx-auto text-gray-700 pb-4">
              {t('profileconsole')}
            </p>
          </div>
        </Link>
        <NavLink target='' to='/products/other-fittings'>
          <div className="mx-6 my-6 bg-white shadow-lg hover:scale-105 duration-500">
            <img className="flex justify-center mx-auto w-[75%]"
              src={OtherFittings}
              alt="/"
            />
            <p className="flex justify-center text-center pt-4 font-semibold text-xl max-sm:text-[10px] max-xl:text-sm mx-auto text-gray-700 pb-4">
              {t('otherfittings')}
            </p>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Products;
