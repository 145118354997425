import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import Footer from "../../components/Footer";
import Marka from "../../assets/img/markatescilbelge.jpg";
import Kalite from "../../assets/img/kaliteyonetimi.jpg";
import Gost from "../../assets/img/gost-r.jpg";
import Sicil from "../../assets/img/sicilbelgesi.jpg";
import Standart from "../../assets/img/tsekurumu.jpg";
import Uygun from "../../assets/img/uygunlukbeyani.jpg";
import Belge from "../../assets/img/belgelendirme.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import AnimatedPage from "../../components/AnimatedPage";
import { Link } from "react-router-dom";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const Certificates = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-28">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
            {t("quality")}
          </h1>
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("certificate")}
          </h2>
        </div>
        <div
          className="pl-[25px] relative md:pl-[125px]"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="w-[20px] h-14 bg-[#1D4ED8] rounded-sm absolute"></div>
          <h1 className="flex justify-start font-bold text-2xl md:text-4xl text-[#1D4ED8] pt-2 pb-5 ml-7">
            {t("navcertificate")}
          </h1>
        </div>
        <div
          className="max-w-[1140px] m-auto p-4 "
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Marka}>
                    <img src={Marka} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      Brand Registration Certificate
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Kalite}>
                    <img src={Kalite} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      QUALITY MENAGEMENT ISO 9001 2015
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Gost}>
                    <img src={Gost} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      GOST-R CERTIFICATE
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>

            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Sicil}>
                    <img src={Sicil} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      Industrial Registration Cartificate
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>

            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Standart}>
                    <img src={Standart} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      TURKISH STANDARDS INSTITUTION
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>

            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Uygun}>
                    <img src={Uygun} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      DECLARATION OF CONFORMITY
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>

            <div className="bg-white shadow-xl p-4 group">
              <FancyBox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <div className="group-hover:scale-110 duration-500 ">
                  <Link data-fancybox="eklenti" to={Belge}>
                    <img src={Belge} alt="/" />
                    <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                      CERTIFICATE OF CONFORMITY
                    </h1>
                  </Link>
                </div>
              </FancyBox>
            </div>
          </div>
        </div>
        <FixedImage />
        <Footer />
      </AnimatedPage>
    </div>
  );
};

export default Certificates;
