import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import Slider1 from "../assets/img/slider-images/Slider-1.jpg";
import Slider2 from "../assets/img/slider-images/Slider-2.jpg";
import FancyBox from "./FancyBox";

const ImageSlider = () => {
  const slides = [
    {
      url: Slider1,
      title: "Slider1",
    },
    {
      url: Slider2,
      title: "Slider2",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <FancyBox
      options={{
        Carousel: {
          infinite: true,
        },
        Autoplay: {
          timeout: 1000,
        },
      }}
    >
      <div className="flex flex-col items-center justify-center md:h-[800px] w-full h-[300px] mt-[100px] md:mt-[70px] group">
        <div
          data-src
          className="w-full h-full bg-center bg-cover duration-500"
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        ></div>
        <div className="absolute flex flex-row w-full items-center justify-between px-5 m-auto">
          {/* Left Arrow  */}
          <div className="text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer">
            <BsChevronCompactLeft onClick={prevSlide} size={35} />
          </div>
          {/* Right Arrow  */}
          <div className="text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer">
            <BsChevronCompactRight onClick={nextSlide} size={35} />
          </div>
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              className="text-2xl cursor-pointer"
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
            >
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div>
    </FancyBox>
  );
};

export default ImageSlider;
