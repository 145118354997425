import { Route, Routes, useLocation } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Fair from "./pages/news-alt/Fair";
import Home from "./pages/Home";
import News from "./pages/News";
import Pdf from "./pages/Pdf";
import ProductsPage from "./pages/ProductsPage";
import OtherFittings from "./pages/OtherFittings";
import PipeClamps from "./pages/PipeClamps";
import ProfileConsole from "./pages/ProfileConsole";
import SteelRail from "./pages/product-2/SteelRail";
import CProfile from "./pages/product-2/CProfile";
import Quality from "./pages/Quality";
import Catalogue from "./pages/quality-alt/Catalogue";
import GProfile from "./pages/product-2/GProfile";
import LProfile from "./pages/product-2/LProfile";
import UProfile from "./pages/product-2/UProfile";
import CRailConsole from "./pages/product-2/CRailConsole";
import LRailConsole from "./pages/product-2/LRailConsole";
import URailConsole from "./pages/product-2/URailConsole";
import Certificates from "./pages/quality-alt/Certificates";
import Announcements from "./pages/news-alt/Announcements";
import ScrollToTop from "./components/ScrollToTop";
import NutSet from "./pages/product-1/NutSet";
import NoRubberSet from "./pages/product-1/NoRubberSet";
import ProfileCap from "./pages/product-3/ProfileCap";
import Nut from "./pages/product-1/Nut";
import PerforedMounting from "./pages/product-3/PerforedMounting";
import GRailConsole from "./pages/product-2/GRailConsole";
import ShapedWasher from "./pages/product-3/ShapedWasher";
import SlidingSupport from "./pages/product-3/SlidingSupport";
import CornerVentilation from "./pages/product-3/CornerVentilation";
import BeamClamp from "./pages/product-3/BeamClamp";
import GClimps from "./pages/product-3/GClimps";
import VTrapezeHangar from "./pages/product-3/VTrapezeHangar";
import QuickFit from "./pages/product-3/QuickFix";
import SlideNudeTb2 from "./pages/product-3/SlideNudeTb2";
import SlideNudeTb3 from "./pages/product-3/SlideNudeTb3";
import SlideNudeTb4 from "./pages/product-3/SlideNudeTb4";
import SlideNudeTb5 from "./pages/product-3/SlideNudeTb5";
import SlideNude from "./pages/product-3/SlideNude";
import SquareSlideNude from "./pages/product-3/SquareSlideNude";
import SpringSlideNude from "./pages/product-3/SpringSlideNude";
import WallPlateNut from "./pages/product-3/WallPlateNut";
import WallPlateNutWelded from "./pages/product-3/WallPlateNutWelded";
import WallPlateNutBolt from "./pages/product-3/WallPlateNutBolt";
import WallPlateBoldWelded from "./pages/product-3/WallPlateBoldWelded";
import WallPlateNutMuff from "./pages/product-3/WallPlateNutMuff";
import ThreadedRod from "./pages/product-3/ThreadedRod";
import StudBolt from "./pages/product-3/StudBolt";
import NudDin from "./pages/product-3/NudDin";
import ExtensionNutDin6334 from "./pages/product-3/ExtensionNutDin6334";
import WasherDin9021 from "./pages/product-3/WasherDin9021";
import WasherDin125 from "./pages/product-3/WasherDin125";
import WasherInchWhitWorth from "./pages/product-3/WasherInchWhitWorth";
import SpringWasherDin127 from "./pages/product-3/SpringWasherDin127";
import SquareWasher from "./pages/product-3/SquareWasher";
import SquareWasherProfile from "./pages/product-3/SquareWasherProfile";
import BoltDin933 from "./pages/product-3/BoltDin933";
import TriphoneAdaptor from "./pages/product-3/TriphoneAdaptor";
import DropAnchor from "./pages/product-3/DropAnchor";
import DrawAnchorSType from "./pages/product-3/DrawAnchorSType";
import WedgeAnchor from "./pages/product-3/WedgeAnchor";
import PipeTypeAnchor from "./pages/product-3/PipeTypeAnchor";
import SleeveAnchor from "./pages/product-3/SleeveAnchor";
import CeilingAnchor from "./pages/product-3/CeilingAnchor";
import HookAnchor from "./pages/product-3/HookAnchor";
import StandartPlasticAnchorWhite from "./pages/product-3/StandartPlasticAnchorWhite";
import StandartPlasticAnchorGray from "./pages/product-3/StandartPlasticAnchorGray";
import BrickCavityAnchor from "./pages/product-3/BrickCavityAnchor";
import RocketPlasticAnchor from "./pages/product-3/RocketPlasticAnchor";
import BrickAnchor from "./pages/product-3/BrickAnchor";
import LavatoryAssemblySet from "./pages/product-3/LavatoryAssemblySet";
import ClosetAssemblySet from "./pages/product-3/ClosetAssemblySet";
import SpringAssemblyLavatorySet from "./pages/product-3/SpringAssemblyLavatorySet";
import WaterHeaterAssemblySet from "./pages/product-3/WaterHeaterAssemblySet";
import CollectorBoxWithConsole from "./pages/product-3/CollectorBoxWithConsole";
import CollectorBox from "./pages/product-3/CollectorBox";
import CollectorClampSquare from "./pages/product-3/CollectorClampSquare";
import CollectorClampRound from "./pages/product-3/CollectorClampRound";
import AdjustablePlasticClamp from "./pages/product-3/AdjustablePlasticClamp";
import PipeClampCombiNut from "./pages/product-1/PipeClampCombiNut";
import NoRubberWithCombiNut from "./pages/product-1/NoRubberWithCombiNut";
import PipeClampWithTriphone7x70 from "./pages/product-1/PipeClampWithTriphone7x70";
import NoRubberPipeClampWithTriphone7x70 from "./pages/product-1/NoRubberPipeClampWithTriphone7x70";
import PipeClampWithTriphone7x100 from "./pages/product-1/PipeClampWithTriphone7x100";
import NoRubberPipeClampWithTriphone7x100 from "./pages/product-1/NoRubberPipeClampWithTriphone7x100";
import HeavyDutyHeaded from "./pages/product-1/HeavyDutyHeaded";
import HeavyDutyWithNut from "./pages/product-1/HeavyDutyWithNut";
import PlatformHeavyDutyClamp from "./pages/product-1/PlatformHeavyDutyClamp";
import ClevisHanger from "./pages/product-1/ClevisHanger";
import SprinklerPipeClamp from "./pages/product-1/SprinklerPipeClamp";
import SleeveSprinklerPipeClamp from "./pages/product-1/SleeveSprinklerPipeClamp";
import SlientPipeClampWithNutVertical from "./pages/product-1/SlientPipeClampWithNutVertical";
import SlientPipeClampWithNutHorizontal from "./pages/product-1/SlientPipeClampWithNutHorizontal";
import SlientPipeClampWithStand from "./pages/product-1/SlientPipeClampWithStand";
import SlientPipeClampWithTriphoneVertical from "./pages/product-1/SlientPipeClampWithTriphoneVertical";
import SlientPipeClampWithTriphoneHorizontal from "./pages/product-1/SlientPipeClampWithTriphoneHorizontal";
import ItalianTypePipeClampSet from "./pages/product-1/ItalianTypePipeClampSet";
import ItalianTypePipeClampWithNut from "./pages/product-1/ItalianTypePipeClampWithNut";
import ItalianTypePipeClampWithCombiNut from "./pages/product-1/ItalianTypePipeClampWithCombiNut";
import StainlessSteelClampWithNut from "./pages/product-1/StainlessSteelClampWithNut";
import StainlessSteelClampWithCombiNut from "./pages/product-1/StainlessSteelClampWithCombiNut";
import IsolationBlock from "./pages/product-1/IsolationBlock";
import UBolt from "./pages/product-1/UBolt";
import NoRubber from "./pages/product-1/NoRubber";
import { AnimatePresence } from "framer-motion";
import Search from "./pages/Search";

function App() {
  const location = useLocation();
  return (
    <div>
      <ScrollToTop>
        <AnimatePresence>
          <Routes key={location.pathname} location={location}>
            {/* Navbar Routes  */}
            <Route path="/" element={<Home />} />
            <Route exact={false} path="/about" element={<About />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/quality" element={<Quality />} />
            <Route path="/news" element={<News />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/products/ascfixing_new_catalogu_2022.pdf"
              element={<Pdf />}
            />
            {/* Quality Alt Sayfalar  */}
            <Route path="/quality/certificates" element={<Certificates />} />
            <Route path="/quality/catalogue" element={<Catalogue />} />
            {/* News Alt sayfalar  */}
            <Route path="/news/fair" element={<Fair />} />
            <Route path="/news/announcements" element={<Announcements />} />
            {/* Products Pages İçerisi */}
            <Route path="/products/pipe-clamps" element={<PipeClamps />} />
            <Route
              path="/products/other-fittings"
              element={<OtherFittings />}
            />
            <Route
              path="/products/profile-console"
              element={<ProfileConsole />}
            />
            {/* Pipe Clamps Detay Sayfaları Route  */}
            <Route path="/products/pipe-clamps/nut-set" element={<NutSet />} />
            <Route
              path="/products/pipe-clamps/no-rubber-set"
              element={<NoRubberSet />}
            />
            <Route path="/products/pipe-clamps/nut" element={<Nut />} />
            <Route
              path="/products/pipe-clamps/no-rubber"
              element={<NoRubber />}
            />
            <Route
              path="/products/pipe-clamps/pipe-clamp-with-combi-nut"
              element={<PipeClampCombiNut />}
            />
            <Route
              path="/products/pipe-clamps/no-rubber-pipe-clamp-with-combi-nut"
              element={<NoRubberWithCombiNut />}
            />
            <Route
              path="/products/pipe-clamps/pipe-clamp-with-triphone-7x70"
              element={<PipeClampWithTriphone7x70 />}
            />
            <Route
              path="/products/pipe-clamps/no-rubber-pipe-clamp-with-triphone-7x70"
              element={<NoRubberPipeClampWithTriphone7x70 />}
            />
            <Route
              path="/products/pipe-clamps/pipe-clamp-with-triphone-7x100"
              element={<PipeClampWithTriphone7x100 />}
            />
            <Route
              path="/products/pipe-clamps/no-rubber-pipe-clamp-with-triphone-7x100"
              element={<NoRubberPipeClampWithTriphone7x100 />}
            />
            <Route
              path="/products/pipe-clamps/heavy-duty-pipe-clamp(headed)"
              element={<HeavyDutyHeaded />}
            />
            <Route
              path="/products/pipe-clamps/heavy-duty-pipe-clamp-with-nut"
              element={<HeavyDutyWithNut />}
            />
            <Route
              path="/products/pipe-clamps/platform-heavy-duty-clamp"
              element={<PlatformHeavyDutyClamp />}
            />
            <Route
              path="/products/pipe-clamps/clevis-hanger"
              element={<ClevisHanger />}
            />
            <Route
              path="/products/pipe-clamps/sprinkler-pipe-clamp"
              element={<SprinklerPipeClamp />}
            />
            <Route
              path="/products/pipe-clamps/sleeve-sprinkler-pipe-clamp"
              element={<SleeveSprinklerPipeClamp />}
            />
            <Route
              path="/products/pipe-clamps/slient-pipe-clamp-with-nut(vertical)"
              element={<SlientPipeClampWithNutVertical />}
            />
            <Route
              path="/products/pipe-clamps/slient-pipe-clamp-with-nut(horizontal)"
              element={<SlientPipeClampWithNutHorizontal />}
            />
            <Route
              path="/products/pipe-clamps/slient-pipe-clamp-with-stand"
              element={<SlientPipeClampWithStand />}
            />
            <Route
              path="/products/pipe-clamps/slient-pipe-clamp-with-triphone(vertical)"
              element={<SlientPipeClampWithTriphoneVertical />}
            />
            <Route
              path="/products/pipe-clamps/slient-pipe-clamp-with-triphone(horizontal)"
              element={<SlientPipeClampWithTriphoneHorizontal />}
            />
            <Route
              path="/products/pipe-clamps/italian-type-pipe-clamp(set)"
              element={<ItalianTypePipeClampSet />}
            />
            <Route
              path="/products/pipe-clamps/italian-type-pipe-clamp-with-nut"
              element={<ItalianTypePipeClampWithNut />}
            />
            <Route
              path="/products/pipe-clamps/italian-type-pipe-clamp-with-combi-nut"
              element={<ItalianTypePipeClampWithCombiNut />}
            />
            <Route
              path="/products/pipe-clamps/stainless-steel-clamp-with-nut"
              element={<StainlessSteelClampWithNut />}
            />
            <Route
              path="/products/pipe-clamps/stainless-steel-clamp-with-combi-nut"
              element={<StainlessSteelClampWithCombiNut />}
            />
            <Route
              path="/products/pipe-clamps/isolation-block"
              element={<IsolationBlock />}
            />
            <Route path="/products/pipe-clamps/u-bolt" element={<UBolt />} />
            {/* Profile & Console Detay sayfaları Route  */}
            <Route
              path="/products/profile-console/steel-rail"
              element={<SteelRail />}
            />
            <Route
              path="/products/profile-console/c-profile"
              element={<CProfile />}
            />
            <Route
              path="/products/profile-console/g-profile"
              element={<GProfile />}
            />
            <Route
              path="/products/profile-console/l-profile"
              element={<LProfile />}
            />
            <Route
              path="/products/profile-console/u-profile"
              element={<UProfile />}
            />
            <Route
              path="/products/profile-console/c-rail-console"
              element={<CRailConsole />}
            />
            <Route
              path="/products/profile-console/l-rail-console"
              element={<LRailConsole />}
            />
            <Route
              path="/products/profile-console/u-rail-console"
              element={<URailConsole />}
            />
            <Route
              path="/products/profile-console/g-rail-console"
              element={<GRailConsole />}
            />
            {/* Other Fitting Detay Sayfaları Route  */}
            <Route
              path="/products/other-fittings/profile-cap"
              element={<ProfileCap />}
            />
            <Route
              path="/products/other-fittings/perfored-mounting"
              element={<PerforedMounting />}
            />
            <Route
              path="/products/other-fittings/shaped-washer"
              element={<ShapedWasher />}
            />
            <Route
              path="/products/other-fittings/sliding-support"
              element={<SlidingSupport />}
            />
            <Route
              path="/products/other-fittings/corner-for-ventilation"
              element={<CornerVentilation />}
            />
            <Route
              path="/products/other-fittings/beam-clamp"
              element={<BeamClamp />}
            />
            <Route
              path="/products/other-fittings/g-climps"
              element={<GClimps />}
            />
            <Route
              path="/products/other-fittings/v-trapeze-hangar"
              element={<VTrapezeHangar />}
            />
            <Route
              path="/products/other-fittings/quick-fit"
              element={<QuickFit />}
            />
            <Route
              path="/products/other-fittings/slide-nude-tb2"
              element={<SlideNudeTb2 />}
            />
            <Route
              path="/products/other-fittings/slide-nude-tb3"
              element={<SlideNudeTb3 />}
            />
            <Route
              path="/products/other-fittings/slide-nude-tb4"
              element={<SlideNudeTb4 />}
            />
            <Route
              path="/products/other-fittings/slide-nude-tb5"
              element={<SlideNudeTb5 />}
            />
            <Route
              path="/products/other-fittings/slide-nude"
              element={<SlideNude />}
            />
            <Route
              path="/products/other-fittings/square-slide-nude"
              element={<SquareSlideNude />}
            />
            <Route
              path="/products/other-fittings/spring-slide-nude"
              element={<SpringSlideNude />}
            />
            <Route
              path="/products/other-fittings/wall-plate-with-nut"
              element={<WallPlateNut />}
            />
            <Route
              path="/products/other-fittings/wall-plate-with-nut-with-welded"
              element={<WallPlateNutWelded />}
            />
            <Route
              path="/products/other-fittings/wall-plate-with-nut-with-bolt"
              element={<WallPlateNutBolt />}
            />
            <Route
              path="/products/other-fittings/wall-plate-with-nut-with-bolt-welded"
              element={<WallPlateBoldWelded />}
            />
            <Route
              path="/products/other-fittings/wall-plate-with-nut-with-muff"
              element={<WallPlateNutMuff />}
            />
            <Route
              path="/products/other-fittings/threaded-rod"
              element={<ThreadedRod />}
            />
            <Route
              path="/products/other-fittings/stud-bolt"
              element={<StudBolt />}
            />
            <Route
              path="/products/other-fittings/nud-din"
              element={<NudDin />}
            />
            <Route
              path="/products/other-fittings/extension-nut-din6334"
              element={<ExtensionNutDin6334 />}
            />
            <Route
              path="/products/other-fittings/washer-din125"
              element={<WasherDin125 />}
            />
            <Route
              path="/products/other-fittings/washer-din9021"
              element={<WasherDin9021 />}
            />
            <Route
              path="/products/other-fittings/washer-inch-whitworth"
              element={<WasherInchWhitWorth />}
            />
            <Route
              path="/products/other-fittings/spring-washer-din127"
              element={<SpringWasherDin127 />}
            />
            <Route
              path="/products/other-fittings/square-washer"
              element={<SquareWasher />}
            />
            <Route
              path="products/other-fittings/square-washer-for-profile"
              element={<SquareWasherProfile />}
            />
            <Route
              path="products/other-fittings/bolt-din933"
              element={<BoltDin933 />}
            />
            <Route
              path="products/other-fittings/triphone-adaptor"
              element={<TriphoneAdaptor />}
            />
            <Route
              path="products/other-fittings/drop-in-anchor"
              element={<DropAnchor />}
            />
            <Route
              path="/products/other-fittings/draw-in-anchor-stype"
              element={<DrawAnchorSType />}
            />
            <Route
              path="/products/other-fittings/wedge-anchor"
              element={<WedgeAnchor />}
            />
            <Route
              path="/products/other-fittings/pipe-type-anchor"
              element={<PipeTypeAnchor />}
            />
            <Route
              path="/products/other-fittings/sleeve-anchor"
              element={<SleeveAnchor />}
            />
            <Route
              path="/products/other-fittings/ceiling-anchor"
              element={<CeilingAnchor />}
            />
            <Route
              path="/products/other-fittings/hook-anchor"
              element={<HookAnchor />}
            />
            <Route
              path="/products/other-fittings/standart-plastic-anchor-white"
              element={<StandartPlasticAnchorWhite />}
            />
            <Route
              path="/products/other-fittings/standart-plastic-anchor-gray"
              element={<StandartPlasticAnchorGray />}
            />
            <Route
              path="/products/other-fittings/brick-cavity-anchor"
              element={<BrickCavityAnchor />}
            />
            <Route
              path="/products/other-fittings/rocket-plastic-anchor"
              element={<RocketPlasticAnchor />}
            />
            <Route
              path="/products/other-fittings/brick-anchor"
              element={<BrickAnchor />}
            />
            <Route
              path="/products/other-fittings/lavatory-assembly-set"
              element={<LavatoryAssemblySet />}
            />
            <Route
              path="/products/other-fittings/closet-assembly-set"
              element={<ClosetAssemblySet />}
            />
            <Route
              path="/products/other-fittings/spring-assembly-lavatory-set"
              element={<SpringAssemblyLavatorySet />}
            />
            <Route
              path="/products/other-fittings/water-heater-assembly-set"
              element={<WaterHeaterAssemblySet />}
            />
            <Route
              path="/products/other-fittings/collector-box-with-console"
              element={<CollectorBoxWithConsole />}
            />
            <Route
              path="/products/other-fittings/collector-box"
              element={<CollectorBox />}
            />
            <Route
              path="/products/other-fittings/collector-clamp-square"
              element={<CollectorClampSquare />}
            />
            <Route
              path="/products/other-fittings/collector-clamp-round"
              element={<CollectorClampRound />}
            />
            <Route
              path="/products/other-fittings/adjustable-plastic-clamp"
              element={<AdjustablePlasticClamp />}
            />
            <Route path="/search/:id" element={<Search />} />
          </Routes>
        </AnimatePresence>
      </ScrollToTop>
    </div>
  );
}
export default App;
