import React from "react";
import Logo from '../assets/asc-fixing-beyaz-logo.png' 
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}

const FixedImage = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className='flex flex-col justify-center items-center w-full h-[400px] bg-no-repeat bg-[url("assets/img/parallax-asc.jpg")] bg-fixed'>
      <div className="text-[#fff]">
        <img className="flex justify-center items-center md:ml-20 bg-contain" src={Logo} alt="/" />
        <h1 className="flex justify-center text-3xl font-bold md:text-6xl italic pt-10">{t('qualityis')}</h1>
        <h2 className="flex justify-center items-center text-xl md:text-4xl italic py-4">{t('ourbusiness')}</h2>
      </div>
    </div>
  );
};

export default FixedImage;
