import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_slide_nut.png";
import Second from "../../assets/img/other-fittings/yaysız somun teknik resim.png";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const SlideNude = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of14")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of14")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto mb-10 min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Size</td>
              <td>Width (mm)</td>
              <td>Length (mm)</td>
              <td>Thickness (mm)</td>
            </tr>
            <tr>
              <td>ASC KS M8-6</td>
              <td>M8</td>
              <td>34</td>
              <td>19</td>
              <td>6,0</td>
            </tr>
            <tr>
              <td>ASC KS M10-6</td>
              <td>M10</td>
              <td>34</td>
              <td>19</td>
              <td>6,0</td>
            </tr>
            <tr>
              <td>ASC KS M12-6</td>
              <td>M12</td>
              <td>34</td>
              <td>19</td>
              <td>6,0</td>
            </tr>
            <tr>
              <td>ASC KS M8-8</td>
              <td>M8</td>
              <td>34</td>
              <td>19</td>
              <td>8,0</td>
            </tr>
            <tr>
              <td>ASC KS M10-8</td>
              <td>M10</td>
              <td>34</td>
              <td>19</td>
              <td>8,0</td>
            </tr>
            <tr>
              <td>ASC KS M12-8</td>
              <td>M12</td>
              <td>34</td>
              <td>19</td>
              <td>8,0</td>
            </tr>
            <tr>
              <td>ASC KS M8-10</td>
              <td>M8</td>
              <td>34</td>
              <td>19</td>
              <td>10,0</td>
            </tr>
            <tr>
              <td>ASC KS M10-10</td>
              <td>M10</td>
              <td>34</td>
              <td>19</td>
              <td>10,0</td>
            </tr>
            <tr>
              <td>ASC KS M12-10</td>
              <td>M12</td>
              <td>34</td>
              <td>19</td>
              <td>10,0</td>
            </tr>
          </table>
        </div>
        <FancyBox>
          <div>
            <Link className="image object-cover mt-3">
              <img
                data-fancybox="detay"
                src={Second}
                alt=""
                className="h-[250px] drop-shadow-2xl"
              />
            </Link>
          </div>
        </FancyBox>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=57"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default SlideNude;
