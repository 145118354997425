import React from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Main from '../assets/img/pipe-clamps/ascfixing_pipe_clamp_main.jpg';
import Second from '../assets/img/pipe-clamps/ascfixing_no_rubber_pipe_clamp_with_nut_set.jpg'
import Third from '../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_nut_1.jpg'
import Fourth from '../assets/img/pipe-clamps/ascfixing_no_rubber_pipe_clamp_with_nut.jpg'
import Fifth from '../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_combi_nut_1.jpg'
import p6 from '../assets/img/pipe-clamps/ascfixing_no_rubber_pipe_clamp_with_combi_nut.png'
import p7 from '../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_ 7x70.jpg'
import p8 from '../assets/img/pipe-clamps/ascfixing_no_rubber_pipe_clamp_with_triphone_7x70_1.jpg'
import p9 from '../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_7x100-lastikli.jpg'
import p10 from '../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_7x100.jpg'
import p11 from '../assets/img/pipe-clamps/ascfixing_heavy_duty_pipe_clamp_headed_1.jpg'
import p12 from '../assets/img/pipe-clamps/ascfixing_heavy_duty_pipe_clamp_with_nut_1.jpg'
import p13 from '../assets/img/pipe-clamps/ascfixing_platform_hevy_duty_pipe_clamp_2.PNG'
import p14 from '../assets/img/pipe-clamps/ascfixing_clevis_hanger_1.jpg'
import p15 from '../assets/img/pipe-clamps/ascfixing_sprinkler_pipe_clamp.jpg'
import p16 from '../assets/img/pipe-clamps/ascfixing_sleeve_prinkler_pipe_clamp.jpg'
import p17 from '../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_nut_vertical_2.jpg'
import p18 from '../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_nut_horizantal.jpg'
import p19 from '../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_stand.jpg'
import p20 from '../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_triphone_vertical.jpg'
import p21 from '../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_triphone_horizontal_1.jpg'
import p22 from '../assets/img/pipe-clamps/ascfixing_italian_type_pipe_clamp_set.jpg'
import p23 from '../assets/img/pipe-clamps/ascfixing_italian_type_pipe_clamp.jpg'
import p24 from '../assets/img/pipe-clamps/ascfixing_italian_type_pipe_clamp_combi.jpg'
import p25 from '../assets/img/pipe-clamps/ascfixing_stainless_steel_pipe_clamp_with_nut_.jpg'
import p26 from '../assets/img/pipe-clamps/ascfixing_stainless_steel_pipe_clamp_with_combi_nut_.jpg'
import p27 from '../assets/img/pipe-clamps/ascfixing insulation_block.jpg'
import p28 from '../assets/img/pipe-clamps/ascfixing_u_bolt.jpg'
import p29 from '../assets/img/pipe-clamps/ascfixing_single_clamp.jpg'
import p30 from '../assets/img/pipe-clamps/ascfixing_double_clamp.jpg'

import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}
const PipeClamps = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('products')}</h1>
        <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">{t('pipeclamps')}</h2>
      </div>
      <div className="max-w-[1140px] m-auto p-4 " data-aos="fade-up" data-aos-duration="3000">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
          {/* Ürünler Başlangıcı */}
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/nut-set">
              <div className="overflow-hidden">
                <img
                  src={Main}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
               {t('pr1')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/no-rubber-set">
              <div className="overflow-hidden">
                <img
                  src={Second}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr2')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/nut">
              <div className="overflow-hidden">
                <img
                  src={Third}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr3')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/no-rubber">
              <div className="overflow-hidden">
                <img
                  src={Fourth}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr4')}
              </h1>
            </Link>
          </div>

          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/pipe-clamp-with-combi-nut">
              <div className="overflow-hidden">
                <img
                  src={Fifth}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr5')}
              </h1>
            </Link>
          </div>

          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/no-rubber-pipe-clamp-with-combi-nut">
              <div className="overflow-hidden">
                <img
                  src={p6}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr6')}
              </h1>
            </Link>
          </div>

          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/pipe-clamp-with-triphone-7x70">
              <div className="overflow-hidden">
                <img
                  src={p7}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr7')}
              </h1>
            </Link>
          </div>

          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/no-rubber-pipe-clamp-with-triphone-7x70">
              <div className="overflow-hidden">
                <img
                  src={p8}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr8')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/pipe-clamp-with-triphone-7x100">
              <div className="overflow-hidden">
                <img
                  src={p9}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr9')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/no-rubber-pipe-clamp-with-triphone-7x100">
              <div className="overflow-hidden">
                <img
                  src={p10}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr10')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/heavy-duty-pipe-clamp(headed)">
              <div className="overflow-hidden">
                <img
                  src={p11}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr11')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/heavy-duty-pipe-clamp-with-nut">
              <div className="overflow-hidden">
                <img
                  src={p12}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr12')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/platform-heavy-duty-clamp">
              <div className="overflow-hidden">
                <img
                  src={p13}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr13')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/clevis-hanger">
              <div className="overflow-hidden">
                <img
                  src={p14}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr14')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/sprinkler-pipe-clamp">
              <div className="overflow-hidden">
                <img
                  src={p15}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr15')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/sleeve-sprinkler-pipe-clamp">
              <div className="overflow-hidden">
                <img
                  src={p16}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr16')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/slient-pipe-clamp-with-nut(vertical)">
              <div className="overflow-hidden">
                <img
                  src={p17}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr17')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/slient-pipe-clamp-with-nut(horizontal)">
              <div className="overflow-hidden">
                <img
                  src={p18}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr18')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/slient-pipe-clamp-with-stand">
              <div className="overflow-hidden">
                <img
                  src={p19}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr19')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/slient-pipe-clamp-with-triphone(vertical)">
              <div className="overflow-hidden">
                <img
                  src={p20}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr20')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/slient-pipe-clamp-with-triphone(horizontal)">
              <div className="overflow-hidden">
                <img
                  src={p21}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr21')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/italian-type-pipe-clamp(set)">
              <div className="overflow-hidden">
                <img
                  src={p22}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr22')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/italian-type-pipe-clamp-with-nut">
              <div className="overflow-hidden">
                <img
                  src={p23}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr23')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/italian-type-pipe-clamp-with-combi-nut">
              <div className="overflow-hidden">
                <img
                  src={p24}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr24')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/stainless-steel-clamp-with-nut">
              <div className="overflow-hidden">
                <img
                  src={p25}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr25')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/stainless-steel-clamp-with-combi-nut">
              <div className="overflow-hidden">
                <img
                  src={p26}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr26')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/isolation-block">
              <div className="overflow-hidden">
                <img
                  src={p27}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr27')}
              </h1>
            </Link>
          </div>
          <div className="bg-white shadow-xl p-4 group">
            <Link to="/products/pipe-clamps/u-bolt">
              <div className="overflow-hidden">
                <img
                  src={p28}
                  alt=""
                  className="group-hover:scale-110 duration-500"
                />
              </div>
              <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
              {t('pr28')}
              </h1>
            </Link>
          </div>
          {/* Ürünler Sonu */}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PipeClamps