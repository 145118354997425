import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

const resources = {
  tr: {
    translation: {
      //Search Page 
      searchresults: "Arama Sonuçları",
      searchnotfound: "Aramanıza Uygun Bir Sonuç Bulunamadı. Lütfen Tekrar Deneyin.",



      //  navbar
      home: "ANASAYFA",
      aboutus: "HAKKIMIZDA",
      products: "ÜRÜNLER",
      quality: "KALİTE",
      news: "HABERLER",
      contact: "İLETİŞİM",
      navcatologue: "KATALOGLAR",
      navcertificate: "SERTİFİKALAR",
      uruntablosu: "Ürün Tablosu",
      searchplaceholder: "Arama Yapın...",
      languages: "Diller",

      pdf: "Ürün Kataloğu için Tıklayınız...",

      // footer
      footerdesc:
        "ASC FIXING, tesisat ve iklimlendirme sektöründe kalitesi ve müşteri memnuniyeti ile tanınan ve aranan bir markadır. Boru kelepçesi, profil ve çeşitli bağlantı parçaları ürünleri en iyi çözümleri sunar.",
      links: "Hızlı Linkler",
      informations: "Bilgi",
      catologue: "Kataloglar",
      certificate: "Sertifikalar",
      footerproducts: "Ürünler",
      footerpipeclamps: "Kelepçeler",
      footerprofileconsole: "Profil ve Konsol",
      footerotherfittings: "Diğer Bağlantı Elemanları",
      footercontact: "İletişim",
      footerfair: "Fuarlar",
      footerannouncements: "Duyurular",

      // products
      products: "ÜRÜNLER",
      pipeclamps: "KELEPÇELER",
      profileconsole: "PROFİL VE KONSOLLAR",
      otherfittings: "DİĞER BAĞLANTI ELEMANLARI",
      steel: "Çelik Ray",
      application: "Uygulamalar",
      features: "Özellikler",

      // contact placeholders
      brandname: "Şirket Adı",
      authorizedperson: "Yetkili Kişi",
      phonenumber: "Telefon Numarası",
      message: "Mesaj",
      adress: "Adres",

      //Pipe-Clamp
      pr1: "Somunlu Kelepçe (Set)",
      pr2: "Lastiksiz Somunlu Kelepçe (Set)",
      pr3: "Somunlu Kelepçe",
      pr4: "Somunlu Lastiksiz Kelepçe ",
      pr5: "Kombi Somunlu Kelepçe",
      pr6: "Lastiksiz Kombi Somunlu Kelepçe",
      pr7: "Kısa Trifonlu Kelepçe 7x70",
      pr8: "Lastiksiz Kısa Trifonlu 7x70",
      pr9: "Uzun Trifonlu Kelepçe 7x100",
      pr10: "Lastiksiz Uzun Trifonlu Kelepçe 7x100",
      pr11: "Ağır Yük Kafalı Kelepçe",
      pr12: "Ağır Yük Somunlu Kelepçe",
      pr13: "Ağır Yük Platform Kelepçe",
      pr14: "Clevis Kelepçe",
      pr15: "Sprinkler Kelepçe",
      pr16: "Manşonlu Sprinkler Kelepçe",
      pr17: "Sessiz Boru Kelepçesi Somunlu (Dikey)",
      pr18: "Sessiz Boru Kelepçesi Somunlu (Yatay)",
      pr19: "Sessiz Boru Kelepçesi Stand",
      pr20: "Sessiz Boru Kelepçesi Trifonlu (Dikey)",
      pr21: "Sessiz Boru Kelepçesi Trifonlu (Yatay)",
      pr22: "İtalyan Tipi Kelepçe (Set)",
      pr23: "İtalyan Tipi Somunlu Kelepçe",
      pr24: "İtalyan Tipi Kombi Somunlu Kelepçe",
      pr25: "Paslanmaz Somunlu Kelepçe",
      pr26: "Paslanmaz Kombi Somunlu Kelepçe",
      pr27: "İzolasyon Takozu",
      pr28: "U Bolt",
      pr29: "Tekli Kelepçe",
      pr30: "Çiftli Kelepçe",

      //pipe-clamp-nutset
      nsa1: "Çelik, Paslanmaz Çelik, PPR, PVC, Pik, Demir, PE, Bakır borular için tespit elemanları.",
      nsa2: "Boruları duvara, tavana ve zemine yatay veya dikey olarak monte ederek sabitlemek için kullanılır.",

      nsf1: "Somunlu kelepçe seti, adaptör trifon ve plastik dübel ile birlikte paketlenmiştir",
      nsf2: "Malzeme: çelik",
      nsf3: "Güçlendirilmiş yapı daha yüksek dayanıklılık sağlar",
      nsf4: "Kaplama: Çinko kaplama",
      nsf5: "Kaynak noktaları: Konik somunlar punta kaynak otomasyon makinelerinde kaynatılır.",
      nsf6: "İki yan vida sayesinde kolay ve güvenli montaj",
      nsf7: "Yan vidalar, plastik rondelalar yardımıyla montaj sırasında kaybolmaya karşı korunur",
      nsf8: "(Elektro-galvanizli)",
      nsf9: "Sabitleme için plastik tapa kullanılmalıdır.",
      nsf10: "DN 4109 gereksinimlerini karşılar.",
      nsf11: "Yan vidalara ek iki somun uygulandı.",
      nsf12:
        "Kelepçe çapına göre M12 ve M16 Somun olmak üzere iki çeşit üretilmektedir",
      nfs13: "PVC sessiz borular için sabitleme.",
      nfs14:
        "Boruları duvara dikey olarak monte ederek sabitlemek için kullanılır.",
      nfs15: "Atık su sistemlerinde kullanılır.",
      nfs16:
        "Sessiz PVC boru özelliği ile üretilen atık su boruları ile birlikte kullanılarak 8 - 11 dbar ses seviyesine ek olarak sessizlik sağlar.",
      nfs17: "Dikey montajlı sessiz borulu atık su tesisatlarında",
      nfs18:
        "Sıcak veya soğuk su tesisatlarında sıcaklık kaybını önlemek için kullanılır.",
      nfs19: "Duvar plakaları aracılığıyla montaj",
      nfs20: "Boru ve duvar arasında ayarlanabilir mesafe sağlayın",
      nfs21: "AISI304 paslanmak çelikten üretilmiştir",
      nfs22: "Çekirdeklenmeye karşı yüksek dayanıklılık",
      nfs23: "Provide adjustable distance between pipe and wall",

      //Pipe Clamp With Nut
      n1: "Kombine somun M8 ve M10 diş için uygundur",
      n2: "Kauçuk: EPDM",

      //Other Fittings TR
      of1: "Profil Kapağı",
      of2: "Sonsuz Şerit Kelepçe",
      of3: "Profil Rondelası",
      of4: "Kayar Mesnet",
      of5: "Havalandırma Kanalı Köşe Bağlantısı",
      of6: "Askı Mengene",
      of7: "G Klips",
      of8: "V Trapez",
      of9: "Quickfix",
      of10: "Kayar Somun TB2",
      of11: "Kayar Somun TB3",
      of12: "Kayar Somun TB4",
      of13: "Kayar Somun TB5",
      of14: "Kayar Somun",
      of15: "Köşeli Kayar Somun",
      of16: "Yaylı Kayar Somun",
      of17: "Somunlu Zemin Plaka",
      of18: "Ağır Yük Somunlu Zemin Plaka",
      of19: "Cıvatalı Zemin Plaka",
      of20: "Ağır Yük Cıvatalı Zemin Plaka",
      of21: "Manşonlu Zemin Plaka",
      of22: "Rot",
      of23: "Saplama",
      of24: "Somun Din 934",
      of25: "Uzatma Somun Din 6334",
      of26: "Dar Pul Din 125",
      of27: "Geniş Pul Din 9021",
      of28: "Whitwort Pul",
      of29: "Yaylı Pul Din 127",
      of30: "Kare Pul",
      of31: "U Profiller İçin Kare Pul",
      of32: "Cıvata Din 933",
      of33: "Adaptör Trifon",
      of34: "Çakmalı Dübel",
      of35: "Çekmeli Dübel",
      of36: "Klipsli Dübel",
      of37: "Cıvatalı Borulu Dübel",
      of38: "Gömlekli Dübel",
      of39: "Asma Tavan Dübeli",
      of40: "Kancalı Dübel",
      of41: "Standart Beyaz Dübel",
      of42: "Standart Gri Dübel",
      of43: "Tuğla Boşluk Dübel",
      of44: "Roket Duvar Dübel",
      of45: "Tuğla Dübeli",
      of46: "Lavabo Montaj Set",
      of47: "Klozet Montaj Set",
      of48: "Yaylı Lavabo Montaj Set",
      of49: "Kombi Şofben Montaj Seti",
      of50: "Kollektör Dolabı Konsollu",
      of51: "Kollektör Dolabı",
      of52: "Kare Kafa Kollektör Kelepçe",
      of53: "Yuvarlak Kafa Kollektör Kelepçe",
      of54: "Plastik Kablo Bağcığı",

      //steel-rail
      steelfeature:
        "Sıhhi tesisat armatürünün merkez mesafesi için tasarlanmış sürekli delik düzeni",
      steelusage: "Boru veya sıhhi ekipmana sabitlemek için üniversal sistem.",

      //products-profileandconsole-cprofile
      cprofil: "C Profil",
      material: "Malzeme : Ön Galvanizli Çelik",
      coating: "Kaplama : Mevcut çinko. talep üzerine kaplama",
      cfeature:
        "Hızlı ve kolay sabitleme için, sürekli delme, iki tarafta sürekli dereceli santimetre ölçeği",

      //products-profileandconsole-gprofile
      gprofil: "G Profil",
      gfeature:
        "Sürekli delme ile ray ucu ile ilk delik arasındaki mesafe her zaman eşittir",

      //products-profileandconsole-lprofile
      lprofil: "L Profil",
      lfeature: "Hızlı ve kolay sabitleme için sürekli delme",

      //products-profileandconsole-uprofile
      uprofil: "U Profil",

      //products-profileandconsole-crailconsole
      crailconsole: "C Tipi Konsol",

      //products-profileandconsole-crailconsole
      grailconsole: "G Tipi Konsol",

      //products-profileandconsole-lrailconsole
      lrailconsole: "L Tipi Konsol",

      //products-profileandconsole-urailconsole
      urailconsole: "U Tipi Konsol",

      //global
      whychoouse: "NEDEN BİZ?",
      experience:
        "Türkiye'nin 81 ilindeki bayileri ile gücüne güç katan firmamız, 4 kıta, 60'tan fazla ülkeye yaptığı ihracat ile bir Dünya markası olma yolunda emin adımlarla ilerlemektedir.",
      manufact:
        "Aradığınız her şey Türkiye'nin kendi sektöründe en yüksek üretim kapasitesine sahip fabrikamızın kökünün altında.",
      speed: "Kaliteden ödün vermeden zaman çizelgelerini takip etmek.",
      innovation:
        "Güçlü Ar-Ge Ekibimiz ile İklimlendirme sektöründeki yenilikleri takip etmek.",
      service:
        "Ar-Ge süreçleri, üretim hattı, paketleme ve nakliye hizmetleri sürekli kontrol altındadır.",
      facility: "5.000 M2 ÜRETİM TESİSİ",
      monthly: "AYLIK 5.000.000 ÜRETİM",
      export: "60 ÜLKEYE İHRACAT",
      continents: "4 KITA",

      // fixedimage
      qualityis: "KALİTE",
      ourbusiness: "İŞİMİZİN KALBİNDEDİR",

      // aboutus
      aboutdesc:
        "ASC FIXING, tesisat ve iklimlendirme sektöründe kalitesi ve müşteri memnuniyeti ile tanınan ve aranan bir markadır. Boru kelepçesi, profil ve çeşitli bağlantı parçaları ürünleri en iyi çözümleri sunar.",

      //  News
      announcements: "Duyurular",
      fair: "Fuar",

      //  Contact
      address: "İletişim",
      contactus: "Bizimle İletişime Geçin",
      sendform: "Gönder",
    },
  },
  en: {
    translation: {
      //Search Page 
      searchresults: "Search Results",
      searchnotfound: "No results were found for your search. Please try again.",

      //  navbar
      home: "HOME",
      aboutus: "ABOUT US",
      products: "PRODUCTS",
      quality: "QUALITY",
      news: "NEWS",
      contact: "CONTACT",
      navcatologue: "CATALOGUES",
      navcertificate: "CERTIFICATES",
      searchplaceholder: "Search...",
      uruntablosu: "Product Table",

      pdf: "Click for Product Catalogue...",
      // footer
      footerdesc:
        "ASC FIXING is a well known and a wanted brand with its quality and Customer satisfaction in plumbing and HVAC industry. The products pipe clamp, profile and various fittings provide the best solutions.",
      links: "Links",
      footerproducts: "Products",
      footerpipeclamps: "Pipe Clamps",
      footerprofileconsole: "Profile&Console",
      footerotherfittings: "Other Fittings",
      footercontact: "Contact",
      informations: "Informations",
      catologue: "Catalogue",
      certificate: "Certificate",
      footerfair: "Fair",
      footerannouncements: "Announcements",

      // products
      products: "PRODUCTS",
      pipeclamps: "PIPE CLAMPS",
      profileconsole: "PROFILE&CONSOLE",
      otherfittings: "OTHER FITTINGS",
      steel: "Steel Rail",
      application: "Applications",
      features: "Features",

      // contact placeholders
      brandname: "Brand Name",
      authorizedperson: "Authorized Person",
      phonenumber: "Phone Number",
      message: "Message",
      adress: "Adress",

      //Pipe-Clamp
      pr1: "Pipe Clamp With Nut (Set)",
      pr2: "No Rubber Pipe Clamp With Nut (Set)",
      pr3: "Pipe Clamp With Nut",
      pr4: "No Rubber Pipe Clamp With Nut ",
      pr5: "Pipe Clamp With Combi Nut",
      pr6: "No Rubber Pipe Clamp With Combi Nut",
      pr7: "Pipe Clamp With Triphone 7x70",
      pr8: "No Rubber Pipe Clamp With Triphone 7x70",
      pr9: "Pipe Clamp With Triphone 7x100",
      pr10: "No Rubber Pipe Clamp With Triphone 7x100",
      pr11: "Heavy Duty Pipe Clamp (Headed)",
      pr12: "Heavy Duty Pipe Clamp With Nut",
      pr13: "Platform Heavy Duty Clamp",
      pr14: "Clevis Hanger",
      pr15: "Sprinkler Pipe Clamp",
      pr16: "Sleeve Sprinkler Pipe Clamp",
      pr17: "Silent Pipe Clamp With Nut (Vertical)",
      pr18: "Silent Pipe Clamp With Nut (Horizontal)",
      pr19: "Silent Pipe Clamp With Stand",
      pr20: "Silent Pipe Clamp With Triphone (Vertical)",
      pr21: "Silent Pipe Clamp With Triphone (Horizontal)",
      pr22: "Italian Type Pipe Clamp (Set)",
      pr23: "Italian Type Pipe Clamp With Nut",
      pr24: "Italian Type Pipe Clamp With Combi Nut",
      pr25: "Stainless Steel Clamp With Nut",
      pr26: "Stainless Steel Clamp With Combi Nut",
      pr27: "Isolation Block",
      pr28: "U Bolt",
      pr29: "Single Clamp",
      pr30: "Double Clamp",

      //pipe-clamp-nutset
      nsa1: "Fixings for Steel, Stainless Steel, PPR, PVC, Cast, Iron, PE, Copper pipes.",
      nsa2: "Used to fasten the pipes by mounting them on the walls, ceilings, and floors horizontally or vertically.",

      nsf1: "Clamp with nut set is packed with adaptor triphone and plastic dubel",
      nsf2: "Material: steel",
      nsf3: "Reinforced structure provide higher durability",
      nsf4: "Coating: Zinc plating",
      nsf5: "Welding points: The conic nuts are welded by spot-welding automation machines",
      nsf6: "Easy and safe assembly through two side screws",
      nsf7: "Side screws are protected against loss during assembly with the help of plastic washers",
      nsf8: "(Electro-galvanized)",
      nsf9: "Plastic plug should be used for fixing",
      nsf10: "Meets the requirements of DN 4109",
      nsf11: "Additional two nut applied to the side screws.",
      nsf12:
        "Two kinds are produced as M12 and M16 Nut based on the diameter of the clamp",
      nfs13: "Fixing for PVC silent pipes.",
      nfs14:
        "Used to fasten the pipes by mounting them on the walls vertically.",
      nfs15: "Used in waste water systems.",
      nfs16:
        "It provides silence in addition to the sound level of 8 - 11 dbars by using with waste water pipes which produced with the silent PVC pipe feature.",
      nfs17: "In vertical assembled silent pipe waste water installations",
      nfs18:
        "Used to prevent temperature loss in hot or cold water installations.",
      nfs19: "Assembly through wall plates",
      nfs20: "Provide adjustable distance between pipe and wall",
      nfs21: "Made of STAINLESS STEEL AISI304",
      nfs22: "High durability against to coression",
      nfs23: "Provide adjustable distance between pipe and wall",

      //Pipe Clamp With Nut (Set)
      n1: "The combi nut is suitable for M8 and M10 thread",
      n2: "Rubber:EPDM",

      //Other Fittings EN
      of1: "Profile End Cap",
      of2: "Perforated Mounting Strip (Galvanized)",
      of3: "U-Shaped Washer",
      of4: "Sliding Support",
      of5: "Corner For Ventilation",
      of6: "Beam Clamp",
      of7: "G Clips",
      of8: "V Trapez Hanger",
      of9: "Quickfix",
      of10: "Slide Nut TB2",
      of11: "Slide Nut TB3",
      of12: "Slide Nut TB4",
      of13: "Slide Nut TB5",
      of14: "Slide Nut",
      of15: "Square Slide Nut",
      of16: "Spring Slide Nut",
      of17: "Wall Plate With Nut",
      of18: "Wall Plate With Nut Welded",
      of19: "Wall Plate With Bolt",
      of20: "Wall Plate With Bold Welded",
      of21: "Wall Plate With Muff",
      of22: "Threaded Rod",
      of23: "Stud Bolt",
      of24: "Nut Din 934",
      of25: "Extension Nut Din 6334",
      of26: "Washer Narrow Din 125",
      of27: "Washer Wide Din 9021",
      of28: "İnch Whitworth Washer",
      of29: "Spring Washer Din 127",
      of30: "Square Washer",
      of31: "Square Washer For U Profile",
      of32: "Bolt Din 933",
      of33: "Adaptor Triphone",
      of34: "Drop-In Anchor",
      of35: "Draw-In Anchor (S Type)",
      of36: "Wedge Anchor",
      of37: "Pipe Type Anchor",
      of38: "Sleeve Anchor",
      of39: "Ceiling Anchor",
      of40: "Hook Anchor",
      of41: "Standard Plastic Anchor White",
      of42: "Standard Plastic Anchor Gray",
      of43: "Brick Cavity Anchor",
      of44: "Rocket Plastic Anchor",
      of45: "Brick Anchor",
      of46: "Lavatory Assembly Set",
      of47: "Closet Assembly Set",
      of48: "Spring Lavatory Assembly Set",
      of49: "Water Heater Assembly Set",
      of50: "Collector Box (With Console)",
      of51: "Collector Box",
      of52: "Collector Clamp Square",
      of53: "Collector Clamp Round",
      of54: "Adjustable Plastic Clamp",

      //Profile-Console
      //steel-rail
      steelfeature:
        "Continuous hole pattern, designed for centre distance of sanitary armature ",
      steelusage: "Universal system for fixing to pipe or sanitary equipment.",

      //products-profileandconsole-cprofile
      cprofil: "C Profile",
      material: "Material : Pre-Galvanized Steel",
      coating: "Coating : Available zinc. plating on demand",
      cfeature:
        "For a quick and easy fixing, continuous perforation, a continuous graduated centimetre scale on two sides",

      //products-profileandconsole-gprofile
      gprofil: "G Profile",
      gfeature:
        "With continuous perforation, distance between rail end and first hole is always equal",

      //products-profileandconsole-lprofile
      lprofil: "L Profile",
      lfeature: "For a quick and easy fixing, continuous perforation",

      //products-profileandconsole-uprofile
      uprofil: "U Profile",

      //products-profileandconsole-crailconsole
      crailconsole: "C Rail Console",

      //products-profileandconsole-crailconsole
      grailconsole: "G Rail Console",

      //products-profileandconsole-lrailconsole
      lrailconsole: "L Rail Console",

      //products-profileandconsole-urailconsole
      urailconsole: "U Rail Console",

      //global
      whychoouse: "WHY CHOOSE US?",
      experience:
        "Our company, which has been strengthened with its dealers in 81 provinces of Turkey, is taking confident steps towards becoming a World wide brand owing to its exports to 4 continents, over 60 countries.",
      manufact:
        "Everything you look for is under our factory's root which has the highest production capacity in its sector in Turkey.",
      speed: "Following time schedules without compromising on quality.",
      innovation:
        "Following the innovations in the HVAC industry with our strong R&D Team.",
      service:
        "R&D processes, production line, packaging and transportation services are under control continuously.",
      facility: "5.000 M2 PRODUCTION FACILTY",
      monthly: "5.000.000 MONTHLY PRODUCTION",
      export: "60 EXPORT TO COUNTRIES",
      continents: "4 CONTINENTS",

      // fixedimage
      qualityis: "QUALITY IS",
      ourbusiness: "HEART OF OUR BUSINESS",

      // aboutus
      aboutdesc:
        "ASC FIXING is a well known and a wanted brand with its quality and Customer satisfaction in plumbing and HVAC industry. The products pipe clamp, profile and various fittings provide the best solutions.",

      //  QUALİTY

      //  News
      announcements: "Announcements",
      fair: "Fair",

      //  Contact
      address: "Address",
      contactus: "Contact Us",
      sendform: "Send Form",
    },
  },
  fr: {
    translation: {
      //Search Page 
      searchresults: "Résultats de recherche",
      searchnotfound: "Aucun résultat n'a été trouvé pour votre recherche. Veuillez réessayer.",



      home: "PAGE D'ACCUEIL",
      aboutus: "À PROPOS DE NOUS",
      products: "PRODUITS",
      quality: "QUALITÉ",
      news: "ACTUALITÉS",
      contact: "CONTACT",
      navcatologue: "CATALOGUES",
      navcertificate: "CERTIFICATS",
      searchplaceholder: "Recherche...",
      languages: "langues",

      pdf: "Cliquez pour le catalogue de produits...",
      uruntablosu: "Tableau des produits",
      // footer
      footerdesc:
        "ASC FIXING est une marque bien connue et recherchée pour sa qualité et la satisfaction de ses clients dans l'industrie de la plomberie et du CVC. Les produits collier de serrage, profilé et divers raccords offrent les meilleures solutions",
      links: "Liens",
      footerproducts: "Produits",
      footerpipeclamps: "Pipe Clamps",
      footerprofileconsole: "Profile&Console",
      footerotherfittings: "Autres raccords",
      footercontact: "Contact",
      informations: "Informations",
      catologue: "Catalogue",
      certificate: "Certificat",
      footerfair: "Équitable",
      footerannouncements: "Annonces",

      // products
      products: "PRODUITS",
      pipeclamps: "PIPE CLAMPS",
      profileconsole: "PROFILE&CONSOLE",
      otherfittings: "AUTRES RACCORDS",
      steel: "Rail D'acier",
      application: "Candidatures",
      features: "Caractéristiques",

      // contact placeholders
      brandname: "Nom de la marque",
      authorizedperson: "Personne autorisée",
      phonenumber: "Numéro de téléphone",
      message: "Message",
      adress: "Adresse",

      //Pipe-Clamp
      pr1: "Écrou de serrage (jeu)",
      pr2: "Pince à écrou sans caoutchouc (jeu)",
      pr3: "Écrou de serrage",
      pr4: "Pince sans caoutchouc avec écrou",
      pr5: "Pince combinée avec écrou",
      pr6: "Pince à écrou combinée sans pneu",
      pr7: "Pince triphone courte 7x70",
      pr8: "7x70 avec Triphone Court sans Caoutchouc",
      pr9: "Pince triphone longue 7x100",
      pr10: "Pince Triphone Longue 7x100 Sans Caoutchouc",
      pr11: "Pince à tête lourde",
      pr12: "Pince à écrou pour charges lourdes",
      pr13: "Pince plate-forme à usage intensif",
      pr14: "Pince à chape",
      pr15: "Pince d'arrosage",
      pr16: "Pince pour arroseur avec manchon",
      pr17: "Collier de tuyau silencieux avec écrou (vertical)",
      pr18: "Collier de tuyau silencieux avec écrou (horizontal)",
      pr19: "Support silencieux pour collier de serrage",
      pr20: "Triphone de collier de serrage silencieux (vertical)",
      pr21: "Triphone de collier de serrage silencieux (horizontal)",
      pr22: "Collier de type italien (jeu)",
      pr23: "Pince à écrou de type italien",
      pr24: "Collier à écrou combiné de type italien",
      pr25: "Pince à écrou en acier inoxydable",
      pr26: "Pince à écrou combiné en acier inoxydable",
      pr27: "Coin d'isolement",
      pr28: "Bolon en U",
      pr29: "Pince simple",
      pr30: "Double simple",

      //pipe-clamp-nutset
      nsa1: "Eléments de raccord pour tubes Acier, Inox, PPR, PVC, Porc, Fer, PE, Cuivre.",
      nsa2: "Utilisé pour fixer les tuyaux en les montant horizontalement ou verticalement au mur, au plafond et au sol.",

      nsf1: "Livré avec un jeu de pinces à écrou, un adaptateur triphone et une cheville en plastique",
      nsf2: "Matériel : acier",
      nsf3: "La structure renforcée offre une plus grande durabilité",
      nsf4: "Placage : zingué",
      nsf5: "Points de soudure : les écrous coniques sont soudés dans des machines automatisées de soudage par points.",
      nsf6: "Montage facile et sûr grâce à deux vis latérales",
      nsf7: "Les vis latérales sont protégées contre la perte lors du montage à l'aide de rondelles en plastique",
      nsf8: "(Électro-galvanisé)",
      nsf9: "Un bouchon en plastique doit être utilisé pour la fixation.",
      nsf10: "Répond aux exigences DN 4109.",
      nsf11: "Deux écrous supplémentaires appliqués aux vis latérales.",
      nsf12:
        "Deux types sont produits en tant qu'écrous M12 et M16 en fonction du diamètre de serrage",
      nfs13: "Fixation pour tuyaux silencieux en PVC.",
      nfs14:
        "Utilisé pour fixer les tuyaux en les montant verticalement sur le mur.",
      nfs15: "Utilisé dans les systèmes d'eaux usées.",
      nfs16:
        "Il offre un silence en plus d'un niveau sonore de 8 à 11 dbar lorsqu'il est utilisé avec des conduites d'eaux usées produites avec une fonction de conduite silencieuse en PVC.",
      nfs17:
        "Dans les installations d'évacuation des eaux usées verticales silencieuses ",
      nfs18:
        "Utilisé pour éviter les pertes de température dans les installations d'eau chaude ou froide.",
      nfs19: "Montage via plaques murales",
      nfs20: "Fournir une distance réglable entre le tuyau et le mur",
      nfs21: "Fabriqué en acier inoxydable AISI304",
      nfs22: "Haute résistance du noyau",
      nfs23: "Fournir une distance réglable entre le tuyau et le mur",

      // Collier de serrage avec écrou
      n1: "L'écrou combiné convient aux filetages M8 et M10",
      n2: "Caoutchouc : EPDM",

      //Autres raccords FR
      of1: "Couverture de profil",
      of2: "Pince à ruban infinie",
      of3: "rondelle de profil",
      of4: "Support coulissant",
      of5: "Connexion d'angle du conduit de ventilation",
      of6: "étau de suspension",
      of7: "G Clip",
      of8: "V Trapèze",
      of9: "Correction rapide",
      of10: "Écrou coulissant TB2",
      of11: "Écrou coulissant TB3",
      of12: "Écrou coulissant TB4",
      of13: "Écrou coulissant TB5",
      of14: "Écrou coulissant",
      of15: "Écrou coulissant carré",
      of16: "Écrou coulissant à ressort",
      of17: "Plaque de base avec écrous",
      of18: "Plaque de sol pour écrou à charge lourde",
      of19: "Plaque de sol boulonnée",
      of20: "Plaque de sol boulonnée pour charges lourdes",
      of21: "Plaque de sol à revers",
      of22: "Pourriture",
      of23: "goujon",
      of24: "Ecrou Din 934",
      of25: "Ecrou d'extension Din 6334",
      of26: "Tampon étroit Din 125",
      of27: "Cachet Large Din 9021",
      of28: "Timbre Whitworth",
      of29: "Timbre de printemps Din 127",
      of30: "Timbre carré",
      of31: "Rondelle carrée pour profilés en U",
      of32: "Bolt Din 933",
      of33: "Adaptateur Triphone",
      of34: "Cheville percée",
      of35: "Tirer l'ancre",
      of36: "Ancre à clipser",
      of37: "Ancrage tubulaire boulonné",
      of38: "Cheville en chemise",
      of39: "Ancre de plafond suspendu",
      of40: "Ancre avec crochet",
      of41: "Cheville blanche standard ",
      of42: "Ancre grise standard",
      of43: "Brick Space Cheville",
      of44: "Ancrage mural Rocket",
      of45: "Brick Cheville",
      of46: "Kit d'installation d'évier",
      of47: "Ensemble de fixation pour toilettes",
      of48: "Ensemble d'installation pour bassin de printemps",
      of49: "Ensemble d'installation de chauffe-eau combiné",
      of50: "Armoire de collection avec console",
      of51: "Cabinet de collection",
      of52: "Pince de collecteur à tête carrée",
      of53: "Collecteur à tête ronde",
      of54: "attache de câble en plastique",

      steelfeature:
        "Modèle de trous continus conçu pour l'entraxe du raccord sanitaire",
      steelusage: "Système universel de fixation sur tuyauterie ou sanitaire.",

      //products-profileandconsole-cprofile
      cprofil: "Profil C",
      material: "Matériau : acier pré-galvanisé",
      coating: "Revêtement : Zinc disponible. revêtement sur demande",
      cfeature:
        "Pour une fixation simple et rapide, perçage en continu, échelle centimétrique graduée en continu des deux côtés",

      //products-profileandconsole-gprofile
      gprofil: "Profil G",
      gfeature:
        "Avec un perçage continu, la distance entre l'extrémité du rail et le premier trou est toujours égale",

      //products-profileandconsole-lprofile
      lprofil: "Profil L",
      lfeature: "Perçage continu pour une fixation simple et rapide",

      //produits-profiletconsole-uprofil
      uprofil: "Profil U",

      //produits-profiletconsole-crailconsole
      crailconsole: "Console de type C",

      //produits-profiletconsole-crailconsole
      grailconsole: "Console de type G",

      //products-profileandconsole-lrailconsole
      lrailconsole: "Console de type L",

      //products-profileandconsole-urailconsole
      urailconsole: "Console de type U",

      //global
      whychoouse: "POURQUOI NOUS ?",
      experience:
        "Notre société, qui renforce sa puissance avec ses concessionnaires dans 81 provinces de Turquie, prend des mesures fermes pour devenir une marque mondiale avec ses exportations dans plus de 60 pays sur 4 continents.",
      manufact:
        "Tout ce que vous cherchez est sous la racine de notre usine, qui a la capacité de production la plus élevée de son secteur en Turquie.",
      speed: "Suivre les délais sans sacrifier la qualité.",
      innovation:
        "Suivre les innovations dans l'industrie du CVC avec notre solide équipe de R&D.",
      service:
        "Les processus de R&D, la chaîne de production, les services d'emballage et de transport sont sous contrôle constant.",
      facility: "5.000 M2 PRODUCTION FACILITY",
      monthly: "5.000.000 PRODUCTION MENSUELLE",
      export: "EXPORTER VERS 60 PAYS",
      continents: "4 CONTINENTS",

      // fixedimage
      qualityis: "QUALITÉ",
      ourbusiness: "AU CŒUR DE NOTRE MÉTIER",

      // aboutus
      aboutdesc:
        "ASC FIXING est une marque recherchée dans l'industrie de la plomberie et de la climatisation, reconnue pour sa qualité et la satisfaction de ses clients. Colliers de serrage, profilés et divers produits de raccords offrent les meilleures solutions.",

      //  News
      announcements: "Annonces",
      fair: "Juste",

      //  Contact
      address: "Contact",
      contactus: "Contactez-nous",
      sendform: "Envoyer",
    },
  },

  ar: {
    translation: {
      //Search Page 
      searchresults: "نتائج البحث",
      searchnotfound: "لم يتم العثور على نتائج بحثك. حاول مرة اخرى.",


      home: "الصفحة الرئيسية",
      aboutus: "معلومات عنا",
      products: "منتجات",
      quality: "جودة",
      news: "أخبار",
      contact: "تواصل",
      navcatologue: "الكتالوجات",
      navcertificate: "الشهادات",
      searchplaceholder: "يبحث",
      languages: "اللغات",

      pdf: "انقر للحصول على كتالوج المنتجات ...",

      uruntablosu: "جدول المنتج",

      footerdesc:
        "ASC FIXING هي علامة تجارية مرغوبة في صناعة السباكة وتكييف الهواء ، معروفة بجودتها ورضا العملاء. مشابك الأنابيب ، التشكيلات الجانبية ومنتجات التركيبات المتنوعة تقدم أفضل الحلول.",
      links: "روابط سريعة",
      informations: "معلومة",
      catologue: "الكتالوجات",
      certificate: "الشهادات",
      footerproducts: "المنتجات",
      footerpipeclamps: "المشابك",
      footerprofileconsole: "الملف الشخصي ووحدة التحكم",
      footerotherfittings: "تركيبات التذييل:",
      footercontact: "جهة اتصال",
      footerfair: "المعارض",
      footerannouncements: "الإعلانات",

      products: "المنتجات",
      pipeclamps: "مصابيح الأنابيب",
      profileconsole: "ملف التعريف ووحدات التحكم",
      otherfittings: "مثبتات أخرى",
      steel: "سكة حديدية",
      application: "التطبيقات",
      features: "سمات",

      // contact placeholders
      brandname: "اسم الشركة",
      authorizedperson: "الشخص المرخص",
      phonenumber: "رقم الهاتف",
      message: "رسالة",
      adress: "عنوان",

      //Pipe-Clamp
      pr1: "الجوز المشبك (مجموعة)",
      pr2: "مشبك الجوز غير المطاطي (مجموعة)",
      pr3: "الجوز المشبك",
      pr4: "المشبك غير المطاطي مع الجوز",
      pr5: "كومبي المشبك مع الجوز",
      pr6: "المشبك كومبي البندق بلا كلاب",
      pr7: "قصير Triphone Clamp 7x70",
      pr8: "7x70 مع Triphone قصير بدون مطاط",
      pr9: "Long Triphone Clamp 7x100",
      pr10: "شبك هاتف طويل بلا كلل 7x100",
      pr11: "تحميل ثقيل رأس المشبك",
      pr12: "تحميل الجوز الثقيل المشبك",
      pr13: "تحميل ثقيل منصة المشبك",
      pr14: "أصفاد كليفيس",
      pr15: "رشاش المشبك",
      pr16: "كم رشاش المشبك",
      pr17: "مشبك الأنابيب الصامت مع الجوز (عمودي)",
      pr18: "مشبك الأنابيب الصامت مع الجوز (أفقي)",
      pr19: "حامل مشبك الأنابيب الصامت",
      pr20: "صمام ثلاثي صامت للأنابيب (رأسي)",
      pr21: "تريفون مشبك الأنابيب الصامت (أفقي)",
      pr22: "نوع المشبك الإيطالي (مجموعة)",
      pr23: "إيطالي نوع الجوز المشبك",
      pr24: "نوع إيطالي كومبي الجوز المشبك",
      pr25: "غير القابل للصدأ الجوز المشبك",
      pr26: "غير القابل للصدأ كومبي الجوز المشبك",
      pr27: "إسفين العزل",
      pr28: "يو بولت",
      pr29: "مشبك واحد",
      pr30: "مزدوج المشبك",

      nsa1: "عناصر تركيب الفولاذ ، الفولاذ المقاوم للصدأ ، PPR ، PVC ، الخنازير ، الحديد ، PE ، أنابيب النحاس.",
      nsa2: "تستخدم لتثبيت الأنابيب عن طريق تركيبها أفقيًا أو رأسيًا على الحائط والسقف والأرضية.",

      nsf1: "معبأ بمجموعة مشابك صامولة ومحول ثلاثي للهواتف ودواسة بلاستيكية",
      nsf2: "مادة: فولاذ",
      nsf3: "الهيكل المقوى يوفر متانة أعلى",
      nsf4: "تصفيح: مطلي بالزنك",
      nsf5: "نقاط اللحام: الصواميل المخروطية ملحومة في آلات أتمتة اللحام النقطي.",
      nsf6: "تركيب سهل وآمن بفضل البراغي الجانبية",
      nsf7: "البراغي الجانبية محمية من الضياع أثناء التجميع بمساعدة غسالات بلاستيكية",
      nsf8: "(مجلفن كهربائيًا)",
      nsf9: "يجب استخدام المكونات البلاستيكية للتثبيت.",
      nsf10: "يفي بمتطلبات DN 4109.",
      nsf11: "صامولتان إضافيتان مثبتتان على براغي جانبية.",
      nsf12: "يتم إنتاج نوعين مثل صواميل M12 و M16 وفقًا لقطر المشبك",
      nfs13: "تثبيت لأنابيب PVC الصامتة.",
      nfs14: "تُستخدم لإصلاح الأنابيب بتثبيتها رأسياً على الحائط.",
      nfs15: "تستخدم في أنظمة الصرف الصحي.",
      nfs16:
        "يوفر صمتًا بالإضافة إلى مستوى صوت 8-11 ديسيبل عند استخدامه مع أنابيب مياه الصرف المنتجة بميزة الأنابيب البلاستيكية الصامتة.",
      nfs17: "في منشآت مياه الصرف الصحي للأنابيب الصامتة المثبتة عموديًا",
      nfs18:
        "يستخدم لمنع فقدان درجة الحرارة في منشآت المياه الساخنة أو الباردة.",
      nfs19: "التركيب عبر لوحات الحائط",
      nfs20: "توفير مسافة قابلة للتعديل بين الأنبوب والجدار",
      nfs21: "مصنوع من الفولاذ المقاوم للصدأ AISI304",
      nfs22: "مقاومة عالية للنواة",
      nfs23: "توفير مسافة قابلة للتعديل بين الأنبوب والجدار",

      n1: "الجوز المركب مناسب للخيط م 8 و م 10",
      n2: "المطاط: EPDM",

      //Other Fittings TR
      of1: "غلاف الملف الشخصي",
      of2: "مشبك الشريط اللانهائي",
      of3: "غسالة الملف الشخصي",
      of4: "انزلاق الدعم",
      of5: "اتصال ركن مجرى التهوية",
      of6: "شماعات ملزمة",
      of7: "ج  دبابيس",
      of8: "في ترابيز",
      of9: "إصلاح سريع",
      of10: "انزلاق الجوز تي ب 2",
      of11: "انزلاق الجوز تي ب 3",
      of12: "انزلاق الجوز تي ب 4",
      of13: "انزلاق الجوز تي ب 5",
      of14: "البندق المنزلق",
      of15: "صامولة انزلاقية مربعة",
      of16: "الجوز انزلاق الربيع",
      of17: "لوحة أرضية مع صمولة",
      of18: "صواميل حمولة ثقيلة",
      of19: "لوحة أرضية مسحوبة",
      of20: "صفيحة أرضية مثبتة بالحمل الثقيل",
      of21: "لوحة أرضية مكبلة",
      of22: "تعفن",
      of23: "عشيق",
      of24: "934- محمد علي",
      of25: "تمديد الجوز دين 6334",
      of26: "125 عريض الطوابع دين",
      of27: "عريض الطوابع دين 9021",
      of28: "ويتوورت بول",
      of29: "غسالة الربيع دين 127",
      of30: "اعمل لحظة",
      of31: "غسالة مربعة لمحات يو",
      of32: "933 محمد علي",
      of33: "محول تريفون",
      of34: "وتد حفر",
      of35: "سحب المرساة",
      of36: "كليب على وتد",
      of37: "وتد أنبوبي انسحب",
      of38: "الوتد ذو القميص",
      of39: "مرساة السقف المعلق",
      of40: "هوك مرساة",
      of41: "وتد أبيض قياسي",
      of42: "وتد رمادي قياسي",
      of43: "لبنة فاصل وتد",
      of44: "روكيت وول أنكور",
      of45: "بريك دويل",
      of46: "مجموعة تركيب المغسلة",
      of47: "مجموعة مقعد المرحاض",
      of48: "مجموعة تجميع حوض الربيع",
      of49: "مجموعة تركيب سخان المياه كومبي",
      of50: "خزانة التجميع مع وحدة التحكم",
      of51: "مجلس الوزراء جامع",
      of52: "مشبك جامع الرأس المربع",
      of53: "المشبك ذو الرأس المستديرة",
      of54: "الكابلات البلاستيكية",

      steelfeature: "نمط ثقب مستمر مصمم للمسافة المركزية للتركيبات الصحية",
      steelusage: "نظام عالمي للتثبيت على الأنابيب أو المعدات الصحية.",

      //products-profileandconsole-cprofile
      cprofil: "ملف تعريف C",
      material: "المواد: الصلب المجلفن مسبقًا",
      coating: "طلاء: زنك متاح. طلاء حسب الطلب",
      cfeature:
        "للتثبيت السريع والسهل ، والحفر المستمر ، ومقياس السنتيمتر المتدرج المستمر على كلا الجانبين",

      //products-profileandconsole-gprofile
      gprofile: "ملف تعريف G",
      gfeature:
        "مع الحفر المستمر ، تكون المسافة بين نهاية السكة والحفرة الأولى متساوية دائمًا",

      lprofile: "ملف تعريف L",
      lfeature: "حفر مستمر لإصلاح سريع وسهل",

      uprofil: "ملف تعريف U",

      crailconsole: "وحدة التحكم من النوع C",
      grailconsole: "وحدة تحكم من النوع G",
      lrailconsole: "وحدة تحكم من النوع L",

      //products-profileandconsole-urailconsole
      urailconsole: "وحدة تحكم من النوع U",

      whychoouse: "لماذا نحن؟",
      experience:
        "شركتنا ، التي تعزز قوتها مع تجارها في 81 محافظة في تركيا ، تتخذ خطوات حازمة نحو أن",
      manufact:
        "تصبح علامة تجارية عالمية بصادراتها إلى أكثر من 60 دولة في 4 قارات",
      speed:
        "كل ما تبحث عنه هو أصل مصنعنا ، الذي يتمتع بأعلى قدرة إنتاجية في قطاعه في تركيا.",
      innovation: "اتباع الجداول الزمنية دون التضحية بالجودة.",
      service:
        "عمليات البحث والتطوير ، وخط الإنتاج ، وخدمات التعبئة والتغليف والنقل تحت سيطرة مستمرة",
      facility: "منشأة إنتاج تبلغ مساحتها 5.000 متر مربع",
      monthly: "5.000.000 إنتاج شهري قدره  ",
      export: "تصدير إلى 60 دولة",
      continents: "4 قارات",

      qualityis: "الجودة",
      ourbusiness: "في قلب أعمالنا",

      // aboutus
      aboutdesc:
        "ASC FIXING هي علامة تجارية مرغوبة في صناعة السباكة وتكييف الهواء ، معروفة بجودتها ورضا العملاء. مشابك الأنابيب ، التشكيلات الجانبية ومنتجات التركيبات المتنوعة تقدم أفضل الحلول",

      //  News
      announcements: "الإعلانات",
      fair: "عدل",

      //  Contact
      address: "تواصل",
      contactus: "اتصل بنا",
      sendform: "يرسل",
    },
  },

  es: {
    translation: {
      //Search Page 
      searchresults: "Resultados de la búsqueda",
      searchnotfound: "No se encontraron resultados para tu búsqueda. Inténtalo de nuevo.",


      home: "HOGAR",
      aboutus: "ACERCA DE NOSOTROS",
      products: "PRODUCTOS",
      quality: "CALIDAD",
      news: "NOTICIAS",
      contact: "CONTACTO",
      navcatologue: "CATÁLOGOS",
      navcertificate: "CERTIFICADOS",
      uruntablosu: "Tabla de productos",
      searchplaceholder: "Buscar",
      pdf: "Haga clic para el catálogo de productos...",
      languages: "idiomas",
      // footer
      footerdesc:
        "ASC FIXING es una marca bien conocida y buscada por su calidad y satisfacción del cliente en la industria de plomería y HVAC. Los productos, abrazaderas para tuberías, perfiles y diversos accesorios brindan las mejores soluciones",
      links: "Enlaces",
      footerproducts: "Productos",
      footerpipeclamps: "Abrazaderas de tubería",
      footerprofileconsole: "Perfil&Consola",
      footerotherfittings: "Otros accesorios",
      footercontact: "Contacto",
      informations: "Informaciones",
      catologue: "Catálogo",
      certificate: "Certificado",
      footerfair: "Justo",
      footerannouncements: "Anuncios",

      // products
      products: "PRODUCTOS",
      pipeclamps: "ABRAZADERAS DE TUBERÍA",
      profileconsole: "PERFIL&CONSOLA",
      otherfittings: "OTROS ACCESORIOS",
      steel: "Carril de acero",
      application: "Aplicaciones",
      features: "Funciones",

      // contact placeholders
      brandname: "Nombre de la marca",
      authorizedperson: "Persona autorizada",
      phonenumber: "Número de teléfono",
      message: "Mensaje",
      adress: "Dirección",

      pr1: "Abrazadera de tubo con tuerca (juego)",
      pr2: "Sin abrazadera de tubo de goma con tuerca (juego)",
      pr3: "Abrazadera de tubo con tuerca",
      pr4: "Sin abrazadera de tubo de goma con tuerca",
      pr5: "Abrazadera de tubo con tuerca combinada",
      pr6: "Sin abrazadera de tubo de goma con tuerca combinada",
      pr7: "Abrazadera de tubo con trifono 7x70",
      pr8: "Sin abrazadera de tubo de goma con trifono 7x70",
      pr9: "Abrazadera de tubo con trifono 7x100",
      pr10: "Sin abrazadera de tubo de goma con trifono 7x100",
      pr11: "Abrazadera de tubo de servicio pesado (con cabeza)",
      pr12: "Abrazadera de tubo de alta resistencia con tuerca",
      pr13: "Abrazadera de servicio pesado para plataforma",
      pr14: "Colgador de horquilla",
      pr15: "Abrazadera de tubería de rociadores",
      pr16: "Abrazadera de tubo de rociador de manga",
      pr17: "Abrazadera Silenciosa Con Tuerca (Vertical)",
      pr18: "Abrazadera de tubo silenciosa con tuerca (horizontal)",
      pr19: "Abrazadera de tubo silenciosa con soporte",
      pr20: "Abrazadera Silenciosa Con Trifono (Vertical)",
      pr21: "Abrazadera Silenciosa Con Trifono (Horizontal)",
      pr22: "Abrazadera de tubo tipo italiano (juego)",
      pr23: "Abrazadera de tubo tipo italiano con tuerca",
      pr24: "Abrazadera de tubo tipo italiano con tuerca combinada",
      pr25: "Abrazadera de acero inoxidable con tuerca",
      pr26: "Abrazadera de acero inoxidable con tuerca combinada",
      pr27: "Bloque de aislamiento",
      pr28: "Perno en U",
      pr29: "Abrazadera simple",
      pr30: "doble abrazadera",

      nsa1: "Elementi di raccordo per tubi in acciaio, acciaio inossidabile, PPR, PVC, maiale, ferro, PE, rame.",
      nsa2: "Utilizzato per fissare i tubi montandoli orizzontalmente o verticalmente a parete, soffitto e pavimento.",

      nsf1: "Confezionato con set di morsetti per dadi, trifono adattatore e tassello in plastica",
      nsf2: "Materiale: acciaio",
      nsf3: "La struttura rinforzata offre una maggiore durabilità",
      nsf4: "Placcatura: zincata",
      nsf5: "Punti di saldatura: i dadi conici sono saldati in macchine automatiche di saldatura a punti.",
      nsf6: "Montaggio facile e sicuro grazie a due viti laterali",
      nsf7: "Le viti laterali sono protette dalla perdita durante il montaggio con l'aiuto di rondelle di plastica",
      nsf8: "(Elettrozincato)",
      nsf9: "Il tappo di plastica deve essere utilizzato per il fissaggio.",
      nsf10: "Soddisfa i requisiti DN 4109.",
      nsf11: "Due dadi aggiuntivi applicati alle viti laterali.",
      nsf12:
        "Due tipi sono prodotti come dadi M12 e M16 in base al diametro del morsetto",
      nfs13: "Fissaggio per tubi silenziosi in PVC.",
      nfs14: "Usato per fissare i tubi montandoli verticalmente sul muro.",
      nfs15: "Usato nei sistemi di acque reflue.",
      nfs16:
        "Fornisce silenziosità oltre a un livello sonoro di 8 - 11 dbar se utilizzato con tubi per acque reflue prodotti con la caratteristica del tubo in PVC silenzioso.",
      nfs17: "In impianti per acque reflue silenziosi montati verticalmente",
      nfs18:
        "Usato per prevenire perdite di temperatura in impianti con acqua calda o fredda.",
      nfs19: "Montaggio tramite placche",
      nfs20: "Fornire distanza regolabile tra tubo e muro",
      nfs21: "Realizzato in acciaio inossidabile AISI304",
      nfs22: "Alta resistenza del kernel",
      nfs23: "Fornire distanza regolabile tra tubo e parete",

      //Abrazadera de tubo con tuerca (juego)
      n1: "Il dado combinato è adatto per filettatura M8 e M10",
      n2: "Gomma: EPDM",

      //Altri Raccordi TR
      of1: "Copertina profilo",
      of2: "Morsetto a nastro infinito",
      of3: "Rondella di profilo",
      of4: "Supporto scorrevole",
      of5: "Collegamento angolare condotto di ventilazione",
      of6: "morsa appendiabiti",
      di7: "G Clip",
      di8: "V Trapezio",
      of9: "Correzione rapida",
      of10: "Dado scorrevole TB2",
      of11: "Dado scorrevole TB3",
      of12: "Dado scorrevole TB4",
      of13: "Dado scorrevole TB5",
      of14: "Dado scorrevole",
      of15: "Dado scorrevole quadrato",
      of16: "Dado scorrevole a molla",
      of17: "Piastra di base con dadi",
      of18: "Piastra da pavimento per dadi per carichi pesanti",
      of19: "Piastra di massa imbullonata",
      of20: "Piastra del pavimento imbullonata per carichi pesanti",
      of21: "Piastra con risvolto",
      di22: "Marciume",
      di23: "Stallone",
      of24: "Dado Din 934",
      of25: "Dado di prolunga Din 6334",
      of26: "Timbro stretto Din 125",
      of27: "Timbro largo Din 9021",
      di28: "Timbro di Whitwort",
      di29: "Timbro primaverile Din 127",
      of30: "Timbro quadrato",
      of31: "Rondella quadra per profili a U",
      of32: "Bullone Din 933",
      of33: "Adattatore Trifono",
      of34: "Tassello forato",
      of35: "Tira l'ancora",
      of36: "Ancora a clip",
      of37: "Ancoraggio tubolare imbullonato",
      of38: "Tassello con camicia",
      of39: "Ancoraggio controsoffitto",
      of40: "Ancora con gancio",
      of41: "Tassello bianco standard",
      of42: "Ancora grigia standard",
      of43: "Brick Space Tassello",
      of44: "Rocket Wall Anchor",
      of45: "Tassello di mattoni",
      of46: "Set installazione lavello",
      of47: "Set supporto WC",
      of48: "Set installazione lavabo a molla",
      of49: "Set di installazione scaldabagno combinato",
      of50: "Mobile da collezione con consolle",
      of51: "Gabinetto del collezionista",
      of52: "Morsetto collettore a testa quadra",
      of53: "Morsetto collettore a testa tonda",
      of54: "Fascetta in plastica",

      steelfeature:
        "Schema di fori continui progettato per l'interasse del raccordo sanitario",
      steelusage:
        "Sistema universale per il fissaggio a tubazioni o apparecchiature sanitarie.",
      //products-profileandconsole-cprofile
      cprofil: "Profilo C",
      material: "Materiale: acciaio prezincato",
      coating: "Rivestimento: zinco disponibile. rivestimento su richiesta",
      cfeature:
        "Per un fissaggio facile e veloce, foratura continua, scala centimetrica graduata continua su entrambi i lati",

      gfeature:
        "Con la perforazione continua, la distanza tra l'estremità della rotaia e il primo foro è sempre uguale",

      //products-profilee console-lprofile
      lprofil: "Profilo a L",
      lfeature: "Foratura continua per un fissaggio facile e veloce",

      //products-profileandconsole-upprofile
      uprofil: "Profilo U",

      //products-profileandconsole-crailconsole
      crailconsole: "Console di tipo C",

      //products-profileandconsole-crailconsole
      grailconsole: "Console di tipo G",

      //products-profileandconsole-lrailconsole
      lrailconsole: "Consolle tipo L",

      //products-profileandconsole-urailconsole
      railconsole: "Console di tipo U",

      whychoouse: "PERCHÉ NOI?",
      experience:
        "La nostra azienda, che rafforza la sua forza con i suoi rivenditori in 81 province della Turchia, sta compiendo passi decisi per diventare un marchio mondiale con le sue esportazioni in oltre 60 paesi in 4 continenti.",
      manufact:
        "Tutto ciò che stai cercando è sotto la radice della nostra fabbrica, che ha la più alta capacità produttiva nel suo settore in Turchia.",
      speed: "Seguire le tempistiche senza sacrificare la qualità.",
      innovation:
        "Seguendo le innovazioni nel settore HVAC con il nostro forte team di ricerca e sviluppo.",
      service:
        "I processi di ricerca e sviluppo, la linea di produzione, i servizi di imballaggio e trasporto sono sotto costante controllo.",
      facility: "STABILIMENTO PRODUTTIVO DA 5.000 MQ",
      monthly: "5.000.000 PRODUZIONE MENSILE",
      export: "ESPORTAZIONE IN 60 PAESI",
      continents: "4 CONTINENTES",

      // fixedimage
      qualityis: "QUALITÀ",
      ourbusiness: "AL CUORE DELLA NOSTRA ATTIVITÀ",

      // aboutus
      aboutdesc:
        "ASC FIXING è un marchio ricercato nel settore dell'idraulica e della climatizzazione, noto per la sua qualità e la soddisfazione del cliente. Fascette per tubi, profili e vari prodotti di raccorderia offrono le migliori soluzioni.",
      //  QUALİTY

      //  News
      announcements: "Annunci",
      fair: "Giusto",

      //  Contact
      address: "Contatto",
      contactus: "Contattaci",
      sendform: "Invia",
    },
  },

  it: {
    translation: {
      //Search Page 
      searchresults: "risultati di ricerca",
      searchnotfound: "Nessun risultato è stato trovato per la tua ricerca. Per favore riprova.",



      home: "CASA",
      aboutus: "CHI SIAMO",
      products: "PRODOTTI",
      quality: "QUALITÀ",
      news: "NOTIZIE",
      contact: "CONTATTO",
      navcatologue: "CATALOGHI",
      navcertificate: "CERTIFICATI",
      searchplaceholder: "Ricerca",
      languages: "le lingue",

      pdf: "Clicca per il catalogo prodotti...",
      uruntablosu: "Tabella Prodotti",

      // footer
      footerdesc:
        "ASC FIXING è un marchio ricercato nel settore dell'idraulica e della climatizzazione, noto per la sua qualità e la soddisfazione del cliente. Fascette per tubi, profili e vari prodotti di raccorderia offrono le migliori soluzioni.",
      links: "Collegamenti rapidi",
      informations: "Informazioni",
      catologue: "Cataloghi",
      certificate: "Certificati",
      footerproducts: "Prodotti",
      footerpipeclamps: "Morsetti",
      footerprofileconsole: "Profilo e Console",
      footerotherfittings: "Altri elementi di fissaggio",
      footercontact: "Contatto",
      footerfair: "Fiere",
      footerannouncements: "Annunci",

      // products
      products: "PRODOTTI",
      pipeclamps: "CLAMPS",
      profileconsole: "PROFILO E CONSOLE",
      otherfittings: "ALTRI FISSAGGI",
      steel: "Steel Rail",
      application: "Applicazioni",
      features: "Caratteristiche",

      // contact placeholders
      brandname: "Nome dell'azienda",
      authorizedperson: "Persona autorizzata",
      phonenumber: "Numero di telefono",
      message: "Messaggio",
      adress: "Indirizzo",

      pr1: "Morsa dado (Set)",
      pr2: "Morsetto per dadi senza gomma (set)",
      pr3: "Morsa dado",
      pr4: "Morsetto senza gomma con dado",
      pr5: "Morsa combinata con dado",
      pr6: "Morsa per dadi combinata senza pneumatici",
      pr7: "Morsetto Trifono Corto 7x70",
      pr8: "7x70 con Trifono Corto senza Gomma",
      pr9: "Morsetto Trifono Lungo 7x100",
      pr10: "Morsetto Trifono Lungo 7x100 Senza Gomma",
      pr11: "Morsetto per testa per carichi pesanti",
      pr12: "Morsetto per dadi per carichi pesanti",
      pr13: "Morsetto per piattaforma per impieghi gravosi",
      pr14: "Morsetto a forcella",
      pr15: "Morsetto per irrigatore",
      pr16: "Morsetto per sprinkler con manicotto",
      pr17: "Morsetto per tubi silenzioso con dado (verticale)",
      pr18: "Morsetto per tubi silenzioso con dado (orizzontale)",
      pr19: "Supporto per morsetto per tubi silenzioso",
      pr20: "Trifono con morsetto per tubi silenzioso (verticale)",
      pr21: "Trifono con morsetto per tubi silenzioso (orizzontale)",
      pr22: "Morsetto tipo italiano (set)",
      pr23: "Morsetto per dadi tipo italiano",
      pr24: "Morsa dado combinato tipo italiano",
      pr25: "Morsetto per dadi inox",
      pr26: "Morsa per dadi combi inox",
      pr27: "Cuneo isolante",
      pr28: "U Bolt",
      pr29: "Morsetto singolo",
      pr30: "Doppio Morsetto",

      nsa1: "Fijaciones para tuberías de acero, acero inoxidable, PPR, PVC, fundición, hierro, PE, cobre.",
      nsa2: "Se utiliza para sujetar las tuberías montándolas en las paredes, techos y pisos en forma horizontal o vertical.",

      nsf1: "La abrazadera con el juego de tuercas se empaqueta con un trípode adaptador y una etiqueta de plástico",
      nsf2: "Material: acero",
      nsf3: "La estructura reforzada proporciona una mayor durabilidad",
      nsf4: "Revestimiento: zincado",
      nsf5: "Puntos de soldadura: Las tuercas cónicas se sueldan mediante máquinas automáticas de soldadura por puntos",
      nsf6: "Montaje fácil y seguro mediante dos tornillos laterales",
      nsf7: "Los tornillos laterales están protegidos contra pérdidas durante el montaje con la ayuda de arandelas de plástico",
      nsf8: "(Electro-galvanizado)",
      nsf9: "Se debe usar un tapón de plástico para la fijación",
      nsf10: "Cumple con los requisitos de DN 4109",
      nsf11: "Dos tuercas adicionales aplicadas a los tornillos laterales.",
      nsf12:
        "Se producen dos tipos como tuerca M12 y M16 según el diámetro de la abrazadera",
      nfs13: "Fijación para tuberías silenciosas de PVC.",
      nfs14:
        "Se utiliza para sujetar las tuberías montándolas en las paredes de forma vertical.",
      nfs15: "Usado en sistemas de aguas residuales.",
      nfs16:
        "Proporciona silencio además del nivel de sonido de 8 - 11 dbars mediante el uso de tuberías de aguas residuales que se producen con la función de tubería de PVC silenciosa",
      nfs17:
        "En instalaciones de aguas residuales de tubería silenciosa de montaje vertical",
      nfs18:
        "Utilizado para evitar la pérdida de temperatura en instalaciones de agua fría o caliente.",
      nfs19: "Montaje mediante placas de pared",
      nfs20: "Proporcionar distancia ajustable entre tubería y pared",
      nfs21: "Fabricado en ACERO INOXIDABLE AISI304",
      nfs22: "Alta durabilidad frente a la corrosión",
      nfs23: "Proporcionar distancia ajustable entre tubería y pared",

      //Abrazadera de tubo con tuerca (juego)
      n1: "La tuerca combinada es adecuada para roscas M8 y M10",
      n2: "Goma:EPDM",

      //Otros Herrajes ES
      of1: "Tapa final de perfil",
      of2: "Franja de montaje perforada (galvanizada)",
      of3: "arandela en forma de U",
      of4: "Soporte Deslizante",
      of5: "Esquina Para Ventilación",
      of6: "Abrazadera de viga",
      of7: "G Clips",
      of8: "Percha de trapecio en V",
      of9: "Corrección rápida",
      of10: "Tuerca deslizante TB2",
      of11: "Tuerca deslizante TB3",
      of12: "Tuerca deslizante TB4",
      of13: "Tuerca deslizante TB5",
      of14: "Tuerca deslizante",
      of15: "Tuerca deslizante cuadrada",
      of16: "Tuerca deslizante de resorte",
      of17: "Placa de pared con tuerca",
      of18: "Placa de pared con tuerca soldada",
      of19: "Placa de pared con perno",
      of20: "Placa de pared con soldadura en negrita",
      of21: "Placa de pared con manguito",
      of22: "Varilla roscada",
      of23: "Perno prisionero",
      of24: "Tuerca DIN 934",
      of25: "Tuerca de extensión DIN 6334",
      of26: "Arandela Estrecha Din 125",
      of27: "Arandela Ancha Din 9021",
      of28: "Arandela Whitworth de İnch",
      of29: "Arandela elástica Din 127",
      of30: "Arandela cuadrada",
      of31: "Arandela cuadrada para perfil en U",
      of32: "Perno DIN 933",
      of33: "Adaptador Trifono",
      of34: "Anclaje de caída",
      of35: "Anclaje de extracción (tipo S)",
      of36: "Ancla de cuña",
      of37: "Anclaje tipo tubería",
      of38: "Anclaje de manga",
      of39: "Anclaje de techo",
      of40: "Ancla de gancho",
      of41: "Anclaje Plástico Estándar Blanco",
      of42: "Anclaje de plástico estándar gris",
      of43: "Anclaje de cavidad de ladrillo",
      of44: "Ancla de plástico cohete",
      of45: "Ancla de ladrillo",
      of46: "Conjunto de montaje para lavabo",
      of47: "Conjunto de montaje de armario",
      of48: "Juego de ensamblaje de lavabo de resorte",
      of49: "Conjunto de montaje del calentador de agua",
      of50: "Caja de coleccionista (con consola)",
      of51: "Caja de coleccionista",
      of52: "Cuadrado de la abrazadera del colector",
      of53: "Abrazadera de colector redonda",
      of54: "Abrazadera plástica ajustable",

      steelfeature:
        "Patrón de orificios continuos, diseñado para la distancia entre centros de la armadura sanitaria",
      steelusage:
        "Sistema universal para fijación a tubería o equipo sanitario.",
      //products-profileandconsole-cprofile
      cprofil: "Perfil C",
      material: "Material: acero pregalvanizado",
      coating: "Recubrimiento : Zinc disponible. Recubrimiento bajo demanda",
      cfeature:
        "Para una fijación rápida y fácil, perforación continua, una escala centimétrica graduada continua en dos lados",

      gprofil: "Perfil G",
      gfeature:
        "Con perforación continua, la distancia entre el extremo del riel y el primer orificio siempre es igual",

      //products-profileandconsole-lprofile
      lprofil: "Perfil L",
      lfeature: "Para una fijación rápida y fácil, perforación continua",

      //products-profileandconsole-upprofile
      uprofil: "Perfil U",

      //productos-perfilyconsola-crailconsole
      crailconsole: "Consola de riel C",

      //productos-perfilyconsola-crailconsole
      grialconsole: "Consola G Rail",

      //productos-perfilyconsola-lrailconsole
      lrailconsole: "Consola de riel L",

      //productos-perfilyconsola-urailconsole
      urailconsole: "Consola de riel U",

      whychoouse: "POR QUÉ ELEGIRNOS?",
      experience:
        "Nuestra empresa, que se ha fortalecido con sus distribuidores en 81 provincias de Turquía, está dando pasos seguros para convertirse en una marca mundial debido a sus exportaciones a 4 continentes, más de 60 países",
      manufact:
        "Todo lo que busca está bajo la raíz de nuestra fábrica, que tiene la mayor capacidad de producción en su sector en Turquía",
      speed: "Seguir horarios sin comprometer la calidad.",
      innovation:
        "Siguiendo las innovaciones en la industria HVAC con nuestro sólido equipo de I+D",
      service:
        "Los procesos de I+D, la línea de producción, los servicios de embalaje y transporte están bajo control de forma continua.",
      facility: "5.000 M2 PLANTA DE PRODUCCIÓN",
      monthly: "5.000.000 PRODUCCIÓN MENSUAL",
      export: "60 EXPORTAR A PAISES",
      continents: "4 CONTINENTI",

      // fixedimage
      qualityis: "CALIDAD ES",
      ourbusiness: "CORAZÓN DE NUESTRO NEGOCIO",

      // aboutus
      aboutdesc:
        "ASC FIXING es una marca bien conocida y buscada por su calidad y satisfacción del cliente en la industria de plomería y HVAC. Los productos, abrazaderas para tuberías, perfiles y diversos accesorios brindan las mejores soluciones",

      //  QUALİTY

      //  News
      announcements: "Anuncios",
      fair: "Justo",

      //  Contact
      address: "Dirección",
      contactus: "Contáctenos",
      sendform: "Enviar formulario",
    },
  },
  uk: {
    translation: {
      //Search Page 
      searchresults: "Результати пошуку",
      searchnotfound: "За вашим запитом не знайдено результатів. Будь ласка спробуйте ще раз.",



      home: "ДОМАШНЯ СТОРІНКА",
      aboutus: "ПРО НАС",
      products: "ПРОДУКТИ",
      quality: "ЯКІСТЬ",
      news: "НОВИНИ",
      contact: "ЗВ'ЯЗОК",
      navcatologue: "КАТАЛОГИ",
      navcertificate: "СЕРТИФІКАТИ",
      searchplaceholder: "пошук",
      languages: "мови",

      pdf: "Натисніть, щоб переглянути каталог продукції...",
      uruntablosu: "Таблиця продуктів",

      footerdesc:
        "ASC FIXING — це затребуваний бренд у галузі сантехніки та кондиціонування повітря, відомий своєю якістю та задоволеністю клієнтів. Трубні хомути, профілі та різноманітні фітинги пропонують найкращі рішення",
      links: "Швидкі посилання",
      informations: "Інфо",
      catologue: "Каталоги",
      certificate: "Сертифікати",
      footerproducts: "Продукти",
      footerpipeclamps: "Затискачі",
      footerprofileconsole: "Профіль і консоль",
      footerotherfittings: "Інші кріплення",
      footercontact: "Контакт",
      footerfair: "Ярмарки",
      footerannouncements: "Оголошення",

      // products
      products: "ПРОДУКТИ",
      pipeclamps: "ЗАЖИМИ",
      profileconsole: "ПРОФІЛЬ ТА КОНСОЛІ",
      otherfittings: "ІНШІ Кріплення",
      steel: "Сталева рейка",
      application: "Програми",
      features: "Особливості",

      // contact placeholders
      brandname: "Назва компанії",
      authorizedperson: "Уповноважена особа",
      phonenumber: "Номер телефону",
      message: "Повідомлення",
      adress: "Адреса",

      pr1: "Затискач гайки (набір)",
      pr2: "Безгумовий гайковий зажим (набір)",
      pr3: "Гайковий затискач",
      pr4: "Безгумовий хомут з ​​гайкою",
      pr5: "Комбінований хомут з ​​гайкою",
      pr6: "Tyreless Combi Nut Clamp",
      pr7: "Затискач для трифона короткий 7x70",
      pr8: "7x70 з коротким трифоном без гуми",
      pr9: "Довгий трифонний зажим 7x100",
      pr10: "Довгий трифонний хомут 7x100 без гуми",
      pr11: "Затискач голови для важкого навантаження",
      pr12: "Затискач гайки для важкого навантаження",
      pr13: "Зажим для платформи для важких навантажень",
      pr14: "Clevis Clamp",
      pr15: "Спринклерний зажим",
      pr16: "Спринклерний зажим із рукавом",
      pr17: "Безшумний хомут для труб з гайкою (вертикальний)",
      pr18: "Безшумний трубний хомут з ​​гайкою (горизонтальний)",
      pr19: "Безшумна підставка для трубного хомута",
      pr20: "Безшумний трубний трифон (вертикальний)",
      pr21: "Безшумний трубний трифон (горизонтальний)",
      pr22: "Затискач італійського типу (набір)",
      pr23: "Гайковий зажим італійського типу",
      pr24: "Комбінований гайковий зажим італійського типу",
      pr25: "Нержавіючий гайковий зажим",
      pr26: "Комбінований гайковий зажим із нержавіючої сталі",
      pr27: "Ізоляційний клин",
      pr28: "U Bolt",
      pr29: "Один затискач",
      pr30: "Подвійний затискач",

      nsa1: "Фітингові елементи для труб зі сталі, нержавіючої сталі, PPR, PVC, свинини, чавуну, PE, міді.",
      nsa2: "Використовується для кріплення труб шляхом горизонтального або вертикального кріплення до стіни, стелі та підлоги.",

      nsf1: "Укомплектовано набором гайкових затискачів, трифонним адаптером і пластиковим дюбелем",
      nsf2: "Матеріал: сталь",
      nsf3: "Посилена структура забезпечує більшу міцність",
      nsf4: "Покриття: оцинковане",
      nsf5: "Точки зварювання: конічні гайки зварюються в автоматичних машинах для точкового зварювання.",
      nsf6: "Простий і безпечний монтаж завдяки двом бічним гвинтам",
      nsf7: "Бічні гвинти захищені від втрати під час збирання за допомогою пластикових шайб",
      nsf8: "(Електрооцинкований)",
      nsf9: "Для кріплення слід використовувати пластикову заглушку.",
      nsf10: "Відповідає вимогам DN 4109.",
      nsf11: "Дві додаткові гайки застосовані до бічних гвинтів.",
      nsf12:
        "Два типи виготовляються як гайки M12 і M16 відповідно до діаметра затискача",
      nfs13: "Кріплення для тихих ПВХ труб.",
      nfs14:
        "Використовується для кріплення труб, монтуючи їх вертикально на стіні.",
      nfs15: "Використовується в системах водовідведення.",
      nfs16:
        "Це забезпечує тишу на додаток до рівня звуку 8 - 11 дбар при використанні з трубами для стічної води, виготовленими з безшумними трубами з ПВХ.",
      nfs17: "У вертикально встановлених безшумних системах водовідведення",
      nfs18:
        "Використовується для запобігання втраті температури в системах гарячого або холодного водопостачання.",
      nfs19: "Монтаж за допомогою настінних панелей",
      nfs20: "Надати регульовану відстань між трубою та стіною",
      nfs21: "Виготовлено з нержавіючої сталі AISI304",
      nfs22: "Висока стійкість ядра",
      nfs23: "Надати регульовану відстань між трубою та стіною",

      n1: "Комбінована гайка підходить для різьби M8 і M10",
      n2: "Гума: EPDM",

      //Інша арматура TR
      of1: "Обкладинка профілю",
      of2: "Нескінченний стрічковий затискач",
      of3: "Профільна шайба",
      of4: "Розсувна опора",
      of5: "Кутове з'єднання вентиляційного каналу",
      of6: "Лещата вішалки",
      of7: "G Clip",
      of8: "V Трапеція",
      of9: "Швидке виправлення",
      of10: "Ковзна гайка TB2",
      of11: "Гайка розсувна TB3",
      of12: "Гайка розсувна TB4",
      of13: "Гайка розсувна TB5",
      of14: "Ковзна гайка",
      of15: "Квадратна розсувна гайка",
      of16: "Пружинна гайка",
      of17: "Базова тарілка з горіхами",
      of18: "Пластина для гайки для важких навантажень",
      of19: "Пластина заземлення з болтами",
      of20: "Плита підлоги з болтами для важкого навантаження",
      of21: "Накладка з манжетами",
      of22: "Гниль",
      of23: "Студ",
      of24: "Гайка Din 934",
      of25: "Подовжувальна гайка Din 6334",
      of26: "Вузька марка Din 125",
      of27: "Широка марка Din 9021",
      of28: "Штамп Whitwort",
      of29: "Весняна марка Din 127",
      of30: "Квадратна марка",
      of31: "Квадратна шайба для U-профілів",
      of32: "Болт Din 933",
      of33: "Трифонний адаптер",
      of34: "Свердлений дюбель",
      of35: "Витягнути якір",
      of36: "Кріпковий анкер",
      of37: "Болтовий трубчастий анкер",
      of38: "Shired Dowel",
      of39: "Анкер для підвісної стелі",
      of40: "Якір з гаком",
      of41: "Стандартний білий дюбель",
      of42: "Стандартний сірий якір",
      of43: "Цегляний просторовий дюбель",
      of44: "Якір ракетної стіни",
      of45: "Цегельний дюбель",
      of46: "Набір для встановлення раковинof",
      of47: "Кріплення для туалету",
      of48: "Набір для монтажу пружинного басейну",
      of49: "Набір для монтажу комбінованого водонагрівача",
      of50: "Колекторна шафа з консоллю",
      of51: "Колекторська шафа",
      of52: "Хомутик колектора з квадратною головкою",
      of53: "Хомутик колектора з круглою головкою",
      of54: "Пластикова кабельна стяжка",

      steelfeature:
        "Безперервна схема отворів, призначена для міжцентрової відстані сантехнічної арматури",
      steelusage:
        "Універсальна система для кріплення до трубопроводів або сантехнічного обладнання.",

      cprofil: "Профіль C",
      material: "Матеріал: попередньо оцинкована сталь",
      coating: "Покриття: доступний цинк. покриття за запитом",
      cfeature:
        "Для швидкої та легкої фіксації, безперервне свердління, безперервна градуйована сантиметрова шкала з обох сторін",

      gprofil: "Профіль G",
      gfeature:
        "При безперервному свердлінні відстань між кінцем рейки та першим отвором завжди однакова",

      //products-profileandconsole-lprofile
      lprofil: "Профіль L",
      lfeature: "Безперервне свердління для швидкого та легкого кріплення",

      //products-profileandconsole-uprofile
      uprofil: "Профіль U",

      //products-profileandconsole-crailconsole
      crailconsole: "Консоль типу C",

      //products-profileandconsole-crailconsole
      grailconsole: "Консоль G-типу",

      //products-profileandconsole-lrailconsole
      lrailconsole: "Консоль типу L",

      //products-profileandconsole-urailconsole
      urailconsole: "Консоль типу U",

      whychoouse: "ЧОМУ МИ?",
      experience:
        "Наша компанія, яка зміцнює свою владу за допомогою своїх дилерів у 81 провінції Туреччини, робить тверді кроки до того, щоб стати світовим брендом з експортом у більш ніж 60 країн на 4 континентах.",
      manufact:
        "Усе, що ви шукаєте, знаходиться в основі нашої фабрики, яка має найвищу виробничу потужність у своєму секторі в Туреччині.",
      speed: "Дотримання часових рамок без шкоди для якості.",
      innovation:
        "Наслідування інновацій у галузі опалення, вентиляції, вентиляції та кондиціонування повітря з нашою сильною командою досліджень і розробок",
      service:
        "Процеси R&D, виробнича лінія, пакувальні та транспортні послуги знаходяться під постійним контролем.",
      facility: "ВИРОБНИЧИЙ ЦЕХ 5 000 М2",
      monthly: "5 000 000 ВИРОБНИЦТВО МІСЯЦЯ",
      export: "ЕКСПОРТ У 60 КРАЇН",
      continents: "4 КОНТИНЕНТИ",

      qualityis: "ЯКІСТЬ",
      ourbusiness: "В СЕРЦІ НАШОГО БІЗНЕСУ",

      // aboutus
      aboutdesc:
        "ASC FIXING — це затребуваний бренд у галузі сантехніки та кондиціонування повітря, відомий своєю якістю та задоволеністю клієнтів. Трубні хомути, профілі та різноманітні фітинги пропонують найкращі рішення",

      //  News
      announcements: "Оголошення",
      fair: "Чесно",

      //  Contact
      address: "Контакт",
      contactus: "Зв'язатися з нами",
      sendform: "Надіслати",
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  resources,
});
export default i18n;
