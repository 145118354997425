import React from 'react'
import pdffile from "../assets/pdf/ascfixing_new_catalogu_2022.pdf"
import { Document, Page } from 'react-pdf';
const pdf = () => {
  return (
    <div  className='w-screen h-screen'>
     <iframe src={pdffile} frameborder="0" className='w-screen h-screen'></iframe>
    </div>
  )
}

export default pdf
