import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_spring_washer_.jpg";
import Second from "../../assets/img/other-fittings/ascfixing_spring_washer_teknik.jpg";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const SpringWasherDin127 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of29")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of29")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
      </div>
      <div className="flex flex-row max-md:flex-col gap-x-5 overflow-x-auto mb-10 md:ml-[200px] mx-4">
        <table className="w-[50%]">
          <tr className="table-tr">
            <td>Product Code</td>
            <td>Size M</td>
            <td>Inner Diameter d1 (mm)</td>
            <td>Outer Diameter d2 (mm)</td>
            <td>Thickness s (mm)</td>
            <td> w (mm)</td>
            <td>Height h (mm)</td>
            <td>Weight/pcs (gr)</td>
          </tr>
          <tr>
            <td>ASC YP 006</td>
            <td>M 6</td>
            <td>6,1</td>
            <td>11,8</td>
            <td>1,6</td>
            <td>2,5</td>
            <td>3,2</td>
            <td>0,83</td>
          </tr>
          <tr>
            <td>ASC YP 008</td>
            <td>M 8</td>
            <td>8,1</td>
            <td>14,8</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>1,6</td>
          </tr>
          <tr>
            <td>ASC YP 010</td>
            <td>M 10</td>
            <td>10,2</td>
            <td>18,1</td>
            <td>2,2</td>
            <td>3,5</td>
            <td>4,4</td>
            <td>2,53</td>
          </tr>
          <tr>
            <td>ASC YP 012</td>
            <td>M 12</td>
            <td>12,2</td>
            <td>21,1</td>
            <td>2,5</td>
            <td>4</td>
            <td>5</td>
            <td>3,82</td>
          </tr>
          <tr>
            <td>ASC YP 014</td>
            <td>M 14</td>
            <td>14,2</td>
            <td>24,1</td>
            <td>3</td>
            <td>4,5</td>
            <td>6</td>
            <td>6,01</td>
          </tr>
          <tr>
            <td>ASC YP 016</td>
            <td>M 16</td>
            <td>16,2</td>
            <td>27,4</td>
            <td>3,5</td>
            <td>5</td>
            <td>7</td>
            <td>8,91</td>
          </tr>
          <tr>
            <td>ASC YP 018</td>
            <td>M 18</td>
            <td>18,2</td>
            <td>29,4</td>
            <td>3,5</td>
            <td>5</td>
            <td>7</td>
            <td>9,73</td>
          </tr>
          <tr>
            <td>ASC YP 020</td>
            <td>M 20</td>
            <td>20,2</td>
            <td>33,6</td>
            <td>4</td>
            <td>6</td>
            <td>8</td>
            <td>15,2</td>
          </tr>
          <tr>
            <td>ASC YP 022</td>
            <td>M 22</td>
            <td>22,5</td>
            <td>35,9</td>
            <td>4</td>
            <td>6</td>
            <td>8</td>
            <td>16,5</td>
          </tr>
          <tr>
            <td>ASC YP 024</td>
            <td>M 24</td>
            <td>24,5</td>
            <td>40</td>
            <td>5</td>
            <td>7</td>
            <td>10</td>
            <td>26,2</td>
          </tr>
        </table>
        <div>
          <div className="flex flex-row mx-auto gap-x-8">
            <FancyBox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              <Link className="image  items-center object-cover mb-3">
                <img
                  data-fancybox="detay"
                  src={Second}
                  alt=""
                  className="h-[250px] drop-shadow-2xl"
                />
              </Link>
            </FancyBox>
          </div>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=63"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default SpringWasherDin127;
