import React from "react";
import Bubble from "../assets/bubble.png";
import LocationImage from "../assets/location.png";
import Manifacture from "../assets/manifacture.png";
import WhyChoose1 from '../assets/img/why-choose-us/why-choose-1.jpg'
import WhyChoose2 from '../assets/img/why-choose-us/why-choose-2.jpg'
import WhyChoose3 from '../assets/img/why-choose-us/why-choose-3.jpg'
import WhyChoose4 from '../assets/img/why-choose-us/why-choose-4.jpg'
import WhyChoose5 from '../assets/img/why-choose-us/why-choose-5.jpg'
import World from "../assets/world.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}


const Global = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <div className="border border-solid border-x-0 border-y-8 mt-20 mb-9 py-20" data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine">
        <div className="pl-[25px] relative md:pl-[125px]">
          <div className="w-[20px] h-14 bg-[#1D4ED8] rounded-sm absolute"></div>
          <h1 className="flex justify-start font-bold text-2xl md:text-4xl text-[#1D4ED8] pt-2 pb-5 ml-7">
            {t('whychoouse')}
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row gap-y-10 lg:gap-x-6 justify-center p-6">
          <div className="w-full lg:w-1/6 text-sm lg:text-base duration-75">
            <div className="w-full box-border overflow-hidden">
              <img
                src={WhyChoose1}
                className="hover:scale-110 duration-500 w-[75%] mx-auto"
              />
            </div>
            <p className="text-sm py-3 ">
              {t('experience')}
            </p>
          </div>
          <div className="w-full lg:w-1/6 text-sm lg:text-base duration-75">
            <div className="w-full box-border overflow-hidden">
              <img
                src={WhyChoose2}
                className="hover:scale-110 duration-500 w-[75%] mx-auto"
              />
            </div>
            <p className="text-sm py-3">
              {t('manufact')}
            </p>
          </div>
          <div className="w-full lg:w-1/6 text-sm lg:text-base duration-75">
            <div className="w-full box-border overflow-hidden">
              <img
                src={WhyChoose3}
                className="hover:scale-110 duration-500 w-[75%] mx-auto"
              />
            </div>
            <p className="text-sm py-3">{t('speed')}</p>
          </div>
          <div className="w-full lg:w-1/6 text-sm lg:text-base duration-75">
            <div className="w-full box-border overflow-hidden">
              <img
                src={WhyChoose4}
                className="hover:scale-110 duration-500 w-[75%] mx-auto"
              />
            </div>
            <p className="text-sm py-3">
              {t('innovation')}
            </p>
          </div>
          <div className="w-full lg:w-1/6 text-sm lg:text-base duration-75">
            <div className="w-full box-border overflow-hidden">
              <img
                src={WhyChoose5}
                className="hover:scale-110 duration-500 w-[75%] mx-auto"
              />
            </div>
            <p className="text-sm py-3">
              {t('service')}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-y-10 lg:gap-x-6 justify-center" data-aos="fade-up"
        data-aos-anchor-placement="top-center">
        <div className="w-full lg:w-1/5 text-base lg:text-xl duration-75 hover:lg:text-blue-800 hover:font-bold">
          <div className="w-full box-border overflow-hidden">
            <img src={Manifacture} className="hover:scale-110 duration-500 w-[75%] mx-auto" />
          </div>
          <p className="text-center px-6 py-2">{t('facility')}</p>
        </div>
        <div className="w-full lg:w-1/5 text-base lg:text-xl duration-75 hover:lg:text-blue-800 hover:font-bold">
          <div className="w-full box-border overflow-hidden">
            <img src={Bubble} className="hover:scale-110 duration-500 w-[75%] mx-auto" />
          </div>
          <p className="text-center px-6 py-2">{t('monthly')}</p>
        </div>
        <div className="w-full lg:w-1/5 text-base lg:text-xl duration-75 hover:lg:text-blue-800 hover:font-bold">
          <div className="w-full box-border overflow-hidden">
            <img src={LocationImage} className="hover:scale-110 duration-500 w-[75%] mx-auto" />
          </div>
          <p className="text-center px-6 py-2">{t('export')}</p>
        </div>
        <div className="w-full lg:w-1/5 text-base lg:text-xl duration-75 hover:lg:text-blue-800 hover:font-bold">
          <div className="w-full box-border overflow-hidden">
            <img src={World} className="hover:scale-110 duration-500 w-[75%] mx-auto" />
          </div>
          <p className="text-center px-6 py-2">{t('continents')}</p>
        </div>
      </div>
    </div>
  );
};

export default Global;
