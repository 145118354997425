import React, { useState, Fragment, Component } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import {
  Route,
  Routes,
  Link,
  NavLink,
  redirect,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import MaviLogo from "../assets/asc-fixing-logo.png";
import BeyazLogo from "../assets/asc-fixing-beyaz-logo.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import About from "../pages/About";
import TurkeyFlag from "../assets/turkey.png";
import ENFlag from "../assets/united-kingdom.png";
import FRFlag from "../assets/france.png";
import SPFlag from "../assets/spain.png";
import ITFlag from "../assets/italy.png";
import UKFlag from "../assets/ukraine.png";
import ARFlag from "../assets/united-arab-emirates.png";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const onButtonClick = () => {
  // using Java Script method to get PDF file
  fetch("general-clamp-fixing-system.pdf").then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "general-clamp-fixing-system.pdf";
      alink.click();
    });
  });
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [search, setSearch] = useState("");

  const getValue = (event) => {
    setSearch(event.target.value);
  };
  const navigate = useNavigate();
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate("/search/" + search);
    }
  };

  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  const navLinkStyles = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#1D4ED8" : "transparent",
      color: isActive ? "#fff" : "#333",
      borderRadius: isActive ? "6px" : "none",
    };
  };
  const { t, i18n } = useTranslation();
  return (
    <nav className="flex justify-between fixed top-0 z-[80] bg-[#fff] items-center w-full h-[100px] shadow-lg text-black">
      <div className="container relative max-w-[1490px] flex justify-start items-center ">
        <NavLink to="/">
          <img
            className="lg:hidden absolute -top-14 lg:-top-6 lg:left-32 h-16 md:h-20 my-3 mx-1"
            src={MaviLogo}
            alt="/"
          />
        </NavLink>
        <ul className="hidden items-center text-center lg:flex md:text-[12px] max-sm:text-[8px] justify-center fixed w-screen text-black xl:gap-x-6 gap-x-1">
          <NavLink to="/">
            <img
              width={150}
              height={50}
              className="absolute -top-4 left-10 xl:-top-2 xl:left-44"
              src={MaviLogo}
              alt="/"
            />
          </NavLink>
          <NavLink to="/" style={navLinkStyles}>
            <li className="p-3 font-bold hover:text-[#fff] hover:bg-[#1D4ED8] rounded-md hover:scale-110 duration-300">
              {t("home")}
              <Menu as="div" className="relative inline-block text-left">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-xl bg-white divide-y divide-gray-100 focus:outline-none"></Menu.Items>
                </Transition>
              </Menu>
            </li>
          </NavLink>

          <NavLink to="/about" style={navLinkStyles}>
            <li className="p-3 font-bold hover:text-[#fff] hover:bg-[#1D4ED8] rounded-md hover:scale-110 duration-300">
              {t("aboutus")}
              <Menu as="div" className="relative inline-block text-left">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-xl bg-white divide-y divide-gray-100 focus:outline-none"></Menu.Items>
                </Transition>
              </Menu>
            </li>
          </NavLink>
          <NavLink to="/products" style={navLinkStyles}>
            <li className="group is-published p-3 font-bold hover:text-[#fff] hover:bg-[#1D4ED8] rounded-md hover:scale-110 duration-300">
              {t("products")}
              <Menu as="div" className="relative inline-block text-left">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-xl bg-white divide-y divide-gray-100 focus:outline-none"></Menu.Items>
                </Transition>
              </Menu>
              <div className="hidden group-hover:block w-[170px] text-center text-black absolute border bg-white mt-[13px] ml-[-58px] rounded-lg">
                <NavLink to="/products/pipe-clamps">
                  <li class="p-2 font-light text-[12px] hover:bg-[#e9e7e7]">
                    {t("pipeclamps")}
                  </li>
                </NavLink>
                <Link to="/products/profile-console">
                  <li class="p-2 font-light text-[12px] hover:bg-[#e9e7e7]">
                    {t("profileconsole")}
                  </li>
                </Link>
                <Link to="/products/other-fittings">
                  <li class="p-2 font-light text-[12px] hover:bg-[#e9e7e7]">
                    {t("otherfittings")}
                  </li>
                </Link>
              </div>
            </li>
          </NavLink>

          <NavLink to="/quality" style={navLinkStyles}>
            <li className="group p-3 font-bold hover:text-[#fff] hover:bg-[#1D4ED8] rounded-md hover:scale-110 duration-300">
              {t("quality")}
              <Menu as="div" className="relative inline-block text-left">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-xl bg-white divide-y divide-gray-100 focus:outline-none"></Menu.Items>
                </Transition>
              </Menu>
              <div className="hidden group-hover:block w-[170px] text-center text-black absolute border bg-white mt-[13px] ml-[-60px] rounded-lg">
                <li class="p-2 font-light text-[12px] hover:bg-[#e9e7e7]">
                  <Link to="/quality/certificates"> {t("navcertificate")}</Link>
                </li>
                <li class="p-2 font-light text-[12px] hover:bg-[#e9e7e7]">
                  <button onClick={onButtonClick}>
                    <Link to="/"> {t("navcatologue")} </Link>
                  </button>
                </li>
              </div>
            </li>
          </NavLink>

          <NavLink to="/news" style={navLinkStyles}>
            <li className="p-3 font-bold hover:text-[#fff] hover:bg-[#1D4ED8] rounded-md hover:scale-110 duration-300">
              {t("news")}
              <Menu as="div" className="relative inline-block text-left">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-xl bg-white divide-y divide-gray-100 focus:outline-none"></Menu.Items>
                </Transition>
              </Menu>
            </li>
          </NavLink>

          <NavLink to="/contact" style={navLinkStyles}>
            <li className="p-3 font-bold hover:text-[#fff] hover:bg-[#1D4ED8] rounded-md hover:scale-110 duration-300">
              {t("contact")}
              <Menu as="div" className="relative inline-block text-left">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-xl bg-white divide-y divide-gray-100 focus:outline-none"></Menu.Items>
                </Transition>
              </Menu>
            </li>
          </NavLink>
        </ul>

        {/* Dropdown Ülke Bayrakları  */}

        <div className="fixed right-2 max-md:right-12 flex items-center z-30 text-xs md:px-6 py-2">
          <div className="dropdown">
            <button
              tabIndex={0}
              className="p-3 rounded-lg text-base font-semibold uppercase bg-[#1D4ED8] border-none text-[#fff] m-1"
            >
              {t("languages")}
            </button>
            <ul
              tabIndex={0}
              className="dropdown-content bg-[#fff] menu p-2 shadow rounded-box w-[80px]"
            >
              <li>
                <button onClick={() => clickHandle("tr")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={TurkeyFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
              <li>
                <button onClick={() => clickHandle("en")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={ENFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
              <li>
                <button onClick={() => clickHandle("fr")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={FRFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
              <li>
                <button onClick={() => clickHandle("es")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={SPFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
              <li>
                <button onClick={() => clickHandle("it")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={ITFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
              <li>
                <button onClick={() => clickHandle("uk")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={UKFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
              <li>
                <button onClick={() => clickHandle("ar")}>
                  <img
                    className="flex items-center justify-center mx-auto"
                    src={ARFlag}
                    alt="/"
                    width={30}
                    height={10}
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>

        <label class="relative block">
          <span class="sr-only">Search</span>
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">
              {" "}
              ...
            </svg>
          </span>
          <input
            class="w-[125px] placeholder:italic placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xs fixed max-xl:hidden items-center md:right-28 max-md:right-10 2xl:w-[200px] h-8 text-start top-8 mr-4 xl:mr-[65px]"
            placeholder={t("searchplaceholder")}
            type="text"
            name="search"
            onKeyDown={handleKeyPress}
            onChange={getValue}
          />
        </label>
        <div
          onClick={handleNav}
          className="block lg:hidden fixed right-0 pr-2 py-8"
        >
          {!nav ? (
            <AiOutlineClose className="w-6 h-6 cursor-pointer" />
          ) : (
            <AiOutlineMenu className="w-6 h-6 cursor-pointer" />
          )}
        </div>
        <div
          className={
            !nav
              ? "fixed left-0 top-0 w-[90%] h-full border-r border-r-gray-100 bg-[#1D4ED8] ease-out z-30 duration-500"
              : "fixed left-[-100%]"
          }
        >
          <div>
            <img src={BeyazLogo} className="w-[40%] mx-auto my-10" alt="/" />
          </div>
          <div>
            <h1 className="w-full text-3xl font-bold text-white cursor-pointer"></h1>
          </div>
          <ul className="pt-6">
            <Link to="/">
              <li className="p-4 font-bold text-white/80 border-b border-gray-400 cursor-pointer">
                {t("home")}
              </li>
            </Link>
            <Link to="/about">
              <li className="p-4 font-bold text-white/80 border-b border-gray-400 cursor-pointer">
                {t("aboutus")}
              </li>
            </Link>
            <Link to="/products">
              <li className="p-4 font-bold text-white/80 border-b border-gray-400 cursor-pointer">
                {t("products")}
              </li>
            </Link>
            <Link to="/quality">
              <li className="p-4 font-bold text-white/80 border-b border-gray-400 cursor-pointer">
                {t("quality")}
              </li>
            </Link>
            <Link to="/news">
              <li className="p-4 font-bold text-white/80 border-b border-gray-400 cursor-pointer">
                {t("news")}
              </li>
            </Link>
            <Link to="/contact">
              <li className="p-4 font-bold text-white/80 cursor-pointer">
                {t("contact")}
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
