import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_adjustable_plastic_clamp_2.jpg";
import Second from "../../assets/img/other-fittings/ascfixing_adjustable_plastic_clamp.png";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const AdjustablePlasticClamp = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of54")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of54")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
      </div>
      <div className="overflow-x-auto mb-10 md:ml-[200px] mx-4">
        <table className="w-[50%]">
          <tr className="table-tr">
            <td>Product Code</td>
            <td>Size</td>
            <td>L (mm)</td>
            <td>Pcs / Bag</td>
            <td>Kg / Bag</td>
          </tr>
          <tr>
            <td>ASC PK 0024-2,5</td>
            <td>24</td>
            <td>100</td>
            <td>100</td>
            <td>0,030</td>
          </tr>
          <tr>
            <td>ASC PK 0040-2,5</td>
            <td>40</td>
            <td>150</td>
            <td>100</td>
            <td>0,045</td>
          </tr>
          <tr>
            <td>ASC PK 0055-2,5</td>
            <td>55</td>
            <td>200</td>
            <td>100</td>
            <td>0,068</td>
          </tr>
          <tr>
            <td>ASC PK 0040-3,6</td>
            <td>40</td>
            <td>150</td>
            <td>100</td>
            <td>0,070</td>
          </tr>
          <tr>
            <td>ASC PK 0055-3,6</td>
            <td>55</td>
            <td>200</td>
            <td>100</td>
            <td>0,095</td>
          </tr>
          <tr>
            <td>ASC PK 0068-3,6</td>
            <td>68</td>
            <td>250</td>
            <td>100</td>
            <td>0,111</td>
          </tr>
          <tr>
            <td>ASC PK 0082-3,6</td>
            <td>82</td>
            <td>300</td>
            <td>100</td>
            <td>0,130</td>
          </tr>
          <tr>
            <td>ASC PK 0102-3,6</td>
            <td>102</td>
            <td>370</td>
            <td>100</td>
            <td>0,160</td>
          </tr>
          <tr>
            <td>ASC PK 0055-4,8</td>
            <td>55</td>
            <td>200</td>
            <td>100</td>
            <td>0,130</td>
          </tr>
          <tr>
            <td>ASC PK 0068-4,8</td>
            <td>68</td>
            <td>250</td>
            <td>100</td>
            <td>0,157</td>
          </tr>
          <tr>
            <td>ASC PK 0082-4,8</td>
            <td>82</td>
            <td>300</td>
            <td>100</td>
            <td>0,221</td>
          </tr>
          <tr>
            <td>ASC PK 0110-4,8</td>
            <td>110</td>
            <td>380</td>
            <td>100</td>
            <td>0,250</td>
          </tr>
          <tr>
            <td>ASC PK 0130-4,8</td>
            <td>130</td>
            <td>450</td>
            <td>100</td>
            <td>0,300</td>
          </tr>
          <tr>
            <td>ASC PK 0160-4,8</td>
            <td>160</td>
            <td>500</td>
            <td>100</td>
            <td>0,321</td>
          </tr>
          <tr>
            <td>ASC PK 0082-8</td>
            <td>82</td>
            <td>300</td>
            <td>100</td>
            <td>0,400</td>
          </tr>
          <tr>
            <td>ASC PK 0110-8</td>
            <td>110</td>
            <td>380</td>
            <td>100</td>
            <td>0,500</td>
          </tr>
          <tr>
            <td>ASC PK 0133-8</td>
            <td>133</td>
            <td>450</td>
            <td>100</td>
            <td>0,550</td>
          </tr>
          <tr>
            <td>ASC PK 0166-8</td>
            <td>166</td>
            <td>550</td>
            <td>100</td>
            <td>0,700</td>
          </tr>
          <tr>
            <td>ASC PK 0192-8</td>
            <td>192</td>
            <td>650</td>
            <td>100</td>
            <td>0,800</td>
          </tr>
          <tr>
            <td>ASC PK 0235-8</td>
            <td>235</td>
            <td>750</td>
            <td>100</td>
            <td>0,900</td>
          </tr>
          <tr>
            <td>ASC PK 0260-9</td>
            <td>260</td>
            <td>900</td>
            <td>100</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>ASC PK 0342-9</td>
            <td>342</td>
            <td>950</td>
            <td>100</td>
            <td>2,067</td>
          </tr>
          <tr>
            <td>ASC PK 0350-9</td>
            <td>350</td>
            <td>1000</td>
            <td>100</td>
            <td>3,200</td>
          </tr>
          <tr>
            <td>ASC PK 0364-9</td>
            <td>364</td>
            <td>1200</td>
            <td>100</td>
            <td>3,300</td>
          </tr>
        </table>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=75"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default AdjustablePlasticClamp;
