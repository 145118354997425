import React from "react";
import FixedImage from "../components/FixedImage";
import Footer from "../components/Footer";
import Global from "../components/Global";
import ImageSlider from "../components/ImageSlider";
import Navbar from "../components/Navbar";
import Products from "../components/Products";
import Map from "../components/Map";
import AnimatedPage from "../components/AnimatedPage";
import FancyBox from "../components/FancyBox";

const Home = () => {
  return (
    <>
      <Navbar />
      <AnimatedPage>
        <ImageSlider />
        <Products />
        <Global />
        <FixedImage />
        <Map />
        <Footer />
      </AnimatedPage>
    </>
  );
};

export default Home;
