import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_7x100-lastikli.jpg";
import Second from "../../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_7x100_2lastikli.jpg";
import Third from "../../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_7x100_teknik.jpg";
import Forth from "../../assets/img/pipe-clamps/ascfixing_pipe_clamp_with_triphone_uygula.jpg";
import Fifth from "../../assets/img/pipe-clamps/ascfixing_pipe_clamp_kapak_teknik.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const PipeClampWithTriphone7x100 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr9")}
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3  container mx-auto">
        <div
          className="w-full md:w-[100%]"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>

        <div
          className="w-full md:w-[100%]"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={Forth} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme min-[640px]:w-[650px] min-[1024px]:w-[100%]">
          <div className="font-semibold text-3xl text-[#1D4ED8] w-[85%]">
            <h1>{t("pr9")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">
              {t("application")}
            </h3>

            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsa1")}</span>
              </li>

              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsa2")}</span>
              </li>
            </ul>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">{t("features")}</h3>
            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf9")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf2")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf3")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">
                  {t("nsf4")} {t("nsf8")}
                </span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf5")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf6")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf7")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Dimensions</td>
              <td></td>
              <td>Thickness X Width</td>
              <td>Side Screws</td>
              <td>Triphone</td>
              <td>Quantity Per Boxes</td>
              <td>Weight Per Box</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td> (mm)</td>
              <td>(inch)</td>
              <td>TXB (mm)</td>
              <td>R mm</td>
              <td>S mm</td>
              <td>Pcs/box</td>
              <td>(kg)</td>
            </tr>
            <tr>
              <td>ASC 0015 UT</td>
              <td>13 - 20</td>
              <td>1/4 &quot;</td>
              <td>1,00 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>150</td>
              <td>8.70</td>
            </tr>
            <tr>
              <td>ASC 0018 UT</td>
              <td>17 - 23</td>
              <td>3/8 &quot;</td>
              <td>1,00 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>150</td>
              <td>8.70</td>
            </tr>
            <tr>
              <td>ASC 0020 UT</td>
              <td>21 - 26</td>
              <td>1/2 &quot;</td>
              <td>1,00 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>150</td>
              <td>8.70</td>
            </tr>
            <tr>
              <td>ASC 0025 UT</td>
              <td>26 - 30</td>
              <td>3/4 &quot;</td>
              <td>1,00 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>150</td>
              <td>8.80</td>
            </tr>
            <tr>
              <td>ASC 0032 UT</td>
              <td>33 - 37</td>
              <td>1 &quot;</td>
              <td>1,00 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>100</td>
              <td>8.90</td>
            </tr>
            <tr>
              <td>ASC 0040 UT</td>
              <td>40 - 46</td>
              <td>1 1/4 &quot;</td>
              <td>1,20 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>75</td>
              <td>6.30</td>
            </tr>
            <tr>
              <td>ASC 0048 UT</td>
              <td>48 - 53</td>
              <td>1 1/2 &quot;;</td>
              <td>1,20 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>75</td>
              <td>7.10</td>
            </tr>
            <tr>
              <td>ASC 0054 UT</td>
              <td>53 - 59</td>
              <td>53 - 59</td>
              <td>1,20 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>50</td>
              <td>4.70</td>
            </tr>
            <tr>
              <td>ASC 0056 UT</td>
              <td>60 - 66</td>
              <td>2 &quot;</td>
              <td>1,20 X 20</td>
              <td>M5X15</td>
              <td>7 X 100</td>
              <td>50</td>
              <td>4.70</td>
            </tr>
            <tr>
              <td>ASC 0067 UT</td>
              <td>67 - 77</td>
              <td>67 - 77</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>40</td>
              <td>6.80</td>
            </tr>
            <tr>
              <td>ASC 0075 UT</td>
              <td>75 - 84</td>
              <td> 1/2 &quot;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>40</td>
              <td>6.80</td>
            </tr>
            <tr>
              <td>ASC 0083 UT</td>
              <td>83 - 93</td>
              <td>83 - 93</td>
              <td>1,50 X 20</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>30</td>
              <td>5.80</td>
            </tr>
            <tr>
              <td>ASC 0086 UT</td>
              <td>87 - 96</td>
              <td>3 &quot;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>30</td>
              <td>5.80</td>
            </tr>
            <tr>
              <td>ASC 0097 UT</td>
              <td>94 - 104</td>
              <td>3 1/2 &quot;;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>30</td>
              <td>7.80</td>
            </tr>
            <tr>
              <td>ASC 0101 UT</td>
              <td>109 - 119</td>
              <td>4 &quot;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>30</td>
              <td>8.10</td>
            </tr>
            <tr>
              <td>ASC 0123 UT</td>
              <td>118 - 125</td>
              <td>4 1/2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>25</td>
              <td>7.40</td>
            </tr>
            <tr>
              <td>ASC 0138 UT</td>
              <td>135 - 146</td>
              <td>5 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>20</td>
              <td>6.50</td>
            </tr>
            <tr>
              <td>ASC 0160 UT</td>
              <td>150 - 162</td>
              <td>5 1/2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>20</td>
              <td>7.01</td>
            </tr>
            <tr>
              <td>ASC 0166 UT</td>
              <td>158 - 169</td>
              <td>6 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>20</td>
              <td>6.60</td>
            </tr>
            <tr>
              <td>ASC 0195 UT</td>
              <td>197 - 207</td>
              <td>7 &#39;&#39;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>15</td>
              <td>8.70</td>
            </tr>
            <tr>
              <td>ASC 0216 UT</td>
              <td>216 - 224</td>
              <td>8 &#39;&#39;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>10</td>
              <td>9.02</td>
            </tr>
            <tr>
              <td>ASC 0270 UT</td>
              <td>269 - 275</td>
              <td>10 &#39;&#39;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7 X 100</td>
              <td>7</td>
              <td>8.60</td>
            </tr>
          </table>
        </div>
        <div>
          <table className="h-20">
            <tr className="table-tr">
              <td>Size</td>
              <td>BREAKING LOAD</td>
              <td>RECOMMENDED LOAD</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td>(KN)</td>
              <td>(KN)</td>
            </tr>
            <tr>
              <td>1/4&#39;- 1&#39;</td>
              <td>3.9</td>
              <td>1.3</td>
            </tr>
            <tr>
              <td>11/4&#39;- 2&#39;</td>
              <td>4.8</td>
              <td>1.6</td>
            </tr>
            <tr>
              <td>3&#39;- 4&#39;</td>
              <td>6.9</td>
              <td>2.3</td>
            </tr>
            <tr>
              <td>41/2&#39;- 10&#39;</td>
              <td>7.8</td>
              <td>2.6</td>
            </tr>
          </table>
          <FancyBox>
            <div className="image object-cover mt-3">
              <img src={Third} data-FancyBox alt="" className="w-[260px] cursor-pointer drop-shadow-2xl" />
            </div>
          </FancyBox>
          <FancyBox>
            <div className="image object-cover mt-3">
              <img src={Fifth} data-FancyBox alt="" className="w-[260px] cursor-pointer drop-shadow-2xl" />
            </div>
          </FancyBox>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=13"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div >
  );
};

export default PipeClampWithTriphone7x100;
