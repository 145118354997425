import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_1.jpg";
import Second from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_2.jpg";
import Third from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_teknik.jpg";
import Forth from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_uygulama_1.png";
import Fifth from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_uygulama_2.png";
import Sixth from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_uygulama_3.png";
import Seventh from "../../assets/img/other-fittings/ascfixing_slide_nut_tb3_uygulama_4.png";
import Eighth from "../../assets/img/other-fittings/hareket1 (1).png";
import Ninth from "../../assets/img/other-fittings/hareket1 (2).png";
import Tenth from "../../assets/img/other-fittings/hareket1 (3).png";
import Eleventh from "../../assets/img/other-fittings/hareket1 (4).png";
import Twelfth from "../../assets/img/other-fittings/hareket1 (5).png";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const SlideNudeTb3 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of11")}
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3  container mx-auto">
        <div
          className="w-full md:w-[100%]"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>

        <div
          className="w-full md:w-[100%]"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={Fifth} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Forth} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of11")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto mb-10 min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Size</td>
              <td>S (mm)</td>
              <td>T (max) (Nm)</td>
              <td>For Rail</td>
              <td>Fa,z (N)</td>
              <td>Fa,x (N)</td>
            </tr>
            <tr>
              <td>ASC TB3 M8</td>
              <td>M8</td>
              <td>8</td>
              <td>11</td>
              <td>G Profile</td>
              <td>3,120</td>
              <td>1,100</td>
            </tr>
            <tr>
              <td>ASC TB3 M10</td>
              <td>M10</td>
              <td>8</td>
              <td>15</td>
              <td>G Profile</td>
              <td>5,000</td>
              <td>1,250</td>
            </tr>
          </table>
        </div>
        <FancyBox>
          <div>
            <Link className="image object-cover my-3">
              <img data-fancybox src={Third} alt="" className="h-[250px] drop-shadow-2xl mb-3" />
            </Link>
          </div>
        </FancyBox>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=55"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default SlideNudeTb3;
