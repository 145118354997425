import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing insulation_block.jpg";
import Second from "../../assets/img/pipe-clamps/ascfixing insulation_block_2.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const IsolationBlock = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr27")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("pr27")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">
              {t("application")}
            </h3>
            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs18")}</span>
              </li>
            </ul>
          </div>
          {/* <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('features')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nfs21')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('n2')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf6')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf7')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nfs22')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('n1')}
                        </span>
                    </li>
                </ul>
            </div> */}
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>INCH</td>
              <td>DIMENSION TO BE USED</td>
              <td>Size</td>
              <td>BREAKING LOAD</td>
              <td>RECOMMENDED LOAD</td>
            </tr>
            <tr className="table-tr">
              <td>CORRESPONDING SIZE</td>
              <td></td>
              <td></td>
              <td>(KN)</td>
              <td>(KN)</td>
            </tr>
            <tr>
              <td>1/2&#39;</td>
              <td>2&quot;</td>
              <td>1/2&#39;-1&#39;</td>
              <td>14</td>
              <td>1.5</td>
            </tr>
            <tr>
              <td>3/4&#39;</td>
              <td>67 - 73</td>
              <td>21/2&#39;-4&#39;</td>
              <td>21</td>
              <td>3.9</td>
            </tr>
            <tr>
              <td>1&#39;</td>
              <td>67 -73</td>
              <td>5&#39;-6&#39;</td>
              <td>25</td>
              <td>7.5</td>
            </tr>
            <tr>
              <td>11/4&#39;</td>
              <td>83 - 93</td>
              <td>8&#39;-10&#39;</td>
              <td>40</td>
              <td>13</td>
            </tr>
            <tr>
              <td>11/2&#39;</td>
              <td>3&#39;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>2&#39;</td>
              <td>94 - 104</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>21/2&#39;</td>
              <td>5&#39;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>3&#39;</td>
              <td>5&#39;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>4&#39;</td>
              <td>6&quot;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>5&#39;</td>
              <td>196 - 209</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>6&#39;</td>
              <td>8&#39;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>8&quot;</td>
              <td>10&#39;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=34"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default IsolationBlock;
