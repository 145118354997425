import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing_u_bolt.jpg";
import Second from "../../assets/img/pipe-clamps/ascfixing_u_bolt_teknik.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const UBolt = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr28")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("pr28")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          {/* <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('application')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nfs18')}
                        </span>
                    </li>
                </ul>
            </div>
             <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('features')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nfs21')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('n2')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf6')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf7')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nfs22')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('n1')}
                        </span>
                    </li>
                </ul>
            </div> */}
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Dimensions</td>
              <td></td>
              <td>D</td>
              <td>L</td>
              <td>k</td>
              <td>G</td>
              <td>H</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td> (mm)</td>
              <td>(inch)</td>
              <td>(mm)</td>
              <td>(mm)</td>
              <td>(mm)</td>
              <td>(mm)</td>
              <td>(mm)</td>
            </tr>
            <tr>
              <td>ASC 0015 UB</td>
              <td>15</td>
              <td>&quot;1/2 &quot;&quot;&quot;</td>
              <td>21.5</td>
              <td>25</td>
              <td>28</td>
              <td>M6</td>
              <td>48</td>
            </tr>
            <tr>
              <td>ASC 0020 UB</td>
              <td>20</td>
              <td>&quot;3/4 &quot;&quot;&quot;</td>
              <td>26.9</td>
              <td>30</td>
              <td>35</td>
              <td>M6</td>
              <td>63</td>
            </tr>
            <tr>
              <td>ASC 0025 UB</td>
              <td>25</td>
              <td>&quot;1 &quot;&quot;&quot;</td>
              <td>33.7</td>
              <td>30</td>
              <td>42</td>
              <td>M8</td>
              <td>71</td>
            </tr>
            <tr>
              <td>ASC 0032 UB</td>
              <td>32</td>
              <td>&quot;1 1/4 &quot;&quot;&quot;</td>
              <td>42.4</td>
              <td>30</td>
              <td>51</td>
              <td>M8</td>
              <td>79</td>
            </tr>
            <tr>
              <td>ASC 0040 UB</td>
              <td>40</td>
              <td>&quot;1 1/2 &quot;&quot;&quot;</td>
              <td>48.3</td>
              <td>30</td>
              <td>57</td>
              <td>M8</td>
              <td>80</td>
            </tr>
            <tr>
              <td>ASC 0050 UB</td>
              <td>50</td>
              <td>&quot;2 &quot;&quot;&quot;</td>
              <td>60.3</td>
              <td>30</td>
              <td>57</td>
              <td>M10</td>
              <td>92</td>
            </tr>
            <tr>
              <td>ASC 0065 UB</td>
              <td>65</td>
              <td>&quot;2 1/2&quot;&quot;&quot;</td>
              <td>76.1</td>
              <td>30</td>
              <td>69</td>
              <td>M10</td>
              <td>108</td>
            </tr>
            <tr>
              <td>ASC 0080 UB</td>
              <td>80</td>
              <td>&quot;3 &quot;&quot;&quot;</td>
              <td>88.9</td>
              <td>40</td>
              <td>99</td>
              <td>M12</td>
              <td>131</td>
            </tr>
            <tr>
              <td>ASC 0100 UB</td>
              <td>100</td>
              <td>&quot;4 &quot;&quot;&quot;</td>
              <td>114.3</td>
              <td>50</td>
              <td>127</td>
              <td>M12</td>
              <td>167</td>
            </tr>
            <tr>
              <td>ASC 0125 UB</td>
              <td>125</td>
              <td>&quot;5 &quot;&quot;&quot;</td>
              <td>139.7</td>
              <td>50</td>
              <td>152</td>
              <td>M12</td>
              <td>191</td>
            </tr>
            <tr>
              <td>ASC 0150 UB</td>
              <td>150</td>
              <td>&quot;6 &quot;&quot;&quot;</td>
              <td>168.3</td>
              <td>50</td>
              <td>180</td>
              <td>M16</td>
              <td>219</td>
            </tr>
            <tr>
              <td>ASC 0200 UB</td>
              <td>200</td>
              <td>&quot;8 &quot;&quot;&quot;</td>
              <td>219.1</td>
              <td>60</td>
              <td>236</td>
              <td>M16</td>
              <td>282</td>
            </tr>
            <tr>
              <td>ASC 0250 UB</td>
              <td>250</td>
              <td>&quot;10 &quot;&quot;&quot;</td>
              <td>273.0</td>
              <td>60</td>
              <td>290</td>
              <td>M16</td>
              <td>336</td>
            </tr>
            <tr>
              <td>ASC 0270 UB</td>
              <td>300</td>
              <td>&quot;12 &quot;&quot;&quot;</td>
              <td>380</td>
              <td>100</td>
              <td>328</td>
              <td>M16</td>
              <td>380</td>
            </tr>
          </table>
        </div>
        <div>
          <FancyBox>
            <div className="image object-cover">
              <img src={Second} data-FancyBox alt="" className="w-[250px] cursor-pointer drop-shadow-2xl" />
            </div>
          </FancyBox>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=36"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default UBolt;
