import React from "react";
import Navbar from "../../components/Navbar";
import Fuar1 from "../../assets/fuar.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Footer from "../../components/Footer";
import FixedImage from "../../components/FixedImage";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}


const Fair = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- mt-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('news')}</h1>
        <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">{t('fair')}</h2>
      </div>
      <div className="mt-8" data-aos="fade-up" data-aos-duration="3000">
        <div className="w-full md:w-11/12 lg:w-4/5 m-auto flex flex-col md:flex-row">
          <div className="mt-[52px] w-full">
            <div className="border-b py-3 px-2 md:px-6 mb-4 hover:bg-white hover:shadow hover:border-none hover:rounded-lg flex flex-col md:flex-row gap-x-5">
              <img src={Fuar1} alt="" className="w-full md:w-60" />
              <div className="w-full">
                <div className="flex flex-col md:flex-row justify-between">
                  <h1 className="font-bold mb-2 mt-2 md:mt-0">
                    ASC Fixing Bağlantı Belgesini Güncelledi.
                  </h1>
                  <span className="text-[#a8a7a7] text-sm mb-2">
                    15 Ağustos 2018
                  </span>
                </div>
                <p className="text-sm">
                  Asc Fixing, 9001:2008 ISO Belgesini ISO 9001:2015 olarak
                  yenilemiştir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default Fair;
