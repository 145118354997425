import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import AnimatedPage from "../components/AnimatedPage";

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}
const News = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center my-[100px]">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('news')}</h1>
        </div>
        <div className="container m-auto flex flex-col md:flex-row justify-evenly mt-10 gap-8 px-5 mb-24">
          <div className="box-border w-full md:w-2/5 h-80 overflow-hidden" data-aos="zoom-in">
            <Link to="/news/fair">
              <div className="bg-no-repeat bg-center bg-cover bg-fair-bg w-full h-full hover:scale-110 duration-500">
                <div className="bg-[#0000008a] w-full h-full">
                  <h1 className="relative text-4xl text-white/80 top-[135px] font-bold text-center">
                    {t('fair')}
                  </h1>
                </div>
              </div>
            </Link>
          </div>
          <div className="box-border w-full md:w-2/5 h-80 overflow-hidden" data-aos="zoom-in">
            <Link to="/news/announcements">
              <div className="bg-no-repeat bg-center bg-cover bg-announcement-bg w-full h-full hover:scale-110 duration-500">
                <div className="bg-[#0000008a] w-full h-full">
                  <h1 className="relative text-4xl text-white/80 top-[135px] font-bold text-center">
                    {t('announcements')}
                  </h1>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </AnimatedPage>
    </div>
  );
};

export default News;
