import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/other-fittings/ascfixing_collector_box_with_console.png";

import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const CollectorBoxWithConsole = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/other-fittings">{t("otherfittings")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("of50")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("of50")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] w-[45%] ml-[20px] mb-10 md:ml-[200px]">
        <h1>{t('uruntablosu')}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
      </div>
      <div className="overflow-x-auto mb-10 md:ml-[200px] mx-4">
        <table className="w-[50%]">
          <tr className="table-tr">
            <td>Product Code</td>
            <td>Dimensions</td>
            <td></td>
          </tr>
          <tr className="table-tr">
            <td></td>
            <td>Width (mm)</td>
            <td>Lenght (mm)</td>
          </tr>
          <tr>
            <td>ASC KD 4060</td>
            <td>40</td>
            <td>60</td>
          </tr>
          <tr>
            <td>ASC KD 6060</td>
            <td>60</td>
            <td>60</td>
          </tr>
          <tr>
            <td>ASC KD 8060</td>
            <td>80</td>
            <td>60</td>
          </tr>
          <tr>
            <td>ASC KD 1060</td>
            <td>100</td>
            <td>60</td>
          </tr>
        </table>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=74"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default CollectorBoxWithConsole;
