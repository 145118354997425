import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_triphone_vertical.jpg";
import Second from "../../assets/img/pipe-clamps/ascfixing_silent_pipe_clamp_with_triphone_vertical_2.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const SlientPipeClampWithTriphoneVertical = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr20")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("pr20")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">
              {t("application")}
            </h3>
            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs13")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs14")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs15")}</span>
              </li>
            </ul>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">{t("features")}</h3>
            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf2")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">
                  {t("nsf4")} {t("nsf8")}
                </span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("n2")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs16")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs17")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nfs18")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Dimensions</td>
              <td></td>
              <td>Thickness X Width</td>
              <td>Side Screws</td>
              <td>Triphone</td>
              <td>Quantity Per Boxes</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td> (mm)</td>
              <td>(inch)</td>
              <td>TXB (mm)</td>
              <td>R (mm)</td>
              <td>(mm)</td>
              <td>Pcs/box</td>
            </tr>
            <tr>
              <td>ASC 0056 TD</td>
              <td>60 - 66</td>
              <td>2 &quot;</td>
              <td>1,20 X 20</td>
              <td>M5X15</td>
              <td>7X70</td>
              <td>25</td>
            </tr>
            <tr>
              <td>ASC 0075 TD</td>
              <td>75 - 84</td>
              <td>2 1/2 &quot;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>20</td>
            </tr>
            <tr>
              <td>ASC 0086 TD</td>
              <td>87 - 96</td>
              <td>3 &quot;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>15</td>
            </tr>
            <tr>
              <td>ASC 0110 TD</td>
              <td>109 - 119</td>
              <td>4 &quot;</td>
              <td>1,50 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>15</td>
            </tr>
            <tr>
              <td>ASC 0125 TD</td>
              <td>118 - 125</td>
              <td>4 1/2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>15</td>
            </tr>
            <tr>
              <td>ASC 0138 TD</td>
              <td>135 - 146</td>
              <td>5 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>10</td>
            </tr>
            <tr>
              <td>ASC 0160 TD</td>
              <td>150 - 162</td>
              <td>5 1/2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>10</td>
            </tr>
            <tr>
              <td>ASC 0166 TD</td>
              <td>158 - 169</td>
              <td>6 &quot;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>10</td>
            </tr>
            <tr>
              <td>ASC 0195 TD</td>
              <td>197 - 207</td>
              <td>7 &#39;&#39;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>7</td>
            </tr>
            <tr>
              <td>ASC 0216 TD</td>
              <td>216 - 224</td>
              <td>8 &#39;&#39;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>7</td>
            </tr>
            <tr>
              <td>ASC 0270 TD</td>
              <td>269 - 275</td>
              <td>10 &#39;&#39;</td>
              <td>2,00 X 25</td>
              <td>M6X30</td>
              <td>7X70</td>
              <td>5</td>
            </tr>
          </table>
        </div>
        <div className="">
          <table className="h-20">
            <tr className="table-tr">
              <td>Size</td>
              <td>BREAKING LOAD</td>
              <td>RECOMMENDED LOAD</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td>(KN)</td>
              <td>(KN)</td>
            </tr>
            <tr>
              <td>Ø50</td>
              <td>4.8</td>
              <td>1.6</td>
            </tr>
            <tr>
              <td>Ø75</td>
              <td>6.9</td>
              <td>2.3</td>
            </tr>
            <tr>
              <td>Ø110</td>
              <td>6.9</td>
              <td>2.3</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=21"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default SlientPipeClampWithTriphoneVertical;
