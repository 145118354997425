import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing_heavy_duty_pipe_clamp_headed_1.jpg";
import Second from "../../assets/img/pipe-clamps/ascfixing_heavy_duty_pipe_clamp_headed_2.jpg";
import Third from "../../assets/img/pipe-clamps/ascfixingascfixing_heavy_duty_pipe_clamp_headed_teknik.jpg";
import Forth from "../../assets/img/pipe-clamps/ascfixing_heavy_duty_pipe_clamp_headed_.jpg";
import Fifth from "../../assets/img/pipe-clamps/ascfixing_heavy_duty_pipe_clamp_headed_uygulama 1.jpg";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import FancyBox from "../../components/FancyBox";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};
const HeavyDutyHeaded = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr11")}
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3  container mx-auto">
        <div
          className="w-full md:w-[100%]"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>

        <div
          className="w-full md:w-[100%]"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={Fifth} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme min-[640px]:w-[650px] min-[1024px]:w-[100%]">
          <div className="font-semibold sm:text-3xl text-[#1D4ED8] w-[85%]">
            <h1>{t("pr11")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">
              {t("application")}
            </h3>

            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsa1")}</span>
              </li>

              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsa2")}</span>
              </li>
            </ul>
          </div>
          <div className="text-md mt-5 p-2">
            <h3 className="text-[#1d4fd8cf] mb-3 font-bold">{t("features")}</h3>
            <ul className="text-sm">
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf2")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">
                  {t("nsf4")} {t("nsf8")}
                </span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("n2")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf10")}</span>
              </li>
              <li className="flex pb-2">
                <AiOutlineArrowRight className="mt-1" />
                <span className="ml-2">{t("nsf11")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Dimensions</td>
              <td></td>
              <td>Thickness X Width</td>
              <td>Side Screws</td>
              <td>Head</td>
              <td>Quantity Per Box</td>
              <td>Weight Per Box</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td> (mm)</td>
              <td>(inch)</td>
              <td>TXB (mm)</td>
              <td>R (mm)</td>
              <td>SXE(mm)</td>
              <td>Pcs/box</td>
              <td>(kg)</td>
            </tr>
            <tr>
              <td>ASC 0032 AK</td>
              <td>32 - 36</td>
              <td>1 &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>10,5x14</td>
              <td>60</td>
              <td>10.99</td>
            </tr>
            <tr>
              <td>ASC 0039 AK</td>
              <td>41 -45</td>
              <td>1 1/4 &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>10,5x14</td>
              <td>50</td>
              <td>9.76</td>
            </tr>
            <tr>
              <td>ASC 0048 AK</td>
              <td>49 - 53</td>
              <td>1 1/2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>10,5x14</td>
              <td>40</td>
              <td>8.59</td>
            </tr>
            <tr>
              <td>ASC 0059 AK</td>
              <td>59 - 65</td>
              <td>2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>10,5x14</td>
              <td>40</td>
              <td>9.57</td>
            </tr>
            <tr>
              <td>ASC 0074 AK</td>
              <td>75 - 83</td>
              <td>2 1/2 &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>10,5x14</td>
              <td>40</td>
              <td>10.36</td>
            </tr>
            <tr>
              <td>ASC 0087 AK</td>
              <td>87 - 95</td>
              <td>3 &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>10,5x14</td>
              <td>30</td>
              <td>8.67</td>
            </tr>
            <tr>
              <td>ASC 0108 AK</td>
              <td>108 - 118</td>
              <td>4 &quot;</td>
              <td>2,50 X 30</td>
              <td>M8 X 30</td>
              <td>12x14</td>
              <td>20</td>
              <td>10.58</td>
            </tr>
            <tr>
              <td>ASC 0135 AK</td>
              <td>134 - 147</td>
              <td>5 &quot;</td>
              <td>3,00 X 30</td>
              <td>M10 X 45</td>
              <td>12x14</td>
              <td>20</td>
              <td>12.25</td>
            </tr>
            <tr>
              <td>ASC 0162 AK</td>
              <td>157 - 169</td>
              <td>6 &quot;</td>
              <td>3,00 X 30</td>
              <td>M10 X 45</td>
              <td>12x14</td>
              <td>15</td>
              <td>9.95</td>
            </tr>
            <tr>
              <td>ASC 0206 AK</td>
              <td>211 - 226</td>
              <td>8 &quot;</td>
              <td>4,00 X 30</td>
              <td>M10 X 45</td>
              <td>12x14</td>
              <td>10</td>
              <td>10.63</td>
            </tr>
            <tr>
              <td>ASC 0260 AK</td>
              <td>265 - 273</td>
              <td>10 &quot;;</td>
              <td>4,00 X 30</td>
              <td>M10 X 45</td>
              <td>12x14</td>
              <td>7</td>
              <td>9.40</td>
            </tr>
          </table>
        </div>
        <div className="">
          <table className="h-20">
            <tr className="table-tr">
              <td>Size</td>
              <td>BREAKING LOAD</td>
              <td>RECOMMENDED LOAD</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td>(KN)</td>
              <td>(KN)</td>
            </tr>
            <tr>
              <td>1&#39; - 3&#39;</td>
              <td>10</td>
              <td>2.75</td>
            </tr>
            <tr>
              <td>4&quot;</td>
              <td>14</td>
              <td>3.75</td>
            </tr>
            <tr>
              <td>41/2&#39;&#39; - 10&#39;&#39;</td>
              <td>20</td>
              <td>6</td>
            </tr>
          </table>
          <FancyBox>
            <div className="image object-cover mt-3">
              <img src={Third} data-FancyBox alt="" className="w-[265px] cursor-pointer drop-shadow-2xl" />
            </div>
          </FancyBox>
          <FancyBox>
            <div className="image object-cover mt-3">
              <img src={Forth} data-FancyBox alt="" className="w-[265px] cursor-pointer drop-shadow-2xl" />
            </div>
          </FancyBox>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=14"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default HeavyDutyHeaded;
