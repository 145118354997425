import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";
import FixedImage from "../components/FixedImage";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useState } from "react";





const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const Search = (props) => {
  const [search, setSearch] = useState("");

  const getValue = (event) => {
    setSearch(event.target.value);
  };
  const navigate = useNavigate();
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate('/search/' + search);
    }
  };


  const { id } = useParams();
  const { t, i18n } = useTranslation();

  if (i18n.language == "tr") {
    var json = require('../data_tr.json');
  } else if (i18n.language == "it") {
    var json = require('../data_it.json');
  } else if (i18n.language == "ar") {
    var json = require('../data_ar.json');
  } else if (i18n.language == "uk") {
    var json = require('../data_uk.json');
  } else if (i18n.language == "es") {
    var json = require('../data_es.json');
  } else if (i18n.language == "fr") {
    var json = require('../data_fr.json');
  } else if (i18n.language == "en") {
    var json = require('../data_en.json');
  } else {
    var json = require('../data_en.json');
  }


  if (json.filter((p) => p.title.toLowerCase().includes(id)).length > 0) {
    return (
      <div>
        <Navbar />
        <AnimatedPage>
          <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
            <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
              {t("products")}
            </h1>
            <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
              {t("profileconsole")}
            </h2>
          </div>
          <div
            className="max-w-[1140px] m-auto p-4 "
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
              {/* Ürünler Başlangıcı */}
              {
                json
                  .filter((p) => p.title.toLowerCase().includes(id))
                  .map((p, i) => {
                    return (
                      <div className="bg-white shadow-xl p-4 group" key={i}>
                        <Link to={"../" + p.url}>
                          <div className="overflow-hidden">
                            <img
                              src={p.image}
                              alt=""
                              className="group-hover:scale-110 duration-500"
                            />
                          </div>
                          <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                            {p.title}

                          </h1>
                        </Link>

                      </div>
                    );
                  })}

              {/* Ürünler Sonu */}
            </div>
          </div>
          <FixedImage />
          <Footer />
        </AnimatedPage>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <AnimatedPage>
          <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
            <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
              {t("searchresults")}
            </h1>
          </div>
          <div
            className="max-w-[1140px] m-auto p-4 "
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            {/* Ürünler Başlangıcı */}
            <div className="w-full flex flex-col items-center gap-y-6 mb-20 ">
              <h1 className="text-lg font-bold">{t("searchnotfound")}</h1>
              <input type="text" placeholder={t("searchplaceholder")} className="w-full md:w-[50%] h-12 px-6 bg-white border" onKeyDown={handleKeyPress} onChange={getValue} />

            </div>
            {/* Ürünler Sonu */}

          </div>
          <FixedImage />
          <Footer />
        </AnimatedPage>
      </div>
    );
  }





};

export default Search;
