import React from "react";
import Navbar from "../../components/Navbar";
import FixedImage from "../../components/FixedImage";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer from "../../components/Footer";
import First from "../../assets/img/pipe-clamps/ascfixing_platform_hevy_duty_pipe_clamp_2.PNG";
import Second from "../../assets/img/pipe-clamps/ascfixing_platform_hevy_duty_pipe_clamp.png";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const clickHandle = (lang) => {
  i18n.changeLanguage(lang);
};

const PlatformHeavyDutyClamp = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
        <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">
          {t("products")}
        </h1>
        <div className="flex justify-center items-center">
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            <Link to="/products/pipe-clamps">{t("footerpipeclamps")}</Link>
          </h2>
          <BsArrowRightSquareFill className="mx-3 text-gray-600" size={25} />
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">
            {t("pr13")}
          </h2>
        </div>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto">
        <div
          className="w-[90%] md:w-[50%] ml-4"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="shadow-lg mb-10 rounded-lg">
            <Carousel infiniteLoop autoPlay>
              <div className="image object-contain">
                <img src={First} alt="" />
              </div>
              <div className="image object-contain">
                <img src={Second} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="px-5 deneme">
          <div className="font-semibold text-3xl text-[#1D4ED8]">
            <h1>{t("pr13")}</h1>
            <div className="border-2 border-[#1D4ED8]/80 mt-4"></div>
          </div>
          {/* <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('application')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsa1')}
                        </span>
                    </li>

                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsa2')}</span>
                    </li>
                </ul>
            </div>
            <div className='text-md mt-5 p-2'>
                <h3 className='text-[#1d4fd8cf] mb-3 font-bold'>{t('features')}</h3>
                <ul className='text-sm'>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf2')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf4')} {t('nsf8')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('n2')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf10')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf11')}
                        </span>
                    </li>
                    <li className='flex pb-2'><AiOutlineArrowRight className='mt-1' />
                        <span className='ml-2'>{t('nsf12')}
                        </span>
                    </li>
                </ul>
            </div> */}
        </div>
      </div>
      <div className="font-semibold text-3xl text-[#1D4ED8] container mx-auto mb-10 px-4">
        <h1>{t("uruntablosu")}</h1>
        <div className="border-2 border-[#1D4ED8]/80 mt-4 w-[15%]"></div>
      </div>
      <div className="overflow-x-auto min-[768px]:block min-[1280px]:grid grid-cols-1 gap-10 sm:grid-cols-2 container mx-auto pl-3">
        <div className="mb-10">
          <table className="w-full">
            <tr className="table-tr">
              <td>Product Code</td>
              <td>Dimensions</td>
              <td></td>
              <td>Thickness X Width</td>
              <td>Side Screws</td>
              <td>Quantity Per Box</td>
            </tr>
            <tr className="table-tr">
              <td></td>
              <td> (mm)</td>
              <td>(inch)</td>
              <td>TXB (mm)</td>
              <td>R  (mm)</td>
              <td>Pcs/box</td>
            </tr>
            <tr>
              <td>ASC 0032 PH</td>
              <td>32 - 36</td>
              <td>&quot;1 &quot;&quot;&quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>60</td>
            </tr>
            <tr>
              <td>ASC 0039 PH</td>
              <td>41 -45</td>
              <td>&quot;1 1/4 &quot;&quot;&quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>50</td>
            </tr>
            <tr>
              <td>ASC 0048 PH</td>
              <td>49 - 53</td>
              <td>&quot;1 1/2 &quot;&quot;&quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>40</td>
            </tr>
            <tr>
              <td>ASC 0059 PH</td>
              <td>59 - 65</td>
              <td>&quot;2 &quot;&quot;&quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>40</td>
            </tr>
            <tr>
              <td>ASC 0074 PH</td>
              <td>75 - 83</td>
              <td>&quot;2 1/2 &quot;&quot;&quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>40</td>
            </tr>
            <tr>
              <td>ASC 0087 PH</td>
              <td>87 - 95</td>
              <td>&quot;3 &quot;&quot; &quot;</td>
              <td>2,00 X 25</td>
              <td>M8 X 30</td>
              <td>30</td>
            </tr>
            <tr>
              <td>ASC 0108 PH</td>
              <td>108 - 118</td>
              <td>&quot;4 &quot;&quot; &quot;</td>
              <td>2,50 X 30</td>
              <td>M8 X 30</td>
              <td>20</td>
            </tr>
            <tr>
              <td>ASC 0135 PH</td>
              <td>134 - 147</td>
              <td>&quot;5 &quot;&quot;&quot;</td>
              <td>3,00 X 30</td>
              <td>M10 X 45</td>
              <td>20</td>
            </tr>
            <tr>
              <td>ASC 0162 PH</td>
              <td>157 - 169</td>
              <td>&quot;6 &quot;&quot;&quot;</td>
              <td>3,00 X 30</td>
              <td>M10 X 45</td>
              <td>15</td>
            </tr>
            <tr>
              <td>ASC 0206 PH</td>
              <td>211 - 226</td>
              <td>&quot;8 &quot;&quot;&quot;</td>
              <td>4,00 X 30</td>
              <td>M10 X 45</td>
              <td>10</td>
            </tr>
            <tr>
              <td>ASC 0260 PH</td>
              <td>265 - 273</td>
              <td>&quot;10 &quot;&quot;&quot;</td>
              <td>4,00 X 30</td>
              <td>M10 X 45</td>
              <td>7</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="text-[#1D4ED8] container mx-auto mb-5">
        <NavLink
          to="https://ascfixing.com/general-clamp-fixing-system.pdf#page=35"
          target="_blank"
        >
          {t("pdf")}
        </NavLink>
      </div>
      <FixedImage />
      <Footer />
    </div>
  );
};

export default PlatformHeavyDutyClamp;
