import React from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import FixedImage from "../components/FixedImage";
import SteelRail from "../assets/img/profile-console/ascfixing_steel_rail_1.jpg";
import CProfile from "../assets/img/profile-console/ascfixing_c_profile_1.jpg";
import GProfile from "../assets/img/profile-console/ascfixing_g_profile_1.jpg";
import LProfile from "../assets/img/profile-console/ascfixing_l_profile.jpg";
import UProfile from "../assets/img/profile-console/ascfixing_u_profile.jpg";
import CRailConsole from "../assets/img/profile-console/ascfixing_c_rail_console_1.jpg";
import GRailConsole from "../assets/img/profile-console/ascfixing_g_rail_console.jpg";
import LRailConsole from "../assets/img/profile-console/ascfixing_l_rail_console.jpg";
import URailConsole from "../assets/img/profile-console/ascfixing_u_rail_console.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Link } from 'react-router-dom';
import AnimatedPage from '../components/AnimatedPage';

const clickHandle = lang => {
  i18n.changeLanguage(lang)
}


const ProfileConsole = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Navbar />
      <AnimatedPage>
        <div className="w-full h-[340px] bg-[url('assets/arkaplan.jpg')] bg-center max-md:bg- my-[100px]">
          <h1 className="flex justify-center text-3xl text-gray-700 font-bold md:text-6xl pt-28">{t('products')}</h1>
          <h2 className="flex justify-center items-center text-xl text-gray-600 md:text-4xl py-4">{t('profileconsole')}</h2>
        </div>
        <div className="max-w-[1140px] m-auto p-4 " data-aos="fade-up" data-aos-duration="3000">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
            {/* Ürünler Başlangıcı */}
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/steel-rail">
                <div className="overflow-hidden">
                  <img
                    src={SteelRail}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('steel')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/c-profile">
                <div className="overflow-hidden">
                  <img
                    src={CProfile}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('cprofil')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/g-profile">
                <div className="overflow-hidden">
                  <img
                    src={GProfile}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('gprofil')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/l-profile">
                <div className="overflow-hidden">
                  <img
                    src={LProfile}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('lprofil')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/u-profile">
                <div className="overflow-hidden">
                  <img
                    src={UProfile}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('uprofil')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/c-rail-console">
                <div className="overflow-hidden">
                  <img
                    src={CRailConsole}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('crailconsole')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/g-rail-console">
                <div className="overflow-hidden">
                  <img
                    src={GRailConsole}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('grailconsole')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/l-rail-console">
                <div className="overflow-hidden">
                  <img
                    src={LRailConsole}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('lrailconsole')}
                </h1>
              </Link>
            </div>
            <div className="bg-white shadow-xl p-4 group">
              <Link to="/products/profile-console/u-rail-console">
                <div className="overflow-hidden">
                  <img
                    src={URailConsole}
                    alt=""
                    className="group-hover:scale-110 duration-500"
                  />
                </div>
                <h1 className="text-center mb-2 break-words group-hover:underline group-hover:text-blue-800">
                  {t('urailconsole')}
                </h1>
              </Link>
            </div>

            {/* Ürünler Sonu */}
          </div>
        </div>
        <FixedImage />
        <Footer />
      </AnimatedPage>
    </div>
  )
}

export default ProfileConsole